import { css } from 'styled-components'

export const TitleHeaderH2SCSS = css`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-decoration: none;
  text-transform: none;
`
