import { PropsWithChildren } from 'react'
import styled from 'styled-components'

export const BasicChip: React.FC<PropsWithChildren> = ({ children }) => {
  return <Root>{children}</Root>
}

const Root = styled.div`
  align-items: center;
  background: #141a1b;
  border-radius: 40px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  line-height: 24px;
  padding: 4px 12px;
`
