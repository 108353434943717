import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Markdown from 'react-markdown'

import { AboutFooter } from '@components/atoms/About/About'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { TitleHeaderH1LCSS, TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours, gradients } from '@configs/colours'
import { mediaQueries } from '@utils/mediaQueries'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { MiscelaneousSpecialTitleBoldCSS } from '@components/atoms/typography/css/MiscelaneouSpecialTitleBoldCSS'
import { InfoWithBadge } from '@components/atoms/InfoWithBadge/InfoWithBadge'
import { useState } from 'react'
import { GradientCard } from '@components/atoms/GradientCard/GradientCard'
import { Modal } from '@components/atoms/modals/Modal'
import { ShareModalContent } from '@components/molecules/refer/ShareModalContent'
import { ReferFriendFAQ } from '@components/molecules/refer/FAQ'

const hardcoded_desc = `[Hi!\n
Here’s a link to join as Norstat panelist so that we can both get a prize. Peeked your interest? find out more at <http://www.join-the-panelist-party.yesty.io/1234r5>]
`
const hardcoded_terms_link = 'https://www.google.com/search?q=maine+coon'
const hardcoded_share_link = 'https://www.google.com/search?q=black+cat+maine+coon'

const Page: React.FC = () => {
  const { t } = useTranslation()

  const [shareModal, setShareModal] = useState(false)

  return (
    <Root>
      <Modal open={shareModal} onClose={() => setShareModal(false)}>
        <ShareModalContent shareLink={hardcoded_share_link} onClose={() => setShareModal(false)} />
      </Modal>
      <IntroContent>
        <WidthBoundary>
          <Grid>
            <Info>
              <Title>{t('referFriendsAndEarnMore')}</Title>
              <Subtitle>{t('getExtraEachFriendYouRefer')}</Subtitle>
              <div>
                <RegularGray>
                  {t('helpUsBuildPanel')}
                  <br />
                  <Trans
                    i18nKey="termsAndConditionsApply"
                    components={[
                      <LinkStyle href={hardcoded_terms_link} key="idk" target="_blank" />,
                    ]}
                  />
                </RegularGray>
              </div>
            </Info>
            <Frame>
              <MainDescription>{t('shareWithFriend')}</MainDescription>
              <RegularGray>{t('sharingIsCaring')}</RegularGray>
              <BlueFrame>
                <Markdown className="__markdown">{hardcoded_desc}</Markdown>
              </BlueFrame>
              <Buttons>
                <PrimaryButton
                  iconRight={Icons.Share}
                  type="button"
                  onClick={() => setShareModal(true)}
                >
                  {t('share')}
                </PrimaryButton>
                <IconOnlyButton
                  iconRight={Icons.Link}
                  onClick={() => navigator.clipboard.writeText(hardcoded_share_link)}
                />
              </Buttons>
            </Frame>
            <ToDesktop>
              <Buttons>
                <StyledPrimaryButton iconRight={Icons.Share} onClick={() => setShareModal(true)}>
                  {t('share')}
                </StyledPrimaryButton>
                <IconOnlyButton
                  iconRight={Icons.Link}
                  onClick={() => navigator.clipboard.writeText(hardcoded_share_link)}
                />
              </Buttons>
            </ToDesktop>
          </Grid>
        </WidthBoundary>
      </IntroContent>
      <HowDoesItWorkContent>
        <ExplanationWidthWrapper>
          <DarkMiscTitle>{t('howDoesItWork')}</DarkMiscTitle>
          <RegularGray>{t('howDoesItWorkExplanation')}</RegularGray>
          <BadgeInfos>
            <InfoWithBadge icon={Icons.People}>{t('refer.steps.one')}</InfoWithBadge>
            <InfoWithBadge icon={Icons.Gift}>{t('refer.steps.two')}</InfoWithBadge>
            <InfoWithBadge icon={Icons.Shop}>{t('refer.steps.three')}</InfoWithBadge>
          </BadgeInfos>
          <StyledPrimaryButton iconRight={Icons.ArrowRight} onClick={() => setShareModal(true)}>
            {t('referFriend')}
          </StyledPrimaryButton>
        </ExplanationWidthWrapper>
        <DummyCardsWrapper>
          <DummyCards>
            {Array(20)
              .fill(null)
              .map((_, i) => i)
              .map((c) => (
                <GradientCard key={c} />
              ))}
          </DummyCards>
        </DummyCardsWrapper>
      </HowDoesItWorkContent>
      <WidthBoundary>
        <FAQRoot>
          <ReferFriendFAQ />
        </FAQRoot>
      </WidthBoundary>
      <AboutFooter />
    </Root>
  )
}

const LinkStyle = styled.a`
  ${BodyLargeCSS};
  color: ${colours.brand.blue};
  text-decoration: underline;
  font-weight: 700;
`

const StyledPrimaryButton = styled(PrimaryButton)``

const DummyCardsWrapper = styled.div`
  position: relative;
  width: 99%;
  height: 120px;
  margin-bottom: 56px;
`

const DummyCards = styled.div`
  display: flex;
  gap: 16px;
  overflow: hidden;
  width: 100%;
  position: absolute;
`

const FAQRoot = styled.div`
  margin-bottom: 96px;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 600px;
  }
`

const BadgeInfos = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: flex-start;
  margin-bottom: 32px;
  ${mediaQueries.to.breakpoint.tablet} {
    flex-direction: column;
    align-items: center;
  }
`

const IconOnlyButton = styled(PrimaryButton)`
  flex-shrink: 8;
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

const BlueFrame = styled.div`
  ${BodyLargeCSS};
  background-color: ${colours.blues[10]};
  color: ${colours.blues[110]};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  margin-bottom: 24px;
`

const DarkMiscTitle = styled.div`
  ${MiscelaneousSpecialTitleBoldCSS};
  color: ${colours.brand.dark};
  margin-bottom: 8px;
`

const Title = styled.div`
  margin-bottom: 16px;
  ${mediaQueries.from.breakpoint.desktop} {
    ${TitleHeaderH1LCSS}
  }
  ${mediaQueries.to.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }
`

const Subtitle = styled(Fonts.IntroSubtextLarge)`
  color: ${colours.mist[800]};
  margin-bottom: 16px;
`

const MainDescription = styled(Fonts.TitleHeaderH2)`
  margin: 0 0 8px 0;
  text-align: left;
`

const RegularGray = styled(Fonts.BodyLarge)`
  color: ${colours.mist[700]};
  margin-bottom: 24px;
`

const ExplanationWidthWrapper = styled(WidthBoundary)`
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 832px;
  }
`

const Info = styled.div`
  text-align: left;
`

const Grid = styled.div`
  display: grid;
  gap: 40px;
  margin-bottom: 48px;
  justify-items: center;
  align-items: center;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;
    margin-bottom: 96px;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 10;
  width: 100%;
  ${gradients.defaultBackground}
`

const HowDoesItWorkContent = styled.div`
  background-color: ${colours.prisma.white};
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 96px;
  flex-direction: column;
  align-items: center;
`

const IntroContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 64px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-top: 96px;
  }
`

const Frame = styled.div`
  width: min(440px, 96vw);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 28px 24px 24px;
  gap: 32px;
  background: rgba(255, 255, 255, 0.85);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const ToDesktop = styled.div`
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

export default Page
