const { entries } = Object

export const breakpoints = {
  mobile: 0,
  mobileL: 551,
  tablet: 768,
  desktop: 1024,
  desktopL: 1280,
}

type BreakpointMediaQuery = Record<keyof typeof breakpoints, string>

const breakpointValues = entries(breakpoints).sort((a, b) => a[1] - b[1])

export const mediaQueries = {
  from: {
    breakpoint: breakpointValues.reduce(
      (acc, [breakpoint, pixels]) => ({ ...acc, [breakpoint]: `@media (min-width: ${pixels}px)` }),
      {},
    ) as BreakpointMediaQuery,
  },
  to: {
    breakpoint: breakpointValues.reduce(
      (acc, [breakpoint, pixels]) => ({
        ...acc,
        [breakpoint]: `@media (max-width: ${Math.max(0, pixels - 1)}px)`,
      }),
      {},
    ) as BreakpointMediaQuery,
  },
  within: {
    breakpoint: breakpointValues.reduce(
      (acc, [breakpoint, pixels], idx, self) => ({
        ...acc,
        [breakpoint]: `@media (min-width: ${pixels}px)${
          self[idx + 1] ? ` and (max-width: ${self[idx + 1][1] - 1}px)` : ''
        }`,
      }),
      {},
    ) as BreakpointMediaQuery,
  },
}
