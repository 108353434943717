import { useField } from 'formik'
import React from 'react'
import Select, { Props as SelectProps } from 'react-select'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

type Option = { label: string; value: string | number }
const isOption = (o: unknown): o is Option =>
  typeof o === 'object' && o !== null && !('options' in o)

const settingValueError = 'Error - check SelectField component'

type SelectFieldProps = {
  name: string | number
  description?: string
  onValueChange?: (value: string | number) => void
}

export const SelectField: React.FC<SelectProps<Option> & SelectFieldProps> = ({
  name,
  description,
  onValueChange,
  options,
}) => {
  const [field, , { setValue }] = useField(name)
  if (!options) return null

  return (
    <React.Fragment>
      {description && <Description>{description}</Description>}
      <Select
        components={{ IndicatorSeparator: null }}
        defaultValue={options.find((option) =>
          isOption(option) ? option.value === field.value : settingValueError,
        )}
        name={name}
        onChange={(t) => {
          if (!isOption(t)) return
          setValue(t.value).catch((e: unknown) => {
            console.error('Cannot set field value', e)
          })
          onValueChange?.(t.value)
        }}
        options={options}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Poppins',
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Poppins',
          }),
        }}
        value={options.find((option) =>
          isOption(option) ? option.value === field.value : settingValueError,
        )}
      />
    </React.Fragment>
  )
}

const Description = styled(Fonts.BodyRegular)`
  font-size: 14px;
  line-height: 21px;
  color: ${colours.mist[700]};
  margin: 0 0 4px;
`
