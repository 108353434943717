import { OrderStatus } from './orderStatus'

export const orderPendingStatuses = [
  OrderStatus.NEW,
  OrderStatus.WAITING_FOR_PAYMENT,
  OrderStatus.PAID,
  OrderStatus.ISSUING_CARDS,
]
export const orderSuccessStatuses = [OrderStatus.SUCCESS, OrderStatus.ISSUING_CUSTOMER_READY]
export const orderFailedStatuses = [OrderStatus.FAILED, OrderStatus.CANCELED]
