import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonTextLinkCSS } from '@components/atoms/typography/css/index'
import { ButtonTextLink } from '@components/atoms/typography/elements/ButtonTextLink'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { getAboutLinks } from '@constants/aboutLinks'
import { LanguageCodes } from '@constants/language'
import { DivProps } from '@typeDeclarations/elements/div'

import { mediaQueries } from '@utils/mediaQueries'

type Props = { $noBackground?: boolean }

export const AboutFooter: React.FC<PropsWithChildren<DivProps & Props>> = ({ ...props }) => {
  const { t, i18n } = useTranslation()
  const language = i18n.language as `${LanguageCodes}` | 'cimode'
  const aboutLinks = getAboutLinks()
  const privacy = aboutLinks[language].privacy.href

  return (
    <Root {...props}>
      <Text>{t('copyright', { year: new Date().getFullYear() })}</Text>
      <BlueLinks>
        {aboutLinks[language].conditions.href && (
          <BlueLink href={aboutLinks[language].conditions.href} rel="noreferrer" target="_blank">
            {t('aboutLinks.conditions')}
          </BlueLink>
        )}
        {privacy && <BlueText>•</BlueText>}
        {privacy && (
          <BlueLink href={privacy} rel="noreferrer" target="_blank">
            {t('aboutLinks.privacy')}
          </BlueLink>
        )}
        <BlueText>•</BlueText>
        <BlueLink href="https://yesty.io/" rel="noreferrer" target="_blank">
          yesty.io
        </BlueLink>
      </BlueLinks>
    </Root>
  )
}

const Root = styled.div<{ $noBackground?: boolean }>`
  background-color: ${(p) => (!p.$noBackground ? colours.prisma.background : '')};
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    flex-direction: row;
    gap: 0;
  }
`

const Text = styled(Fonts.BodyXSmall)`
  color: ${colours.mist[800]};
  margin-right: 16px;
`

const BlueLink = styled.a`
  color: ${colours.brand.blue};
  text-decoration: underline;
  font-size: 16px;
  ${ButtonTextLinkCSS}
  overflow-wrap: break-word;
`

const BlueLinks = styled.div`
  display: grid;
  text-align: center;
  align-items: center;
  grid-template-columns: repeat(5, min-content);
  ${mediaQueries.from.breakpoint.mobileL} {
    white-space: nowrap;
  }
`

const BlueText = styled(ButtonTextLink)`
  color: ${colours.brand.blue};
  margin: 0 8px;
`
