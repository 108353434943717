import { t } from 'i18next'
import { Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Tooltip } from '@components/atoms/tooltips/Tooltips/Tooltip'
import { colours } from '@configs/colours'

type Props = {
  conditions: ([RegExp, Maybe<string>] | [RegExp])[]
  string: string
  tip: 'right' | 'left' | 'top' | 'bottom' | 'all' | 'none'
}

export const PasswordStrength: React.FCS<Props> = ({ className, conditions, string, tip }) => {
  const matchedCount = conditions.map(([regex]) => regex.test(string)).filter((t) => !!t).length
  const correctPercentage = (100 * matchedCount) / conditions.length

  if (conditions.length === 0) {
    console.warn('No conditions were passed to password checker')
    return null
  }

  return (
    <Tooltip
      className={className}
      tip={tip}
      title={
        <Title>
          {conditions.filter(([r]) => !r.test(string)).map(([, text]) => text)[0] ?? (
            <GoodPassword>
              <Suspense>
                {t('goodPassword')}
                <IconComponents.checkmark fill={colours.brand.green} stroke={colours.brand.green} />
              </Suspense>
            </GoodPassword>
          )}
        </Title>
      }
      type="light"
    >
      <Worms data-testid="password-strength-worms">
        {conditions.map(([, text], i) => (
          <Earthworm
            $correctPercentage={correctPercentage}
            $fill={i <= matchedCount - 1}
            key={text}
          />
        ))}
      </Worms>
      {conditions.map(([regex, msg]) => {
        const matches = regex.test(string)
        return (
          <ListItem key={msg}>
            {matches ? (
              <Suspense>
                <IconComponents.filledCheckmark
                  fill={correctPercentage === 100 ? colours.brand.green : colours.brand.blue}
                  height="20"
                  stroke={correctPercentage === 100 ? colours.plant[10] : colours.blues[10]}
                  width="20"
                />
              </Suspense>
            ) : (
              <BlueBullet />
            )}
            <Condition $matches={matches} key={msg}>
              {msg}
            </Condition>
          </ListItem>
        )
      })}
    </Tooltip>
  )
}

const ListItem = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 8px;
`

const BlueBullet = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${colours.brand.blue};
  margin: 7px;
`

const Condition = styled.div<{ $matches?: boolean }>`
  text-decoration: ${(p) => (p.$matches ? 'line-through' : 'unset')};
  color: ${({ $matches }) => ($matches ? colours.mist[700] : colours.brand.dark)};
`

const Worms = styled.section`
  display: flex;
  gap: 8px;
  flex-direction: row;
  margin-bottom: 16px;
`

const Earthworm = styled.div<{ $correctPercentage: number; $fill?: boolean }>`
  height: 0px;
  width: 100%;
  border: 4px solid;
  border-radius: 18px;
  border-color: ${({ $correctPercentage }) =>
    $correctPercentage >= 100
      ? colours.brand.green
      : $correctPercentage >= 60
      ? colours.brand.yellow
      : colours.error.red};
  ${(p) => (!p.$fill ? `border-color: ${colours.mist[300]}` : ``)};
`

const Title = styled.div`
  margin-bottom: 8px;
`

const GoodPassword = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
