import React, { useEffect, useState } from 'react'

import { hsl2rgb } from '@utils/hsl2rgb'
import { lightenOrDarken } from '@utils/ligthenOrDarken'
import { randomHslColor } from '@utils/randomHslColor'

type Props = {
  trigger: boolean
  numberOfColors?: number
}

type Confetto = {
  color: { front: string; back: string }
  dimensions: { x: number; y: number }
  position: { x: number; y: number }
  rotation: number
  scale: { x: number; y: number }
  velocity: { x: number; y: number }
}

const ConfettiAnimation: React.FC<Props> = ({ numberOfColors = 3, trigger = false }) => {
  const [componentReady, setComponentReady] = useState(false)
  const [colors] = useState(
    Array(numberOfColors)
      .fill(null)
      .map(() => {
        const base = lightenOrDarken(randomHslColor(), -0.3)
        const lighter = lightenOrDarken(base, 0.5, { maxLightness: 0.9 })
        const front = hsl2rgb(base)
        const back = hsl2rgb(lighter)

        return { front, back }
      }),
  )

  const animation = () => {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement
    const ctx = canvas.getContext('2d')

    if (!ctx) return

    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    let _cx = ctx.canvas.width / 2
    let _cy = ctx.canvas.height / 2

    const confetti: Confetto[] = []
    const confettiCount = 600
    const gravity = 0.5
    const terminalVelocity = 5
    const drag = 0.075

    // -----------Functions--------------

    const resizeCanvas = () => {
      windowWidth = window.innerWidth
      windowHeight = window.innerHeight
      _cx = ctx.canvas.width / 2
      _cy = ctx.canvas.height / 2
    }

    const randomRange = (min: number, max: number) => Math.random() * (max - min) + min

    const initConfetti = () => {
      for (let i = 0; i < confettiCount; i++) {
        confetti.push({
          color: colors[Math.floor(randomRange(0, colors.length))],
          dimensions: {
            x: randomRange(10, 20),
            y: randomRange(10, 30),
          },
          position: {
            x: randomRange(0, windowWidth),
            y: windowHeight - 1,
          },
          rotation: randomRange(0, 2 * Math.PI),
          scale: {
            x: 1,
            y: 1,
          },
          velocity: {
            x: randomRange(-25, 25),
            y: randomRange(0, -50),
          },
        })
      }
    }

    // ---------Render-----------

    const renderOnce = () => {
      ctx.clearRect(0, 0, windowWidth, windowHeight)
      confetti.forEach((confetto, index) => {
        const width = confetto.dimensions.x * confetto.scale.x
        const height = confetto.dimensions.y * confetto.scale.y
        // Move canvas to position and rotate
        ctx.translate(confetto.position.x, confetto.position.y)
        ctx.rotate(confetto.rotation)
        // Apply forces to velocity
        confetto.velocity.x -= confetto.velocity.x * drag
        confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity)
        confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()
        // Set position
        confetto.position.x += confetto.velocity.x
        confetto.position.y += confetto.velocity.y
        // Delete confetti when out of frame
        if (confetto.position.y >= windowHeight) confetti.splice(index, 1)
        // Loop confetto x position
        if (confetto.position.x > windowWidth) confetto.position.x = 0
        if (confetto.position.x < 0) confetto.position.x = windowWidth
        // Spin confetto by scaling y
        confetto.scale.y = Math.cos(confetto.position.y * 0.1)
        ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back
        // Draw confetto
        ctx.fillRect(-width / 2, -height / 2, width, height)
        // Reset transform matrix
        ctx.setTransform(1, 0, 0, 1, 0, 0)
      })

      if (confetti.length > 0) {
        window.requestAnimationFrame(renderOnce)
      }
    }

    initConfetti()
    renderOnce()

    window.addEventListener('resize', () => {
      resizeCanvas()
    })
  }

  useEffect(() => {
    setComponentReady(true)
  }, [])

  if (componentReady && trigger) {
    animation()
  }

  return (
    <canvas
      className="unwrap-modal-confetti"
      height={window.innerHeight}
      id="canvas"
      width={window.innerWidth}
    />
  )
}

export default ConfettiAnimation
