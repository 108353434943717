import { useRef } from 'react'
import styled from 'styled-components'

import { hsl2rgb } from '@utils/hsl2rgb'
import { lightenOrDarken } from '@utils/ligthenOrDarken'
import { randomHslColor } from '@utils/randomHslColor'
import { randomInt } from '@utils/randomInt'

type Color = string
type Height = number
type X = number
type Y = number
type Opacity = number

type DotDescription = [Color, Height, X, Y, Opacity]

const Dots: React.FC = () => {
  const dot1: DotDescription = [
    hsl2rgb(randomHslColor()),
    randomInt(30, 50),
    randomInt(20, 30),
    randomInt(20, 30),
    randomInt(6, 10),
  ]
  const dot2: DotDescription = [
    hsl2rgb(randomHslColor()),
    randomInt(80, 200),
    randomInt(0, 80),
    randomInt(0, 80),
    randomInt(6, 10),
  ]
  const dot3: DotDescription = [
    hsl2rgb(randomHslColor()),
    randomInt(10, 60),
    randomInt(0, 80),
    randomInt(0, 80),
    randomInt(6, 10),
  ]
  const dot4: DotDescription = [
    hsl2rgb(randomHslColor()),
    randomInt(10, 60),
    randomInt(20, 80),
    randomInt(0, 40),
    randomInt(2, 10),
  ]

  const bgHsl = randomHslColor()
  const bgDarkHsl = lightenOrDarken(bgHsl, -0.3)
  const bgRgb = hsl2rgb(bgDarkHsl)

  const bgRef = useRef(bgRgb)
  const dot1Ref = useRef<DotDescription>(dot1)
  const dot2Ref = useRef<DotDescription>(dot2)
  const dot3Ref = useRef<DotDescription>(dot3)
  const dot4Ref = useRef<DotDescription>(dot4)

  return (
    <Root $bg={bgRef.current}>
      <DotShape
        $color={dot1Ref.current[0]}
        $heightPercentage={dot1Ref.current[1]}
        $o={dot1Ref.current[4]}
        $x={dot1Ref.current[2]}
        $y={dot1Ref.current[3]}
      />
      <DotShape
        $color={dot2Ref.current[0]}
        $heightPercentage={dot2Ref.current[1]}
        $o={dot2Ref.current[4]}
        $x={dot2Ref.current[2]}
        $y={dot2Ref.current[3]}
      />
      <DotShape
        $color={dot3Ref.current[0]}
        $heightPercentage={dot3Ref.current[1]}
        $o={dot3Ref.current[4]}
        $x={dot3Ref.current[2]}
        $y={dot3Ref.current[3]}
      />
      <DotShape
        $color={dot4Ref.current[0]}
        $heightPercentage={dot4Ref.current[1]}
        $o={dot4Ref.current[4]}
        $x={dot4Ref.current[2]}
        $y={dot4Ref.current[3]}
      />
    </Root>
  )
}

const Root = styled.div<{ $bg: string }>`
  padding: 24px;
  overflow: hidden;
  box-sizing: border-box;
  align-items: flex-start;
  aspect-ratio: 1.6;
  background-color: ${(p) => p.$bg};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  width: 100%;
`

const DotShape = styled.div<{
  $heightPercentage: number
  $color: string
  $y: number
  $x: number
  $o: number
}>`
  height: ${(p) => `${p.$heightPercentage}%`};
  aspect-ratio: 1;
  background-color: ${(p) => p.$color};
  border-radius: 100%;
  position: absolute;
  top: ${(p) => `${p.$y}%`};
  left: ${(p) => `${p.$x}%`};
  opacity: ${(p) => p.$o / 10};
`

export default Dots
