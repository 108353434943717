import { Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { mediaQueries } from '@utils/mediaQueries'

const isInt = (n: number) => n % 1 === 0

type Props = { count: number; step: number; stepNames?: string[] }

export const ProgressDots: React.FCS<Props> = ({ className, count, step, stepNames }) => {
  if (!isInt(count) || !isInt(step) || count < 2 || step < 1) {
    return null
  }

  return (
    <Root className={className}>
      {Array(count)
        .fill(null)
        .flatMap((_, i) => (
          <BarWrapper key={i}>
            <StepName $selected={i === step - 1}>
              {stepNames?.[i]}
              {i < step - 1 && (
                <Suspense>
                  <IconComponents.checkmark fill={colours.mist[400]} stroke={colours.mist[400]} />
                </Suspense>
              )}
            </StepName>
            <Bar selected={i <= step - 1} />
          </BarWrapper>
        ))}
      <ForwardArrow />
    </Root>
  )
}

const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ForwardArrow = styled.div`
  width: 16px;
`

const Bar = styled(Fonts.Label)<{ selected?: boolean }>`
  align-items: center;
  background-color: ${(p) => (p.selected ? colours.brand.blue : colours.mist[300])};
  border-radius: 4px;
  color: ${colours.mist[50]};
  display: flex;
  height: 4px;
  justify-content: center;
  width: 100%;
`

const BarWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
`

const StepName = styled(Fonts.ButtonCTALabelSmall)<{ $selected?: boolean }>`
  margin-bottom: 8px;
  min-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => (p.$selected ? colours.mist[800] : colours.mist[400])};
  ${mediaQueries.to.breakpoint.tablet} {
    display: none;
  }
`
