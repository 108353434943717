import { Suspense } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours } from '@configs/colours'

import { PATHS } from '@constants/paths'
import { floating } from '@styles/animations/floating'
import { wave } from '@styles/animations/wave'
import { mediaQueries } from '@utils/mediaQueries'

export const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Root>
      <Content>
        <LeftColumn>
          <Title>404</Title>
          <ColumnCircle>
            <Circle>
              <Suspense>
                <FloatingGift>
                  <VectorsDict.gift fill={colours.mandarin[110]} />
                </FloatingGift>
                <Svg
                  fill="none"
                  height="24"
                  viewBox="0 0 1215 24"
                  width="1215"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    d="M523.133 18.9884L388.725 4.43435C334.674 -1.4184 274.218 -1.47751 220.037 4.257L83.972 18.9884C57.085 21.8997 28.6131 23.4558 0.117188 23.5V24H607.117H1214.12V23.5469C1186.04 23.4968 1157.97 21.9691 1130.13 18.9884L995.725 4.43435C941.674 -1.4184 881.218 -1.47751 827.037 4.257L690.972 18.9884C664.085 21.8997 635.613 23.4558 607.117 23.5V23.5469C579.044 23.4968 550.973 21.9691 523.133 18.9884Z"
                    fill={colours.brand.blue}
                    fillRule="evenodd"
                  />
                </Svg>
              </Suspense>
              <WaterFill />
            </Circle>
          </ColumnCircle>
          <ErrorPageTitle>{t('notFoundTitle')}</ErrorPageTitle>
          <ErrorText>{t('notFoundText')}</ErrorText>
          <StyledLink to={PATHS.root}>
            <StyledPrimaryButton>{t('home')}</StyledPrimaryButton>
          </StyledLink>
          <HelpLink>{t('needHelp')}</HelpLink>
        </LeftColumn>
        <DesktopCircle>
          <Circle>
            <Suspense>
              <FloatingGift>
                <VectorsDict.gift fill={colours.mandarin[110]} />
              </FloatingGift>
              <Svg
                fill="none"
                height="24"
                viewBox="0 0 1215 24"
                width="1215"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M523.133 18.9884L388.725 4.43435C334.674 -1.4184 274.218 -1.47751 220.037 4.257L83.972 18.9884C57.085 21.8997 28.6131 23.4558 0.117188 23.5V24H607.117H1214.12V23.5469C1186.04 23.4968 1157.97 21.9691 1130.13 18.9884L995.725 4.43435C941.674 -1.4184 881.218 -1.47751 827.037 4.257L690.972 18.9884C664.085 21.8997 635.613 23.4558 607.117 23.5V23.5469C579.044 23.4968 550.973 21.9691 523.133 18.9884Z"
                  fill={colours.brand.blue}
                  fillRule="evenodd"
                />
              </Svg>
            </Suspense>
            <WaterFill />
          </Circle>
        </DesktopCircle>
      </Content>
    </Root>
  )
}

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ColumnCircle = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQueries.within.breakpoint.desktop} {
    display: none;
  }
`
const DesktopCircle = styled.div`
  display: none;
  ${mediaQueries.within.breakpoint.desktop} {
    display: flex;
    flex-direction: column;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 32px;
`

const ErrorPageTitle = styled(Fonts.TitleHeaderH2)`
  margin-bottom: 12px;
  text-align: center;
`

const StyledLink = styled(Link)`
  width: 100%;
`

const ErrorText = styled(Fonts.BodyRegular)`
  margin-bottom: 40px;
  text-align: center;
`

const FloatingGift = styled.div`
  position: relative;
  animation: ${floating} 3s linear infinite;
`

const Svg = styled.svg`
  position: absolute;
  top: 178px;
  animation: ${wave} 3s linear infinite;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mediaQueries.within.breakpoint.desktop} {
    margin-top: auto;
    margin-bottom: auto;
  }
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
  ${mediaQueries.from.breakpoint.mobileL} {
    max-width: 500px;
  }

  ${mediaQueries.within.breakpoint.desktop} {
    max-width: unset;
    flex-direction: row;
    gap: 64px;
    width: 678px;
  }
`

const WaterFill = styled.div`
  background-color: ${colours.brand.blue};
  height: 140px;
  width: 304px;
  position: absolute;
  top: 202px;
  z-index: 2;
`

const Circle = styled.div`
  align-items: center;
  background-color: ${colours.mist[100]};
  border-radius: 100%;
  display: flex;
  height: 304px;
  justify-content: center;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  width: 304px;
  z-index: 3;
`

const Title = styled(Fonts.TitleHeaderH1L)`
  font-weight: 600;
  margin-bottom: 24px;
`
const HelpLink = styled(Fonts.BodyLarge)`
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 40px;
`
