import { Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { colours } from '@configs/colours'
import { useSettingsContext } from '@hooks/useSettings'

import yesty from '/images/brands/logos/yesty-new.png'

export const Footer: React.FC = () => {
  const { imageLogo } = useSettingsContext()
  const collab = yesty !== imageLogo

  return (
    <Root>
      <FooterContent>
        <img height={16} src={yesty} />
        {collab && (
          <div>
            <Suspense>
              <IconComponents.cross
                fill={colours.mist[400]}
                height="8"
                stroke={colours.mist[400]}
                width="8"
              />
            </Suspense>
            <img height={16} src={imageLogo ?? undefined} />
          </div>
        )}
      </FooterContent>
    </Root>
  )
}

const FooterContent = styled.div`
  grid-template-columns: 1fr min-content 1fr;
  display: grid;
  align-items: center;
  justify-items: center;
  filter: opacity(0.3) grayscale(1);
  grid-gap: 16px;
  width: min-content;
`

const Root = styled.footer`
  align-items: center;
  background-color: ${colours.mist[200]};
  bottom: 0;
  display: flex;
  height: 64px;
  justify-content: center;
  min-height: 64px;
  position: sticky;
  width: 100%;
  margin-top: 64px;
`
