import { Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { colours } from '@configs/colours'

import { spin } from '@styles/animations/spin'

export const LoadingButton: React.FC = () => {
  return (
    <Root>
      <Suspense>
        <Animation>
          <IconComponents.openCircle />
        </Animation>
      </Suspense>
    </Root>
  )
}

const Root = styled.button`
  align-items: center;
  background-color: ${colours.brand.dark};
  border-radius: 12px;
  border: 0;
  color: ${colours.prisma.white};
  display: flex;
  gap: 8px;
  height: 48px;
  justify-content: center;
  min-width: calc(8px * 20);
  padding: 0 16px;
  width: 100%;
`

const Animation = styled.div`
  animation: ${spin} 2s linear infinite;
  width: 24px;
  height: 24px;
  transform-origin: center;
`
