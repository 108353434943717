export enum Icons {
  Arch = 'arch',
  ArrowLeft = 'arrowLeft',
  ArrowRight = 'arrowRight',
  Bin = 'bin',
  Cart = 'cart',
  Checkmark = 'checkmark',
  Chevron = 'chevron',
  CircledPlus = 'circledPlus',
  CloseOutline = 'closeOutline',
  Cross = 'cross',
  EmptyCart = 'emptyCart',
  ExclamationMark = 'exclamationMark',
  ExternalResource = 'externalResource',
  EyeClosed = 'eyeClosed',
  EyeOpen = 'eyeOpen',
  HeadphonesFace = 'headphonesFace',
  Info = 'info',
  Minus = 'minus',
  Pen = 'pen',
  Plus = 'plus',
  Shop = 'shop',
  Tada = 'tada',
  Warning = 'warning',
  Envelope = 'envelope',
  Megaphone = 'megaphone',
  BigSmile = 'bigSmile',
  Bulb = 'bulb',
  CircledInfo = 'circledInfo',
  FilledCheckmark = 'filledCheckmark',
  Logout = 'logout',
  CardStack = 'cardStack',
  Contract = 'contract',
  Receipt = 'receipt',
  Gift = 'gift',
  EnvelopeOpen = 'envelopeOpen',
  CreditCard = 'creditCard',
  PaperPlane = 'paperPlane',
  OpenCircle = 'openCircle',
  Wallet = 'wallet',
  Copy = 'copy',
  Share = 'share',
  Link = 'link',
  People = 'people',
  Letter = 'letter',
  Whatsapp = 'whatsapp',
  Twitter = 'twitter',
}
