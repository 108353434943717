import { ConsoleApiName } from '@datadog/browser-core'
import { RumInitConfiguration } from '@datadog/browser-rum'

import { envs } from './envs'

const env = envs.VITE_DOTENV
const clientToken = envs.VITE_DATADOG_CLIENT_TOKEN
const version = envs.VITE_VERSION

export const datadogConfig = {
  clientToken,
  env,
  forwardConsoleLogs: [ConsoleApiName.warn, ConsoleApiName.error],
  forwardErrorsToLogs: true,
  service: `redemption_shop_-_dotenv_${env}`,
  sessionSampleRate: 100,
  site: 'datadoghq.eu',
  version: version,
}

export const datadogRumConfig: RumInitConfiguration = {
  applicationId: '4aa9cf3a-708f-4139-92b6-0e8bf0c7051e',
  clientToken,
  defaultPrivacyLevel: 'mask-user-input',
  enableExperimentalFeatures: ['feature_flags'],
  env,
  service: 'redemption-shop',
  sessionReplaySampleRate: 20,
  sessionSampleRate: 100,
  site: 'datadoghq.eu',
  trackLongTasks: true,
  trackResources: true,
  trackUserInteractions: true,
}
