import { Fragment, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { IntroSubtextSmallCSS } from '@components/atoms/typography/css/IntroSubtextSmallCSS'
import { MiscelaneousSpecialTitleBoldCSS } from '@components/atoms/typography/css/MiscelaneouSpecialTitleBoldCSS'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { colours } from '@configs/colours'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  loading?: boolean
  error?: boolean | string
  cardsRequireActivation?: boolean
}

export const OrderOnItsWay: React.FC<Props> = ({ loading, error, cardsRequireActivation }) => {
  const { t } = useTranslation()

  return (
    <Root>
      {!error && !loading && (
        <Fragment>
          <Circle>
            <Suspense>
              <VectorsDict.celebration />
            </Suspense>
          </Circle>
          {cardsRequireActivation ? (
            <Fragment>
              <Title>{t('emailOnItsWay')}</Title>
              <Subtitle>{t('emailOnItsWaySubtitle')}</Subtitle>
            </Fragment>
          ) : (
            <Fragment>
              <Title>{t('orderOnItsWay')}</Title>
              <Subtitle>{t('orderOnItsWaySubtitle')}</Subtitle>
            </Fragment>
          )}
        </Fragment>
      )}
      {error && (
        <Fragment>
          <Circle>
            <Suspense>
              <IconComponents.cross height="64" width="64" />
            </Suspense>
          </Circle>
          <Title>{t('orderFailed')}</Title>
          <Subtitle>{t('orderFailedSubtitle')}</Subtitle>
        </Fragment>
      )}
      {loading && (
        <Fragment>
          <Circle>
            <Suspense>
              <IconComponents.info height="64" width="64" />
            </Suspense>
          </Circle>
          <Title>{t('orderLoading')}</Title>
          <Subtitle>{t('orderLoadingSubtitle')}</Subtitle>
        </Fragment>
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Circle = styled.div`
  width: 200px;
  min-width: 200px;
  height: 200px;
  min-height: 200px;
  background: white;
  border-radius: 100%;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  margin-bottom: 16px;
  text-align: center;
  ${mediaQueries.to.breakpoint.desktop} {
    ${MiscelaneousSpecialTitleBoldCSS}
  }
  ${mediaQueries.from.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }
`

const Subtitle = styled.div`
  color: ${colours.mist[800]};
  text-align: center;
  ${mediaQueries.to.breakpoint.tablet} {
    ${BodyLargeCSS}
  }
  ${mediaQueries.from.breakpoint.tablet} {
    ${IntroSubtextSmallCSS}
  }
`
