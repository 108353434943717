import { useRef } from 'react'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { hsl2rgb } from '@utils/hsl2rgb'
import { lightenOrDarken } from '@utils/ligthenOrDarken'
import { randomHslColor } from '@utils/randomHslColor'
import { randomInt } from '@utils/randomInt'

const Diamond: React.FC = () => {
  const heightPercentage = randomInt(20, 60)

  const hsl = randomHslColor()
  const darkHsl = lightenOrDarken(hsl, -0.2)
  const darkRgb = hsl2rgb(darkHsl)
  const rgb = hsl2rgb(hsl)

  const heightPercentageRef = useRef(heightPercentage)
  const bgColorRef = useRef(darkRgb)
  const colorRef = useRef(rgb)

  return (
    <Root $bg={bgColorRef.current}>
      <DiamondShape $color={colorRef.current} $heightPercentage={heightPercentageRef.current} />
      <Text>Lorem</Text>
    </Root>
  )
}

const Text = styled(Fonts.TitleHeaderH2)`
  color: white;
  text-shadow: 1px 1px 20px #000;
`

const Root = styled.div<{ $bg: string }>`
  padding: 24px;
  box-sizing: border-box;
  align-items: flex-start;
  aspect-ratio: 1.6;
  background-color: ${(p) => p.$bg};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid #0000000a;
  position: relative;
  width: 100%;
`

const DiamondShape = styled.div<{ $heightPercentage: number; $color: string }>`
  height: ${(p) => `${p.$heightPercentage}%`};
  width: ${(p) => `${p.$heightPercentage / 2}%`};
  background-color: ${(p) => p.$color};
  clip-path: polygon(0 50%, 50% 0, 100% 50%, 50% 100%);
  position: absolute;
  top: 20%;
  right: 10%;
`

export default Diamond
