import i18n from '@i18n'

export const rangeErrors = {
  product_basket_quantity_outside_range: i18n.t('backend.product_basket_quantity_outside_range'),
  product_quantity_outside_range: i18n.t('backend.product_quantity_outside_range'),
  product_value_outside_range: i18n.t('backend.product_value_outside_range'),
}

export const generalErrors = {
  'Invalid input.': i18n.t('backend.Invalid input.'),
  balance_not_valid: i18n.t('backend.balance_not_valid'),
  card_claimed_email_mismatch: i18n.t('backend.card_claimed_email_mismatch'),
  card_debited_but_not_used: i18n.t('backend.card_debited_but_not_used'),
  card_not_found: i18n.t('backend.card_not_found'),
  currency_mismatch: i18n.t('backend.currency_mismatch'),
  duplicated_cards: i18n.t('backend.duplicated_cards'),
  flow_mismatch: i18n.t('backend.flow_mismatch'),
  forbidden_action: i18n.t('backend.forbidden_action'),
  no_input_claim_codes: i18n.t('backend.no_input_claim_codes'),
  order_value_mismatch: i18n.t('backend.order_value_mismatch'),
  organization_mismatch: i18n.t('backend.organization_mismatch'),
  permission_error: i18n.t('backend.permission_error'),
  product_unavailable_for_card: i18n.t('backend.product_unavailable_for_card'),
  product_unavailable: i18n.t('backend.product_unavailable'),
  self_addition_error: i18n.t('backend.self_addition_error'),
  temporarily_unavailable: i18n.t('backend.temporarily_unavailable'),
  unable_to_update_balance: i18n.t('backend.unable_to_update_balance'),
  user_does_not_exist: i18n.t('backend.user_does_not_exist'),
  wrong_flow: i18n.t('backend.wrong_flow'),
}

export const shopResponseErrors = {
  ...generalErrors,
  ...rangeErrors,
}
