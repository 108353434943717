import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import * as y from 'yup'

import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { BottomNav } from '@components/organisms/BottomNav/BottomNav'
import { colours } from '@configs/colours'
import { claimCodePaths, shareKeyPaths } from '@configs/urls'
import { datadogMessages as ddMsgs } from '@constants/datadog'
import { datadogLogs } from '@datadog/browser-logs'
import { HttpError } from '@errors/httpError'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { api } from '@services/api'
import { HashType } from '@typeDeclarations/hashType'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'

const Page: React.FC = () => {
  const { t } = useTranslation()
  const { hashFlows } = useHashFlowContext()
  const navigate = useNavigate()

  const codeNotFound = t('codeNotFound')
  const somethingWentWrong = t('somethingWentWrong')

  const getCatalogueLink = (): string => {
    const claimCodes = Object.entries(hashFlows).filter(
      ([, flow]) => flow?.type === HashType.ClaimCode,
    )

    if (claimCodes.length !== 1) return generatePath(claimCodePaths.claimCodeSelect)

    const [firstClaimCode] = claimCodes
    const [claimCodeHash] = firstClaimCode

    return generatePath(claimCodePaths.welcome, { claimCode: claimCodeHash })
  }

  return (
    <Root>
      <Content>
        <WidthBoundary>
          <InputDescription>{t('enterCode')}</InputDescription>
          <Formik
            initialValues={{ code: '' }}
            onSubmit={async ({ code }, { setStatus }) => {
              let claimCodeNotFound = false
              let shareKeyNotFound = false

              setStatus()

              try {
                await api.cardDetail(code)
                const processCodePath = generatePath(claimCodePaths.root, { claimCode: code })
                navigate(processCodePath)
              } catch (e: unknown) {
                if (!(e instanceof Error)) {
                  datadogLogs.logger.error(ddMsgs.notInstanceOfError)
                } else if (!(e instanceof HttpError)) {
                  datadogLogs.logger.error(`${ddMsgs.unknownError} | ${e.name} | ${e.message}`)
                } else if (!(e.status === HttpCodes.get(HttpCodeNames.NotFound))) {
                  datadogLogs.logger.error(`${ddMsgs.httpError} | ${e.name} | ${e.message}`)
                }

                try {
                  claimCodeNotFound = true
                  await api.receiveInfo(code)
                  const cataloguePath = generatePath(shareKeyPaths.confetti, { shareKey: code })
                  navigate(cataloguePath)
                } catch (e: unknown) {
                  if (!(e instanceof Error)) {
                    datadogLogs.logger.error(ddMsgs.notInstanceOfError)
                  } else if (!(e instanceof HttpError)) {
                    datadogLogs.logger.error(`${ddMsgs.unknownError} | ${e.name} | ${e.message}`)
                  } else if (!(e.status === HttpCodes.get(HttpCodeNames.NotFound))) {
                    datadogLogs.logger.error(`${ddMsgs.httpError} | ${e.name} | ${e.message}`)
                  }

                  shareKeyNotFound = true
                }

                setStatus(somethingWentWrong)
              } finally {
                if (claimCodeNotFound && shareKeyNotFound) {
                  setStatus(codeNotFound)
                }
              }
            }}
            validationSchema={y.object({
              code: y.string().required(t('forms.validation.required')),
            })}
          >
            {({ status }) => (
              <WithTranslateFormErrors>
                <Form>
                  <StyledTextInput
                    description={t('code')}
                    name="code"
                    placeholder={t('yourCode')}
                  />
                  {status && <Status>{t(`${status}`)}</Status>}
                  <PrimaryButton type="submit">{t('forms.actions.startReceiveFlow')}</PrimaryButton>
                </Form>
              </WithTranslateFormErrors>
            )}
          </Formik>
          <br />
          <Fonts.BodyRegular>
            Shortcuts: <Link to={getCatalogueLink()}>Catalogue</Link>
            <br />
          </Fonts.BodyRegular>
        </WidthBoundary>
      </Content>
      <BottomNav />
    </Root>
  )
}

const InputDescription = styled(Fonts.BodyRegular)`
  margin: 16px 0 16px 0;
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: 8px;
`

const Root = styled.article`
  width: 100%;
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  flex-grow: 10;
`

const Status = styled(Fonts.BodyRegular)`
  margin-bottom: 16px;
  color: ${colours.tomato[100]};
`

export default Page
