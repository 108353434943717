import styled from 'styled-components'

import { useSettingsContext } from '@hooks/useSettings'

export const BrandBar: React.FC<{ $height?: string; $brandBarColor?: string }> = ({
  $brandBarColor,
  $height,
}) => {
  const { colorBar } = useSettingsContext()

  return <Root $color={$brandBarColor ?? colorBar} $height={$height} id="brand-bar" />
}

const Root = styled.div<{ $color?: string | null; $height?: string }>`
  background-color: ${({ $color }) => `#${$color}`};
  background-color: ${({ $color }) => `${$color}`};
  height: ${({ $height }) => ($height ? $height : '8px')};
  min-height: ${({ $height }) => ($height ? $height : '8px')};
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: 1s ease-in-out background-color;
`
