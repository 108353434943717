import { Fragment, Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteError } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Accordion } from '@components/atoms/Accordion/Accordion'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { TitleHeaderH1LCSS, TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { Fonts } from '@components/atoms/typography/Fonts'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { colours } from '@configs/colours'
import { PATHS } from '@constants/paths'
import { datadogRum } from '@datadog/browser-rum'
import { HttpError } from '@errors/httpError'
import { rotate } from '@styles/animations/rotate'
import { getHttpErrorInfo } from '@utils/getHttpErrorInfo'
import { mediaQueries } from '@utils/mediaQueries'

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [traceExpanded, setTraceExpanded] = useState(false)

  const routeError = useRouteError()
  const isHttpError = routeError instanceof HttpError
  const isFetchError = routeError instanceof TypeError
  const errorInfo = getHttpErrorInfo(routeError)

  return (
    <Root>
      <Content>
        <LeftColumn>
          <Title>{t('somethingWentWrong')}</Title>
          <ColumnCircle>
            <Circle>
              <Suspense>
                <VectorsDict.monitor fill={colours.brand.blue} height="150" width="150" />
                <LeftEye>
                  <IconComponents.plus
                    fill={colours.brand.green}
                    height="48"
                    stroke={colours.brand.green}
                    width="48"
                  />
                </LeftEye>
                <RightEye>
                  <IconComponents.plus
                    fill={colours.brand.green}
                    height="48"
                    stroke={colours.brand.green}
                    width="48"
                  />
                </RightEye>
              </Suspense>
            </Circle>
          </ColumnCircle>
          {isFetchError && (
            <GeneralErrorWrapper>
              <ErrorPageTitle>{t('connectionErrorTitle')}</ErrorPageTitle>
              <ErrorText>{t('connectionErrorText')}</ErrorText>
            </GeneralErrorWrapper>
          )}
          {isHttpError && (
            <GeneralErrorWrapper>
              <ErrorPageTitle>
                {t(errorInfo.title)} ({routeError.sourcePrefix}
                {routeError.status})
              </ErrorPageTitle>
              <ErrorText>{t(errorInfo.description)}</ErrorText>
            </GeneralErrorWrapper>
          )}
          {!isHttpError && !isFetchError && (
            <GeneralErrorWrapper>
              <ErrorPageTitle>{t('errorTitle')}</ErrorPageTitle>
              <ErrorText>{t('errorText')}</ErrorText>
            </GeneralErrorWrapper>
          )}
          <DevInfo>
            DD: {datadogRum.getInternalContext()?.session_id} | {new Date().toISOString()}
          </DevInfo>
          <StyledLink to={PATHS.root}>
            <StyledPrimaryButton>{t('home')}</StyledPrimaryButton>
          </StyledLink>
          <StyledSecondaryButton onClick={() => navigate(0)}>{t('refresh')}</StyledSecondaryButton>
          <HelpLink onClick={() => window.Trengo?.Api?.Widget?.open?.('chat')}>
            {t('needHelp')}
          </HelpLink>
          <TraceWrapper>
            <Accordion
              arrangement="center"
              expanded={traceExpanded}
              onExpandedChange={() => setTraceExpanded((t) => !t)}
              titleText={<AccordionTitle>{t('trace')}</AccordionTitle>}
            >
              <ErrorText>{routeError instanceof Error && routeError.stack}</ErrorText>
            </Accordion>
          </TraceWrapper>
        </LeftColumn>
        <DesktopCircle>
          <Circle>
            <Suspense>
              <VectorsDict.monitor fill={colours.brand.blue} height="150" width="150" />
              <LeftEye>
                <IconComponents.plus
                  fill={colours.brand.green}
                  height="48"
                  stroke={colours.brand.green}
                  width="48"
                />
              </LeftEye>
              <RightEye>
                <IconComponents.plus
                  fill={colours.brand.green}
                  height="48"
                  stroke={colours.brand.green}
                  width="48"
                />
              </RightEye>
            </Suspense>
          </Circle>
        </DesktopCircle>
      </Content>
    </Root>
  )
}

const GeneralErrorWrapper = Fragment

const TraceWrapper = styled.div`
  margin-bottom: 80px;
`

const AccordionTitle = styled(Fonts.BodyLarge)``

const ColumnCircle = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQueries.within.breakpoint.desktop} {
    display: none;
  }
`
const DesktopCircle = styled.div`
  display: none;
  ${mediaQueries.within.breakpoint.desktop} {
    display: flex;
    flex-direction: column;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)`
  width: 100%;
`
const HelpLink = styled(Fonts.BodyLarge)`
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 40px;
`

const RightEye = styled.div`
  position: absolute;
  top: 90px;
  right: 100px;
  animation: 4s linear ${rotate} infinite;
`

const LeftEye = styled.div`
  position: absolute;
  top: 90px;
  left: 100px;
  animation: 4s linear ${rotate} infinite;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 32px;
`

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-bottom: 32px;
`

const ErrorPageTitle = styled(Fonts.TitleHeaderH2)`
  margin-bottom: 12px;
`

const ErrorText = styled(Fonts.BodyRegular)`
  margin-bottom: 40px;
`

const DevInfo = styled(Fonts.BodyRegular)`
  margin-bottom: 40px;
  color: ${colours.mist[800]};
`

const Root = styled.div`
  text-align: center;
  margin-top: 24px;
  padding: 0 24px;
  ${mediaQueries.within.breakpoint.desktop} {
    margin-top: auto;
    margin-bottom: auto;
  }
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${mediaQueries.from.breakpoint.mobileL} {
    max-width: 500px;
  }

  ${mediaQueries.within.breakpoint.desktop} {
    max-width: unset;
    flex-direction: row;
    gap: 64px;
    width: 678px;
  }
`

const Circle = styled.div`
  align-items: center;
  background-color: ${colours.mist[100]};
  border-radius: 100%;
  display: flex;
  height: 304px;
  justify-content: center;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  width: 304px;
  z-index: 3;
`

const Title = styled.div`
  font-weight: 600;
  margin-bottom: 24px;
  ${TitleHeaderH1LCSS};
  ${mediaQueries.to.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }
`
