import styled from 'styled-components'

import { mediaQueries } from '@utils/mediaQueries'

export const Ripped = styled.div`
  ${mediaQueries.to.breakpoint.mobileL} {
    min-height: calc(80vh - 160px);
    background: linear-gradient(180deg, #ffffff 0%, #f4f6f7 50%, #edf0f1 100%);
    --mask: conic-gradient(from -45deg at bottom, #0000, #000 0deg 90deg, #0000 90deg) 50% / 32px
      100%;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
  padding-bottom: 32px;
`
