import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react'

import { useLocation, useParams } from 'react-router-dom'

import { ThemeBar } from '@components/molecules/context/branding'
import { colours } from '@configs/colours'
import { useCartContext, useHashFlowContext } from '@hooks/index'
import { useMainKeyContext } from '@hooks/useMainKey'
import { api } from '@services/api'
import {
  CheckCardFlow,
  LanguageSpecificBranding,
  ReceiveInfo,
  ShopBanner,
} from '@services/api.types'

import yestyLogo from '/images/brands/logos/yesty-new.png'

import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { parseConfigValues } from '@utils/parseConfigValues'

type SettingsContext = {
  colorBar: Maybe<string>
  imageLogo: Maybe<string>
  shopBannerType: Maybe<string>
  languageSpecifics: Maybe<LanguageSpecificBranding[]>
  slotImage: Maybe<string>
  welcomeImage: Maybe<string>
  setColorBar: (color: Maybe<string>) => void
  setImageLogo: (url: Maybe<string>) => void
  setShopBannerType: (type: Maybe<ShopBanner>) => void
  setLanguageSpecifics: (text: Maybe<LanguageSpecificBranding[]>) => void
  setSlotImage: (url: Maybe<string>) => void
  setWelcomeImage: (url: Maybe<string>) => void
  setAllBranding: (card: ReceiveInfo) => void
  setDefaultBranding: () => void
  setLastHash: (hash: string) => void
  lastHash: Maybe<string>
  reloadShopConfig: () => Promise<void>
}

const defaultSettingsContext = {
  colorBar: colours.brand.orange,
  imageLogo: null,
  languageSpecifics: null,
  lastHash: null,
  shopBannerType: null,
  slotImage: null,
  welcomeImage: null,
  setAllBranding: () => {},
  setColorBar: () => {},
  setDefaultBranding: () => {},
  setImageLogo: () => {},
  setLanguageSpecifics: () => {},
  setLastHash: () => {},
  setShopBannerType: () => {},
  setSlotImage: () => {},
  setWelcomeImage: () => {},
  reloadShopConfig: () => Promise.resolve(),
}

export const SettingsContext = createContext<SettingsContext>(defaultSettingsContext)

const prefix = '[Branding]'

const defaults = {
  product: { primary_image: undefined },
  shop_personalization: {
    color_bar: colours.brand.orange.substring(1),
    color_background: colours.prisma.black,
    image_logo: yestyLogo,
    shop_banner: ShopBanner.Clean,
    image_welcome: null,
    language_specifics: [
      {
        language: 'GB',
        shop_text: 'Shop',
        welcome_description: 'Lorem ipsum dolor sit amet',
        welcome_title: 'Welcome!',
      },
    ],
  },
}

export const SettingsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { shareKey, claimCode } = useParams()
  const { pathname } = useLocation()

  const { clearCart, setMainChoiceCardBalance } = useCartContext()
  const { mainKey } = useMainKeyContext()
  const { dispatchHashFlows } = useHashFlowContext()

  const [, setError] = useState()
  const [lastHash, setLastHash] = useState<string | null>(null)
  const [colorBar, setColorBar] = useState<Maybe<string>>(defaults.shop_personalization.color_bar)
  const [imageLogo, setImageLogo] = useState<Maybe<string>>(
    defaults.shop_personalization.image_logo,
  )
  const [shopBannerType, setShopBannerType] = useState<Maybe<ShopBanner>>(
    defaults.shop_personalization.shop_banner,
  )
  const [languageSpecifics, setLanguageSpecifics] = useState<
    Maybe<Array<LanguageSpecificBranding>>
  >(defaults.shop_personalization.language_specifics)
  const [slotImage, setSlotImage] = useState<Maybe<string>>(defaults.product.primary_image)
  const [welcomeImage, setWelcomeImage] = useState<Maybe<string>>(
    defaults.shop_personalization.image_welcome,
  )

  const setAllBranding = useCallback((card: Partial<ReceiveInfo>) => {
    setColorBar(card.shop_personalization?.color_bar)
    setImageLogo(card.shop_personalization?.image_logo)
    setShopBannerType(card.shop_personalization?.shop_banner)
    setLanguageSpecifics(card.shop_personalization?.language_specifics)
    setWelcomeImage(card.shop_personalization?.image_welcome)
  }, [])

  const setDefaultBranding = useCallback(() => {
    setAllBranding(defaults)
  }, [setAllBranding])

  const reloadShopConfig = useCallback(async () => {
    if (!claimCode || claimCode !== mainKey) return

    try {
      const response = await api.cardDetail(claimCode)
      const { maxPurchaseQuantity, maxPurchaseAmount } = parseConfigValues(response.config)

      setMainChoiceCardBalance(response.balance)

      if (response.balance === 0) clearCart()

      dispatchHashFlows({
        type: HashFlowActions.UpdateHashFlow,
        payload: { hash: claimCode, maxPurchaseQuantity, maxPurchaseAmount },
      })
    } catch (e) {
      if (e instanceof TypeError) {
        setError(() => {
          throw e
        })
      }
      console.warn('[Settings] Something went wrong reloading shop config', e)
    }
  }, [claimCode, mainKey, dispatchHashFlows, clearCart, setMainChoiceCardBalance])

  const isReceiveInfoType = (o: unknown): o is ReceiveInfo =>
    o !== null && typeof o === 'object' && 'product' in o

  useEffect(() => {
    if ((claimCode && claimCode === lastHash) || (shareKey && shareKey === lastHash)) return

    const brandingSetter =
      (hash: string, defaults: boolean) => (r: ReceiveInfo | CheckCardFlow) => {
        if (!r.shop_personalization || defaults) {
          setDefaultBranding()
        } else {
          setAllBranding(r)
        }

        if (isReceiveInfoType(r) && r.product?.primary_image) {
          setSlotImage(r.product.primary_image)
        }

        setLastHash(hash)
      }

    if (shareKey) {
      api
        .receiveInfo(shareKey)
        .then(brandingSetter(shareKey, false))
        .catch((e: unknown) => {
          if (e instanceof TypeError) {
            setError(() => {
              throw e
            })
          }
          console.info(`${prefix} Setting default branding`)
          brandingSetter(shareKey, true)
        })
      return
    }

    if (claimCode) {
      api
        .cardDetail(claimCode)
        .then((response) => {
          const { maxPurchaseQuantity, maxPurchaseAmount } = parseConfigValues(response.config)

          dispatchHashFlows({
            type: HashFlowActions.UpdateHashFlow,
            payload: { hash: claimCode, maxPurchaseQuantity, maxPurchaseAmount },
          })

          brandingSetter(claimCode, false)(response)
        })
        .catch((e: unknown) => {
          if (e instanceof TypeError) {
            setError(() => {
              throw e
            })
          }
          api
            .checkCardFlow(claimCode)
            .then(brandingSetter(claimCode, false))
            .catch((e: unknown) => {
              if (e instanceof TypeError) {
                setError(() => {
                  throw e
                })
              }
              console.info(`${prefix} Setting default branding`)
              brandingSetter(claimCode, true)
            })
        })
    }
  }, [
    claimCode,
    lastHash,
    mainKey,
    setDefaultBranding,
    shareKey,
    pathname,
    dispatchHashFlows,
    setAllBranding,
  ])

  return (
    <SettingsContext.Provider
      value={{
        colorBar,
        imageLogo,
        shopBannerType,
        languageSpecifics,
        slotImage,
        welcomeImage,
        setColorBar,
        setImageLogo,
        setShopBannerType,
        setLanguageSpecifics,
        setSlotImage,
        setWelcomeImage,
        setAllBranding,
        setDefaultBranding,
        lastHash,
        setLastHash,
        reloadShopConfig,
      }}
    >
      <ThemeBar color={colorBar} />
      {children}
    </SettingsContext.Provider>
  )
}
