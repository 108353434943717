import { useTranslation } from 'react-i18next'

import { Banner } from '@components/atoms/tooltips/Banners/Banner'
import { LimitsViolations } from '@utils/evaluateLimits'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'

type Props = {
  dismissViolationError: undefined | (() => void)
  violationError: LimitsViolations
  currency?: string
}

export const ViolationBanner: React.FC<Props> = ({
  violationError,
  dismissViolationError,
  currency,
}) => {
  const { i18n, t } = useTranslation()
  return (
    <Banner onClose={dismissViolationError} title={t('bannerViolationError')} type="tipIdea">
      <div>{t('banner.limistChangedDuringSession')}</div>
      <div> {t('banner.reviewAndFixErrors')}</div>
      <div>
        {violationError.totalAmount.violated &&
          t('limits.totalAmountViolated', {
            current: getLocalisedPrice(i18n.language, violationError.totalAmount.current, currency),
            max: getLocalisedPrice(
              i18n.language,
              violationError.totalAmount.max ?? undefined,
              currency,
            ),
          })}
      </div>
      <div>
        {violationError.totalQuantity.violated &&
          t('limits.totalQuantityViolated', {
            current: violationError.totalQuantity.current,
            max: violationError.totalQuantity.max,
          })}
      </div>
    </Banner>
  )
}
