import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { DetailedList } from '@components/molecules/priceSummary/DetailedList'
import { PaymentProcessingFeeComponent } from '@components/molecules/priceSummary/PaymentProcessingFee'
import { PaymentSummary } from '@components/molecules/priceSummary/PaymentSummary'
import { ToPay } from '@components/molecules/priceSummary/ToPay'
import { colours } from '@configs/colours'
import { useCheckoutContext } from '@hooks/useCheckout'
import { CalculateFeesResponse } from '@typeDeclarations/api/calculateFees'
import { DivProps } from '@typeDeclarations/elements/div'
import { OrderItem } from '@typeDeclarations/orderItem'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'

type OrderList = {
  currency: string | undefined
  pastTense?: boolean
}

type ExtendedOrderListProps = {
  currency?: string | undefined
  amountFromChoiceCards?: number | undefined
  choiceCardsCount?: number | undefined
  paymentProcessingFee?: PaymentProcessingFee | undefined
  priceFromCards?: number | undefined
  pastTense?: boolean
  items?: OrderItem[]
  calculatedFees?: CalculateFeesResponse
}

export const ExtendedOrderList: React.FC<ExtendedOrderListProps> = ({
  amountFromChoiceCards,
  choiceCardsCount,
  paymentProcessingFee,
  priceFromCards,
  pastTense,
  currency,
  items,
  calculatedFees,
  ...props
}) => {
  const calculatedToPay = calculatedFees?.payment_fee.total_with_fee
  const calculatedPaymentProcessingFee = calculatedFees?.payment_fee.fee
  const calculatedChoiceCards = calculatedFees?.order_value.choice_card_debit_total
  const calculatedTotal = calculatedFees?.order_value.total_amount_with_product_fee
  const calculatedItems = calculatedFees?.products_with_fees

  return (
    <Root>
      <GrayArea {...props}>
        <DetailedList calculatedItems={calculatedItems} currency={currency} items={items} />
        <Hr />
        <PaymentSummaryWrapper>
          <PaymentSummary
            amountFromChoiceCards={amountFromChoiceCards}
            calculatedChoiceCards={calculatedChoiceCards}
            calculatedTotal={calculatedTotal}
            choiceCardsCount={choiceCardsCount}
            currency={currency}
            paymentProcessingFee={paymentProcessingFee}
            priceFromCards={priceFromCards}
          />
        </PaymentSummaryWrapper>
      </GrayArea>
      <PaymentProcessingFeeComponent
        amountFromChoiceCards={amountFromChoiceCards}
        calculatedPaymentProcessingFee={calculatedPaymentProcessingFee}
        currency={currency}
        paymentProcessingFee={paymentProcessingFee}
        priceFromCards={priceFromCards}
      />
      <ToPay
        amountFromChoiceCards={amountFromChoiceCards}
        calculatedToPay={calculatedToPay}
        currency={currency}
        pastTense={pastTense}
        paymentProcessingFee={paymentProcessingFee}
        priceFromCards={priceFromCards}
      />
    </Root>
  )
}

export const ExtendedCheckoutOrderList: React.FC<PropsWithChildren<OrderList & DivProps>> = ({
  currency,
  pastTense,
  ...props
}) => {
  const {
    amountFromChoiceCards,
    choiceCardsCount,
    paymentProcessingFee,
    priceFromCards,
    items,
    calculatedFees,
  } = useCheckoutContext()

  return (
    <ExtendedOrderList
      amountFromChoiceCards={amountFromChoiceCards}
      calculatedFees={calculatedFees}
      choiceCardsCount={choiceCardsCount}
      currency={currency}
      items={items}
      pastTense={pastTense}
      paymentProcessingFee={paymentProcessingFee}
      priceFromCards={priceFromCards}
      {...props}
    />
  )
}

const PaymentSummaryWrapper = styled.div``

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Hr = styled.hr`
  height: 0;
  border-top: 1px solid ${colours.mist[300]};
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
`

const GrayArea = styled.div`
  border-radius: 16px;
  background-color: ${colours.mist[100]};
  border: 1px solid ${colours.mist[200]};
  box-sizing: border-box;
  padding: 16px;
`
