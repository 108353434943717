import { css } from 'styled-components'

export const LabelCSS = css`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
`
