export const supportLinks = {
  cimode: '',
  de: 'https://157323-yesty-receivers.trengohelp.com/en',
  en: 'https://157323-yesty-receivers.trengohelp.com/en',
  nl: 'https://902292-yesty-redemption-shop.trengohelp.com/nl',
  pl: 'https://157323-yesty-receivers.trengohelp.com/en',
}

export const eonSupportLinks = {
  de: 'https://301313-yesty-eon.trengohelp.com/de',
  en: 'https://301313-yesty-eon.trengohelp.com/de',
}
