export enum OrderStatus {
  NEW = 'new',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  PAID = 'paid',
  ISSUING_CARDS = 'issuing_cards',
  ISSUING_CUSTOMER_READY = 'issuing_customer_ready',
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCELED = 'canceled',
}
