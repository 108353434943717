import { Field, useField } from 'formik'

import { Suspense, createElement } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Icons } from '@typeDeclarations/components/atoms/icons'

type Props = {
  name: string
  value: string
  text?: React.ReactNode
  disabled?: boolean
  accent?: string
  icon?: Icons
  customToggler?: (checked: boolean, disabled?: boolean) => React.ReactNode
  onValueChange?: (newValue: unknown) => void
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Radio: React.FC<Props> = ({
  accent,
  icon,
  disabled,
  name,
  value,
  text,
  customToggler,
  onValueChange,
  ...props
}) => {
  const [field] = useField(name)
  const checked = field.value === value

  const Icon = icon
    ? createElement(IconComponents[icon], {
        fill: disabled ? colours.mist[400] : accent && checked ? accent : '#000',
        stroke: disabled ? colours.mist[400] : accent && checked ? accent : '#000',
      })
    : null

  return (
    <Root checked={checked} color={accent}>
      <Label disabled={disabled}>
        <Input
          type="radio"
          {...props}
          {...field}
          $accent={accent}
          $visible={!customToggler}
          checked={checked}
          disabled={disabled}
          name={name}
          onChange={(e: React.ChangeEvent) => {
            field.onChange(e)
            if ('value' in e.target) {
              onValueChange?.(e.target.value)
            }
          }}
          value={value}
        />
        {customToggler ? (
          customToggler(checked, disabled)
        ) : (
          <Text>
            <Suspense>{Icon}</Suspense> {text}
          </Text>
        )}
      </Label>
    </Root>
  )
}

const Root = styled.div<{ color?: string; checked?: boolean }>`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(p) => (p.color && p.checked ? p.color : '#000')};
`

const Text = styled(Fonts.ButtonCTALabelLarge)`
  align-items: center;
  display: flex;
  gap: 8px;
`

const Label = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  cursor: ${(p) => (p.disabled ? 'unset' : 'pointer')};
`

const Input = styled(Field)<{
  $accent?: string
  checked?: boolean
  disabled?: boolean
  $visible?: boolean
}>`
  --radio-dimensions: 24px;
  /* reset defaults start */
  appearance: none;
  background-color: #fff;
  margin: 0;
  /* reset defaults end */
  font: inherit;
  width: var(--radio-dimensions);
  min-width: var(--radio-dimensions);
  height: var(--radio-dimensions);
  min-height: var(--radio-dimensions);
  border: 2px solid
    ${({ disabled, $accent, checked }) => {
      if (disabled) return colours.mist[400]
      return $accent && checked ? $accent : '#000'
    }};
  border-radius: 50%;
  display: grid;
  place-content: center;
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: none;
    box-shadow: inset 1em 1em
      ${(p) => {
        if (p.disabled) return colours.mist[400]
        return p.$accent ? p.$accent : '#000'
      }};
  }
  &:checked::before {
    display: block;
  }
  &:disabled {
    color: ${colours.mist[400]};
  }
  &:disabled + div {
    color: ${colours.mist[400]};
  }
  display: ${(p) => !p.$visible && 'none'};
`
