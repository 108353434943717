import { PaymentStatus } from './paymentStatus'

export const paymentPendingStatuses = [PaymentStatus.PENDING, PaymentStatus.OPEN]
export const paymentSuccessStatuses = [PaymentStatus.PAID]
export const paymentFailedStatuses = [
  PaymentStatus.CANCELED,
  PaymentStatus.CHARGED_BACK,
  PaymentStatus.EXPIRED,
  PaymentStatus.FAILED,
  PaymentStatus.REFUNDED,
]
