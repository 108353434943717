import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { TagTextCSS } from '@components/atoms/typography/css/TagTextCSS'

export const TagText: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => {
  return <Root {...props}>{children}</Root>
}

const Root = styled.div`
  ${TagTextCSS}
`
