import styled from 'styled-components'

import { mediaQueries } from '@utils/mediaQueries'

export const Root = styled.div`
  flex-grow: 10;
  ${mediaQueries.from.breakpoint.mobileL} {
    background: linear-gradient(180deg, #ffffff 0%, #f4f6f7 50%, #edf0f1 100%);
  }
`

export const RootWithGradient = styled.div`
  flex-grow: 10;
  background: linear-gradient(180deg, #ffffff 0%, #f4f6f7 50%, #edf0f1 100%);
`
