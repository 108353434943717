import { signIn } from 'aws-amplify/auth'
import { NavigateFunction, generatePath } from 'react-router-dom'

import { claimCodePaths, shareKeyPaths } from '@configs/urls'
import { api } from '@services/api'

type ReceiveClaimParams = {
  username: string | null
  email: string | null
  shareKey: string
  unconfirmed_user: boolean
  password?: string
  navigate: NavigateFunction
  setEmailDelayInfo: (shareKey: string, delayMinutes?: number | null | undefined) => void
  setEmail: (email: string) => void
  setIsLoggedIn: (loggedIn: boolean) => void
}

export const receiveClaim = async ({
  username,
  email,
  shareKey,
  unconfirmed_user,
  password,
  navigate,
  setEmailDelayInfo,
  setEmail,
  setIsLoggedIn,
}: ReceiveClaimParams) => {
  const { claim_code_email_delay_minutes, claim_code: claimCode } = await api.receiveClaim({
    cognito_id: username,
    shareKey,
    unconfirmed_user: unconfirmed_user,
    email,
  })

  if (claimCode) {
    if (password && email) {
      await api.checkCardFlow(claimCode)
      const { isSignedIn } = await signIn({ username: email, password })
      if (isSignedIn) {
        setEmail(email)
        setIsLoggedIn(true)
      }
    }
    navigate(generatePath(claimCodePaths.root, { claimCode }))
  } else {
    setEmailDelayInfo(shareKey, claim_code_email_delay_minutes)
    navigate(generatePath(shareKeyPaths.preparingChoiceCard, { shareKey }))
  }
}
