import { useEffect, useState } from 'react'

export const useData = <T>(memoizedApiCallFn?: () => Promise<T>, rethrow?: boolean) => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
    setLoading(true)
    if (memoizedApiCallFn) {
      let ignore = false
      memoizedApiCallFn()
        .then((response) => {
          if (!ignore) setData(response)
        })
        .catch((e: unknown) => {
          console.error(e)
          if (rethrow)
            setError(() => {
              throw e
            })
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
      return () => void (ignore = true)
    }
  }, [memoizedApiCallFn, rethrow])

  return { data, loading, error }
}
