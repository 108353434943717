import { RefObject, useEffect } from 'react'

export const useOutsideClick = (
  ref: RefObject<HTMLDivElement | HTMLButtonElement>,
  callback: (event?: Event) => void,
): void => {
  useEffect(() => {
    const handleClick = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) return
      callback(event)
    }

    document.addEventListener('touchstart', handleClick, { passive: true })
    document.addEventListener('mousedown', handleClick)
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('touchstart', handleClick)
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('click', handleClick)
    }
  }, [callback, ref])
}
