import { PropsWithChildren, Suspense, useState } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { PaymentSummary } from '@components/molecules/priceSummary/PaymentSummary'
import { ToPay } from '@components/molecules/priceSummary/ToPay'
import { colours } from '@configs/colours'
import { zIndexes } from '@constants/zIndexes'
import { useCheckoutContext } from '@hooks/useCheckout'
import { mediaQueries } from '@utils/mediaQueries'

import { DetailedList } from '../priceSummary/DetailedList'

type Props = {
  currency: string | undefined
}

export const MobileSummary: React.FC<PropsWithChildren<Props>> = ({ currency, children }) => {
  const [expanded, setExpanded] = useState(false)
  const { amountFromChoiceCards, choiceCardsCount, items, priceFromCards, paymentProcessingFee } =
    useCheckoutContext()

  return (
    <Root>
      <ContentRoot>
        <Content>
          <ToPay
            amountFromChoiceCards={amountFromChoiceCards}
            currency={currency}
            paymentProcessingFee={paymentProcessingFee}
            priceFromCards={priceFromCards}
          />
          <Expander $expanded={expanded}>
            <GrayHighlight>
              <DetailedList currency={currency} items={items} />
              <Hr />
              <PaymentSummary
                amountFromChoiceCards={amountFromChoiceCards}
                choiceCardsCount={choiceCardsCount}
                currency={currency}
                paymentProcessingFee={paymentProcessingFee}
                priceFromCards={priceFromCards}
              />
            </GrayHighlight>
          </Expander>
          {children}
        </Content>
        <Toggler onClick={() => setExpanded((p) => !p)}>
          <Suspense>{expanded ? <IconComponents.contract /> : <IconComponents.chevron />}</Suspense>
        </Toggler>
      </ContentRoot>
      <TogglerShader />
    </Root>
  )
}

const GrayHighlight = styled.div`
  border-radius: 12px;
  background: ${colours.mist[200]};
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 8px;
  margin-bottom: 16px;
`

const Root = styled.div`
  position: sticky;
  bottom: 72px;
  z-index: ${zIndexes.accordion};
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

const Expander = styled.div<{ $expanded?: boolean }>`
  max-height: ${({ $expanded }) => ($expanded ? '800px' : 0)};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`

const Content = styled.div`
  position: relative;
`

const ContentRoot = styled.div`
  background: ${colours.prisma.white};
  border-bottom: 1px solid ${colours.mist[200]};
  border-radius: 24px 24px 0px 0px;
  bottom: 72px;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 12px 12px 20px 12px;
  position: sticky;
  z-index: 2;
`

const Toggler = styled.div`
  align-items: center;
  background-color: ${colours.prisma.white};
  border-radius: 24px;
  display: flex;
  height: 56px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
  width: 66px;
  cursor: pointer;
`

const TogglerShader = styled.div`
  background-color: ${colours.prisma.white};
  border-radius: 24px;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  height: 56px;
  left: 50%;
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
  width: 66px;
  z-index: 1;
`

const Hr = styled.hr`
  height: 0;
  border-top: 1px solid #dde0e2;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
`
