import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Modal } from '@components/atoms/modals/Modal'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours } from '@configs/colours'

type Props = { open: boolean; onClose: () => void }

export const CannotClaimEmptyCard: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose} open={open}>
      <Root>
        <Title>{t('cardEmpty')}</Title>
        <Description>{t('cardEmptyExplanation')}</Description>
        <PrimaryButton onClick={onClose}>{t('close')}</PrimaryButton>
      </Root>
    </Modal>
  )
}

const Root = styled.div`
  padding: 24px;
`

const Title = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 16px;
`

const Description = styled(Fonts.BodyLarge)`
  color: ${colours.mist[800]};
  margin-bottom: 32px;
`
