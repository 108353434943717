import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'

import { getCurrencySymbol } from './getCurrencySymbol'

type SummaryValues = {
  amountFromChoiceCards?: number
  choiceCardsDiscountValue?: number
  currency?: string
  currencySymbol?: string
  paymentProcessingFeeValue?: number
  priceFromCards?: number
  priceSumValue?: number
  toPay?: number
}

type GetSummaryValuesArgs = {
  amountFromChoiceCards?: number
  currency?: string
  paymentProcessingFee?: PaymentProcessingFee
  priceFromCards?: number
}

export const getSummaryValues = ({
  amountFromChoiceCards,
  currency,
  paymentProcessingFee,
  priceFromCards,
}: GetSummaryValuesArgs = {}): SummaryValues => {
  const currencySymbol = getCurrencySymbol(currency)

  const priceSumValue =
    typeof priceFromCards === 'number' && typeof amountFromChoiceCards === 'number'
      ? Math.max(0, priceFromCards - amountFromChoiceCards)
      : undefined

  const paymentProcessingFeeValue =
    paymentProcessingFee &&
    priceFromCards &&
    amountFromChoiceCards &&
    priceFromCards > amountFromChoiceCards
      ? paymentProcessingFee.type === 'value'
        ? paymentProcessingFee.value
        : paymentProcessingFee.type === 'fixed'
        ? Math.round(100 * paymentProcessingFee.value) / 100
        : Math.round(
            100 * paymentProcessingFee.value * Math.max(0, priceFromCards - amountFromChoiceCards),
          ) / 100
      : undefined

  const toPay =
    typeof priceSumValue === 'number'
      ? priceSumValue +
        (typeof paymentProcessingFeeValue === 'number' ? paymentProcessingFeeValue : 0)
      : undefined

  const choiceCardsDiscountValue =
    typeof amountFromChoiceCards === 'number' && typeof priceFromCards === 'number'
      ? Math.min(amountFromChoiceCards, priceFromCards)
      : undefined

  return {
    amountFromChoiceCards,
    choiceCardsDiscountValue,
    currency,
    currencySymbol,
    paymentProcessingFeeValue,
    priceFromCards,
    priceSumValue,
    toPay,
  }
}
