import { createGlobalStyle } from 'styled-components'

import { mediaQueries } from '@utils/mediaQueries'

export const TrengoCheckoutStyle = createGlobalStyle`
${mediaQueries.to.breakpoint.tablet} {
    div.TrengoWidgetLauncher__iframe {
        right: calc(50vw - 30px);
        bottom: 52px;
    }
}
`
