import styled from 'styled-components'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { colours } from '@configs/colours'
import { Suspense, useRef } from 'react'

export const GradientCard: React.FC = () => {
  const min = 0.4
  const max = 0.8
  const opacity = `${Math.random() * (max - min) + min}`

  const c1 = 'rgba(255, 85, 0)'
  const c2 = 'rgba(92, 0, 186)'

  const opacityMemoized = useRef(opacity)

  return (
    <Root $c1={c1} $c2={c2} $opacity={opacityMemoized.current}>
      <Suspense>
        <GiftOne fill={colours.prisma.white} width="64px" height="64px" />
        <GiftTwo fill={colours.prisma.white} width="48px" height="48px" />
        <GiftThree fill={colours.prisma.white} width="48px" height="48px" />
        <GiftFour fill={colours.prisma.white} width="42px" height="42px" />
        <GiftFive fill={colours.prisma.white} width="32px" height="32px" />
      </Suspense>
    </Root>
  )
}

const Root = styled.div<{ $c1: string; $c2: string; $opacity: string }>`
  width: 200px;
  min-width: 200px;
  height: 120px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-image: ${(p) => `linear-gradient(150deg, ${p.$c1} 0%, ${p.$c2} 100%)`};
  box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.4);
  opacity: ${(p) => p.$opacity};
`

const GiftOne = styled(VectorsDict.gift)`
  opacity: 0.2;
  position: absolute;
  transform: rotate(-15deg);
  left: 16px;
  top: 0;
`

const GiftTwo = styled(VectorsDict.gift)`
  opacity: 0.2;
  position: absolute;
  transform: rotate(-15deg);
  left: 50%;
  bottom: 0;
`

const GiftThree = styled(VectorsDict.gift)`
  opacity: 0.2;
  position: absolute;
  transform: rotate(-15deg);
  right: 16px;
  top: 8px;
`

const GiftFour = styled(VectorsDict.gift)`
  opacity: 0.2;
  position: absolute;
  transform: rotate(-15deg);
  left: 16px;
  bottom: -18px;
`
const GiftFive = styled(VectorsDict.gift)`
  opacity: 0.2;
  position: absolute;
  transform: rotate(-15deg);
  right: -8px;
  bottom: 0px;
`
