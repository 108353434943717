import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { ButtonTextLinkCSS } from '@components/atoms/typography/css/'

export const ButtonTextLink: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => <Root {...props}>{children}</Root>

const Root = styled.div`
  ${ButtonTextLinkCSS}
`
