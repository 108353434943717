import { useFormikContext } from 'formik'
import React, { PropsWithChildren, Suspense, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ChoiceCardBarV2 } from '@components/atoms/ChoiceCardBarV2/ChoiceCardBarV2'
import { ChoiceCardsSummary } from '@components/atoms/ChoiceCardsSummary/ChoiceCardsSummary'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import { Modal } from '@components/atoms/modals/Modal'

import { SuccessButton } from '@components/atoms/SuccessButton/SuccessButton'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { colours } from '@configs/colours'

import { datadogMessages } from '@constants/datadog'
import { Languages } from '@constants/language'
import { Cart, ChoiceCardItem } from '@context/cart'
import { datadogLogs } from '@datadog/browser-logs'
import { HttpError } from '@errors/httpError'
import { useMainKeyContext } from '@hooks/useMainKey'
import { api } from '@services/api'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { extractAddChoiceCardError } from '@utils/extractAddChoiceCardError'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'

import Cards from '/images/card-stack.png'

const obfuscate = (text?: string | null) => {
  const keepFromEnd = 3

  if (!text) return

  return text
    .split('')
    .map((el, idx, { length }) => (idx !== 0 && idx < length - keepFromEnd ? '*' : el))
    .join('')
}

type AddChoiceCardsProps = { open?: boolean; onClose?: () => void }

export const AddChoiceCards: React.FC<PropsWithChildren<AddChoiceCardsProps>> = ({
  open,
  onClose,
}) => {
  const [addChoiceCardDisabled, setAddChoiceCardDisabled] = useState(false)
  const { mainKey } = useMainKeyContext()
  const { i18n, t } = useTranslation()
  const {
    values: formikValues,
    setFieldError,
    setFieldValue,
  } = useFormikContext<Cart & { choiceCardInput?: string }>()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const language = i18n.language as Languages

  const handleAddChoiceCard = async () => {
    setLoading(true)
    setSuccess(false)
    setAddChoiceCardDisabled(true)
    const userCode = formikValues.choiceCardInput

    if (!userCode || !mainKey)
      throw new Error('Cannot check choice cards compatibility - missing data')

    try {
      datadogLogs.logger.info(datadogMessages.choiceCardAdded)
      const result = await api.checkCardCompatibility(mainKey, userCode)
      const newChoiceCard: ChoiceCardItem = {
        claim_code: result.claim_code ?? '',
        currency: result.product?.currency,
        debit_value: result.balance ? Number(result.balance) : 0,
        name: result.product?.name,
      }

      if (!formikValues.choiceCardsToUse.find((el) => el.claim_code === newChoiceCard.claim_code)) {
        await setFieldValue('choiceCardsToUse', formikValues.choiceCardsToUse.concat(newChoiceCard))
      }
      setSuccess(true)
      setTimeout(() => setSuccess(false), 3000)
    } catch (e) {
      setFieldError('choiceCardInput', t('cannotAddChoiceCard'))
      if (e instanceof HttpError) {
        const error = extractAddChoiceCardError(e)
        if (typeof error === 'string') setFieldError('choiceCardInput', t(error))
      }
      datadogLogs.logger.info(datadogMessages.choiceCardAddingFailed)
      console.error(e)
    } finally {
      setAddChoiceCardDisabled(false)
      setLoading(false)
    }
  }

  const handleRemoveChoiceCard = async (clam_code: string) => {
    const choiceCards = formikValues.choiceCardsToUse
    await setFieldValue(
      'choiceCardsToUse',
      choiceCards.filter((v) => v.claim_code !== clam_code),
    )
  }

  const sum = formikValues.choiceCardsToUse
    .map(({ debit_value }) => debit_value)
    .reduce((a, c) => c + a)
  const count = formikValues.choiceCardsToUse.length
  const currency = formikValues.choiceCardsToUse[0]?.currency

  return (
    <Modal onClose={onClose} open={open}>
      <Root>
        <Ornament>
          <CardsImage src={Cards} />
        </Ornament>
        <Content>
          <div>
            <Title>{t('yourCards')}</Title>
            <AddedChoiceCards>
              {mainKey ? (
                formikValues.choiceCardsToUse.map((choiceCard) => {
                  return (
                    <ChoiceCardBarListItem
                      key={choiceCard.claim_code}
                      mainInfo={`${choiceCard.name} - ${obfuscate(choiceCard.claim_code)}`}
                      sideInfo={
                        <Actions>
                          {getLocalisedPrice(language, choiceCard.debit_value, choiceCard.currency)}
                          {mainKey !== choiceCard.claim_code && (
                            <BinWrapper
                              onClick={() => void handleRemoveChoiceCard(choiceCard.claim_code)}
                            >
                              <Suspense>
                                <IconComponents.bin />
                              </Suspense>
                            </BinWrapper>
                          )}
                        </Actions>
                      }
                    />
                  )
                })
              ) : (
                <div>{t('mainChoiceCardMissing')}</div>
              )}
            </AddedChoiceCards>
            <StyledChoiceCardsSummary count={count} currency={currency} sum={sum} />
          </div>
          <div>
            <Subtitle>{t('addChoiceCard')}</Subtitle>
            <Text>{t('addChoiceCardExplanation')}</Text>
            <StyledTextInput
              description={t('forms.fields.cardCode')}
              name="choiceCardInput"
              placeholder={t('forms.placeholders.addChoiceCard')}
            />
            {loading && <LoadingButton />}
            {success && <SuccessButton />}
            {!loading && !success && (
              <PrimaryButton
                disabled={addChoiceCardDisabled || !formikValues.choiceCardInput}
                iconLeft={Icons.Wallet}
                onClick={handleAddChoiceCard}
                type="button"
              >
                {t('forms.actions.addChoiceCard')}
              </PrimaryButton>
            )}
          </div>
        </Content>
      </Root>
    </Modal>
  )
}

const StyledChoiceCardsSummary = styled(ChoiceCardsSummary)`
  margin-bottom: 24px;
`

const AddedChoiceCards = styled.div`
  max-height: 180px;
  overflow-y: auto;
  margin-bottom: 8px;
`

const Subtitle = styled(Fonts.TitleHeaderH2S)`
  margin-bottom: 8px;
`

const Text = styled(Fonts.BodyRegular)`
  color: ${colours.mist[600]};
  margin-bottom: 24px;
`

const Root = styled.div`
  height: 600px;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 800px;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    max-width: 600px;
  }
`

const Ornament = styled.div`
  background-color: ${colours.blues[10]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const Content = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
`

const Title = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 16px;
`

const CardsImage = styled.img`
  width: 168px;
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: 16px;
`

const ChoiceCardBarListItem = styled(ChoiceCardBarV2)`
  margin-bottom: 16px;
`

const Actions = styled(Fonts.ButtonTextLink)`
  align-items: center;
  color: ${colours.brand.blue};
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 0 4px 0 8px;
`

const BinWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
