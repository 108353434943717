import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { ParagraphTitleCSS } from '@components/atoms/typography/css/ParagraphTitleCSS'

export const ParagraphTitle: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => <Root {...props}>{children}</Root>

const Root = styled.div`
  ${ParagraphTitleCSS}
`
