import { Icons } from '@typeDeclarations/components/atoms/icons'

import {
  Arch,
  ArrowLeft,
  ArrowRight,
  BigSmile,
  Bin,
  Bulb,
  CardStack,
  Cart,
  Checkmark,
  Chevron,
  CircledInfo,
  CircledPlus,
  CloseOutline,
  Contract,
  Copy,
  CreditCard,
  Cross,
  EmptyCart,
  Envelope,
  EnvelopeOpen,
  ExclamationMark,
  ExternalResource,
  EyeClosed,
  EyeOpen,
  FilledCheckmark,
  Gift,
  HeadphonesFace,
  Info,
  Logout,
  Megaphone,
  Minus,
  OpenCircle,
  PaperPlane,
  Pen,
  Plus,
  Receipt,
  Shop,
  Tada,
  Wallet,
  Warning,
  Share,
  Link,
  People,
  Letter,
  Whatsapp,
  Twitter,
} from './Index'

export const IconComponents = {
  [Icons.Arch]: Arch,
  [Icons.ArrowLeft]: ArrowLeft,
  [Icons.ArrowRight]: ArrowRight,
  [Icons.BigSmile]: BigSmile,
  [Icons.Bulb]: Bulb,
  [Icons.Bin]: Bin,
  [Icons.Cart]: Cart,
  [Icons.Checkmark]: Checkmark,
  [Icons.Chevron]: Chevron,
  [Icons.CircledPlus]: CircledPlus,
  [Icons.CloseOutline]: CloseOutline,
  [Icons.Cross]: Cross,
  [Icons.EmptyCart]: EmptyCart,
  [Icons.Envelope]: Envelope,
  [Icons.ExclamationMark]: ExclamationMark,
  [Icons.ExternalResource]: ExternalResource,
  [Icons.EyeClosed]: EyeClosed,
  [Icons.EyeOpen]: EyeOpen,
  [Icons.HeadphonesFace]: HeadphonesFace,
  [Icons.Info]: Info,
  [Icons.Megaphone]: Megaphone,
  [Icons.Minus]: Minus,
  [Icons.Pen]: Pen,
  [Icons.Plus]: Plus,
  [Icons.Shop]: Shop,
  [Icons.Tada]: Tada,
  [Icons.Warning]: Warning,
  [Icons.CircledInfo]: CircledInfo,
  [Icons.FilledCheckmark]: FilledCheckmark,
  [Icons.Logout]: Logout,
  [Icons.CardStack]: CardStack,
  [Icons.Contract]: Contract,
  [Icons.Receipt]: Receipt,
  [Icons.Gift]: Gift,
  [Icons.EnvelopeOpen]: EnvelopeOpen,
  [Icons.CreditCard]: CreditCard,
  [Icons.PaperPlane]: PaperPlane,
  [Icons.OpenCircle]: OpenCircle,
  [Icons.Wallet]: Wallet,
  [Icons.Copy]: Copy,
  [Icons.Share]: Share,
  [Icons.Link]: Link,
  [Icons.People]: People,
  [Icons.Letter]: Letter,
  [Icons.Whatsapp]: Whatsapp,
  [Icons.Twitter]: Twitter,
}
