import { FeePaymentOptionEnum } from '@typeDeclarations/feePaymentOption'

export const determineIfDeducted = (
  feePaymentOption: FeePaymentOptionEnum | null | undefined,
  denominatedValues: boolean | null | undefined,
): boolean | undefined => {
  if (denominatedValues) return false
  if (feePaymentOption === FeePaymentOptionEnum.FeeViaPayment) return false
  if (feePaymentOption === FeePaymentOptionEnum.DeductFromCard) return true

  return undefined
}
