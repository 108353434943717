import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Languages } from '@constants/language'
import { DivProps } from '@typeDeclarations/elements/div'
import { OrderItem } from '@typeDeclarations/orderItem'
import { calculateCardValueWithFee } from '@utils/calculateCardValueWithFee'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'

type OrderList = {
  items?: OrderItem[]
}

export const OrderList: React.FC<PropsWithChildren<OrderList & DivProps>> = ({
  items,
  ...props
}) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language as Languages

  return (
    <Root {...props}>
      {items?.length === 0 && <RowTitle>{t('noItems')}</RowTitle>}
      {items?.map((item, idx) => {
        const parsedAmount = !Number.isNaN(Number(item.amount)) ? Number(item.amount) : undefined
        const parsedQuantity = !Number.isNaN(Number(item.quantity))
          ? Number(item.quantity)
          : undefined

        const feeInformation = calculateCardValueWithFee(
          parsedAmount,
          item.feePaymentOption,
          item.feeFlat,
          item.feePercentage,
          item.denominatedValues,
        )

        const balance = getLocalisedPrice(language, feeInformation.cardBalance, item.currency)

        return (
          <Row key={`${item.id ?? idx}-${item.amount}-${item.quantity}`}>
            <ColumnLeft>
              {typeof parsedQuantity === 'number' ? <Amount>{parsedQuantity}</Amount> : null}
              <RowTitle>{item.title}</RowTitle>
            </ColumnLeft>
            {typeof parsedAmount === 'number' && typeof parsedQuantity === 'number' && (
              <ColumnRight>{balance}</ColumnRight>
            )}
          </Row>
        )
      })}
    </Root>
  )
}

const Root = styled.div`
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  padding: 12px;
  background-color: ${colours.prisma.white};
`

const Row = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RowTitle = styled(Fonts.BodyLarge)`
  color: ${colours.brand.dark};
`

const ColumnLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ColumnRight = styled(Fonts.BodyLarge)`
  color: ${colours.mist[600]};
`

const Amount = styled(Fonts.BodyLarge)`
  color: ${colours.brand.dark};
  &:after {
    content: 'x';
    margin: 0 4px;
  }
`
