import { useRef } from 'react'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { hsl2rgb } from '@utils/hsl2rgb'
import { lightenOrDarken } from '@utils/ligthenOrDarken'
import { randomHslColor } from '@utils/randomHslColor'

const RandomWithBlackText: React.FC = () => {
  const hsl = randomHslColor()
  const darkHsl = lightenOrDarken(hsl, -0.1)
  const rgb = hsl2rgb(darkHsl)

  const color = useRef<string>(rgb)

  return (
    <Root $bg={color.current}>
      <Large>Connect</Large>
    </Root>
  )
}

const Root = styled.div<{ $bg: string }>`
  background-color: ${(p) => p.$bg};
  width: 100%;
  aspect-ratio: 1.6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`

const Large = styled(Fonts.TitleHeaderH1S)`
  color: white;
  text-shadow: 1px 1px 20px #000;
`

export default RandomWithBlackText
