import { useTranslation } from 'react-i18next'

import { Banner } from '@components/atoms/tooltips/Banners/Banner'

export const CardsIssuingTakesLongerBanner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Banner title={t('issuingCardsTakeLonger')} type="tipIdea">
      {t('issuingCardsTakeLongerDescription')}
    </Banner>
  )
}
