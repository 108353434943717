import Fraction from 'fraction.js'
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from 'react'

import { creditCardFactor } from '@constants/paymentProcessingFee'
import { useMainKeyContext } from '@hooks/useMainKey'
import { api } from '@services/api'
import { CalculateFeesResponse } from '@typeDeclarations/api/calculateFees'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { calculateCardValueWithFee } from '@utils/calculateCardValueWithFee'
import { determineIfDeducted } from '@utils/determineIfDeducted'
import { getSummaryValues } from '@utils/getSummaryValues'

import { CartItem } from './cart'

const itemsMapper = (card: CartItem) => ({
  id: card.id,
  amount: card.amount,
  currency: card.currency,
  denominatedValues: card.denominatedValues,
  feeFlat: card.feeFlat,
  feePaymentOption: card.feePaymentOption,
  feePercentage: card.feePercentage,
  quantity: card.quantity,
  title: card.name,
})

export type CheckoutItem = ReturnType<typeof itemsMapper>

type CheckoutContextType = {
  amountFromChoiceCards: number | undefined
  balanceFromCards: number | undefined
  calculatedFees: CalculateFeesResponse | undefined
  choiceCardsCount: number | undefined
  getToPay: (currency?: string) => number | undefined
  getFeesFromBackend: (
    newValues?: Partial<CheckoutFormikValues>,
    u?: string,
  ) => void | Promise<void>
  items: CheckoutItem[] | undefined
  paymentProcessingFee: PaymentProcessingFee | undefined
  priceFromCards: number | undefined
  totalCardsCount: number | undefined
  setCheckoutValues: Dispatch<SetStateAction<CheckoutFormikValues | undefined>>
}

const defaultCtx = {
  amountFromChoiceCards: undefined,
  balanceFromCards: undefined,
  choiceCardsCount: undefined,
  paymentProcessingFee: undefined,
  priceFromCards: undefined,
  totalCardsCount: undefined,
  items: undefined,
  calculatedFees: undefined,
  getToPay: () => undefined,
  getFeesFromBackend: () => undefined,
  setCheckoutValues: () => undefined,
}

export const CheckoutContext = createContext<CheckoutContextType>(defaultCtx)

export const CheckoutContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { mainKey } = useMainKeyContext()
  const [values, setCheckoutValues] = useState<CheckoutFormikValues>()

  const [calculatedFees, setCalculatedFees] = useState<CalculateFeesResponse>()
  const [, setError] = useState()

  const primary_claim_code = mainKey ?? ''

  const getFeesFromBackend = (newValues?: Partial<CheckoutFormikValues>) => {
    if (!values) return
    const f = (x: number) => new Fraction(x)

    const payment_method = (newValues?.paymentMethod ?? values.paymentMethod) || null

    const purchased_products = (newValues?.cards ?? values.cards).map((card) => ({
      value: card.amount,
      amount: card.quantity,
      product: Number(card.id),
      used_fee_value: 0,
    }))

    return api
      .calculateFees({
        payment_method,
        purchased_products,
        used_giftcards: [{ claim_code: primary_claim_code, debit_value: 0 }],
        primary_claim_code,
      })
      .then((firstResponse) => {
        const productsWithFees = firstResponse.products_with_fees
        const purchased_products = (newValues?.cards ?? values.cards).map((card, idx) => {
          const isDeducted = determineIfDeducted(card.feePaymentOption, card.denominatedValues)

          return {
            value: isDeducted
              ? f(card.amount).sub(productsWithFees[idx].fee).valueOf()
              : card.amount,
            amount: card.quantity,
            product: Number(card.id),
            used_fee_value: isDeducted ? productsWithFees[idx].fee : 0,
          }
        })

        return api.calculateFees({
          payment_method,
          purchased_products,
          used_giftcards: [{ claim_code: primary_claim_code, debit_value: 0 }],
          primary_claim_code,
        })
      })
      .then((secondResponse) => {
        const productsWithFees = secondResponse.products_with_fees
        let totalWithFees = secondResponse.order_value.total_amount_with_product_fee

        const used_giftcards = (newValues?.choiceCardsToUse ?? values.choiceCardsToUse)
          .map(({ claim_code, debit_value }) => ({
            claim_code,
            debit_value,
          }))
          .map(({ claim_code, debit_value }) => {
            const middle = Math.max(f(totalWithFees).sub(debit_value).valueOf(), 0)
            const deducted = f(totalWithFees).sub(middle).valueOf()
            totalWithFees = f(totalWithFees).sub(deducted).valueOf()

            return { claim_code, debit_value: deducted }
          })

        const purchased_products = (newValues?.cards ?? values.cards).map((card, idx) => {
          const isDeducted = determineIfDeducted(card.feePaymentOption, card.denominatedValues)

          return {
            value: isDeducted
              ? f(card.amount).sub(productsWithFees[idx].fee).valueOf()
              : card.amount,
            amount: card.quantity,
            product: Number(card.id),
            used_fee_value: isDeducted ? productsWithFees[idx].fee : 0,
          }
        })

        return api.calculateFees({
          payment_method,
          purchased_products,
          used_giftcards,
          primary_claim_code,
        })
      })
      .then((thirdResponse) => {
        thirdResponse.products_with_fees = thirdResponse.products_with_fees.map(
          (productWithFee) => ({
            ...productWithFee,
            name: (newValues?.cards ?? values.cards).find(
              (el) => Number(el.id) === Number(productWithFee.product_pk),
            )?.name,
          }),
        )
        setCalculatedFees(thirdResponse)
      })
      .catch((e: unknown) => {
        if (e instanceof TypeError)
          setError(() => {
            throw e
          })

        console.warn('[Calculate fees] Something went wrong getting fees', e)
      })
  }

  const items = values?.cards.map(itemsMapper)

  const priceFromCards = values?.cards.reduce((acc, cur) => {
    const { cardPrice } = calculateCardValueWithFee(
      cur.amount,
      cur.feePaymentOption,
      cur.feeFlat,
      cur.feePercentage,
      cur.denominatedValues,
    )

    return acc + cardPrice * cur.quantity
  }, 0)

  const balanceFromCards = values?.cards.reduce((acc, cur) => {
    const { cardBalance } = calculateCardValueWithFee(
      cur.amount,
      cur.feePaymentOption,
      cur.feeFlat,
      cur.feePercentage,
      cur.denominatedValues,
    )

    return acc + cardBalance * cur.quantity
  }, 0)

  const paymentProcessingFee: PaymentProcessingFee | undefined =
    values?.paymentMethod === 'creditcard' ? { type: 'factor', value: creditCardFactor } : undefined

  const amountFromChoiceCards = values?.choiceCardsToUse.reduce(
    (acc, cur) => acc + cur.debit_value,
    0,
  )

  const choiceCardsCount = values?.choiceCardsToUse.length
  const totalCardsCount = values?.cards.map(({ quantity }) => quantity).reduce((a, c) => a + c, 0)

  const getToPay = (currency?: string) => {
    if (!values || !currency) return undefined

    return getSummaryValues({
      priceFromCards,
      amountFromChoiceCards,
      paymentProcessingFee,
      currency: values.choiceCardsToUse[0]?.currency ?? values.cards[0]?.currency,
    }).toPay
  }

  if (!values)
    return (
      <CheckoutContext.Provider value={{ ...defaultCtx, setCheckoutValues: setCheckoutValues }}>
        {children}
      </CheckoutContext.Provider>
    )

  return (
    <CheckoutContext.Provider
      value={{
        amountFromChoiceCards,
        balanceFromCards,
        calculatedFees,
        choiceCardsCount,
        getFeesFromBackend,
        getToPay,
        items,
        paymentProcessingFee,
        priceFromCards,
        setCheckoutValues,
        totalCardsCount,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}
