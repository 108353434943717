import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { ProgressDots } from '@components/atoms/ProgressDots/ProgressDots'
import { TitleHeaderH1LCSS, TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { mediaQueries } from '@utils/mediaQueries'

export const TitleWithProgressSection: React.FC<{ count: number; step: number }> = ({
  count,
  step,
}) => {
  const { t } = useTranslation()
  return (
    <Root>
      <CheckoutReviewTitle>
        <MainTitle>{t('checkout')}</MainTitle>
        <FromDesktop>
          <Subtitle>{t('letsBringCardsHome')}</Subtitle>
        </FromDesktop>
      </CheckoutReviewTitle>
      <StyledProgressDots
        count={count}
        step={step}
        stepNames={[t('review'), t('payment'), t('orderCompleted')]}
      />
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: 1fr 2fr;
    margin-bottom: 40px;
  }
`

const CheckoutReviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${mediaQueries.from.breakpoint.tablet} {
    margin: 8px 92px 0 0;
  }
  ${mediaQueries.within.breakpoint.tablet} {
    margin-bottom: 12px;
  }
`

const Subtitle = styled(Fonts.IntroSubtextLarge)`
  color: ${colours.mist[700]};
  ${mediaQueries.from.breakpoint.desktop} {
    white-space: nowrap;
  }
`

const MainTitle = styled.div`
  width: fit-content;
  ${mediaQueries.from.breakpoint.desktop} {
    ${TitleHeaderH1LCSS}
  }

  ${mediaQueries.to.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }

  ${mediaQueries.to.breakpoint.tablet} {
    margin-bottom: 16px;
  }
`

const StyledProgressDots = styled(ProgressDots)`
  ${mediaQueries.to.breakpoint.tablet} {
    margin-bottom: 40px;
  }
  ${mediaQueries.within.breakpoint.tablet} {
    margin-bottom: 40px;
  }
`

const FromDesktop = styled.div`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`
