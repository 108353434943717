import { confirmSignUp } from 'aws-amplify/auth'

export const checkUserExists = async (email: string): Promise<boolean> => {
  if (!email) throw new Error('Cannot check if user exist without username')

  try {
    await confirmSignUp({ username: email.trim().toLowerCase(), confirmationCode: '000000' })
    return true
  } catch (e) {
    if (e instanceof Error) return e.name !== 'UserNotFoundException'
    return false
  }
}
