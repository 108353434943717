const {
  VITE_API_CHANNEL,
  VITE_API_ENDPOINT,
  VITE_CONFIGCAT_KEY,
  VITE_CONFIGCAT_BASE_URL,
  VITE_DOTENV,
  VITE_DATADOG_CLIENT_TOKEN,
  VITE_AMPLIFY_AUTH_IDENTITY_REGION,
  VITE_AMPLIFY_AUTH_USER_POOL_ID,
  VITE_AMPLIFY_AUTH_USER_POOL_WEBCLIENT_ID,
  VITE_EXTENDED_LANGUAGES,
  VITE_TRANSLATIONS_APP_ID,
  VITE_TRANSLATIONS_ENDPOINT,
  VITE_VERSION
} = import.meta.env

export const envs = {
  VITE_API_CHANNEL,
  VITE_API_ENDPOINT,
  VITE_CONFIGCAT_KEY,
  VITE_CONFIGCAT_BASE_URL,
  VITE_DOTENV,
  VITE_DATADOG_CLIENT_TOKEN,
  VITE_AMPLIFY_AUTH_IDENTITY_REGION,
  VITE_AMPLIFY_AUTH_USER_POOL_ID,
  VITE_AMPLIFY_AUTH_USER_POOL_WEBCLIENT_ID,
  VITE_EXTENDED_LANGUAGES,
  VITE_TRANSLATIONS_APP_ID,
  VITE_TRANSLATIONS_ENDPOINT,
  VITE_VERSION
}
