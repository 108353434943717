// 0 <= h < 360 | 0 <= S <= 1 and 0 <= L <= 1:
export const hsl2rgb = (hsl: [number, number, number]) => {
  const { abs, round } = Math
  const [H, S, L] = hsl

  const C = (1 - abs(2 * L - 1)) * S
  const U = H / 60
  const X = C * (1 - abs((U % 2) - 1))

  let r1g1b1: number[] = []

  if (U >= 0 && U < 1) r1g1b1 = [C, X, 0]
  if (U >= 1 && U < 2) r1g1b1 = [X, C, 0]
  if (U >= 2 && U < 3) r1g1b1 = [0, C, X]
  if (U >= 3 && U < 4) r1g1b1 = [0, X, C]
  if (U >= 4 && U < 5) r1g1b1 = [X, 0, C]
  if (U >= 5 && U < 6) r1g1b1 = [C, 0, X]

  const m = L - C / 2

  const rgb_0_to_1 = [r1g1b1[0] + m, r1g1b1[1] + m, r1g1b1[2] + m]
  const rgb_255 = rgb_0_to_1.map((color) => round(255 * color))
  const rgb_hex = rgb_255.map((color) => color.toString(16)).map((color) => color.padStart(2, '0'))

  const hexString = `#${rgb_hex.join('')}`

  return hexString
}
