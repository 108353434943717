import { PropsWithChildren, Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

export const BoxWithTick: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Root>
      {children}
      <CheckmarkWrapper>
        <Suspense>
          <IconComponents.checkmark
            fill={colours.prisma.white}
            height="16"
            stroke={colours.prisma.white}
            width="16"
          />
        </Suspense>
      </CheckmarkWrapper>
    </Root>
  )
}

const Root = styled(Fonts.ButtonCTALabelLarge)`
  background-color: ${colours.blues[10]};
  border-radius: 8px;
  border: 2px solid ${colours.brand.blue};
  color: ${colours.brand.blue};
  padding: 16px;
  position: relative;
`

const CheckmarkWrapper = styled.div`
  width: 24px;
  background-color: ${colours.brand.blue};
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
