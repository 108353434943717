import { colours } from '@configs/colours'
import { PropsWithChildren, Suspense } from 'react'
import styled from 'styled-components'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Icons } from '@typeDeclarations/components/atoms/icons'

type Props = { icon: Icons }

export const InfoWithBadge: React.FC<PropsWithChildren<Props>> = ({ children, icon }) => {
  const I = IconComponents[icon]

  return (
    <Root>
      <Container>
        <Badge>
          <Suspense>{<I fill={colours.prisma.white} stroke={colours.prisma.white} />}</Suspense>
        </Badge>
        <Text>{children}</Text>
      </Container>
    </Root>
  )
}
const Root = styled.div``

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 16px;
  background: ${colours.mist[100]};
  border-radius: 16px;
  color: ${colours.mist[800]};
  ${BodyRegularCSS};
  width: fit-content;
  position: relative;
  margin-top: 22px;
`

const Text = styled.div`
  text-align: center;
`

const Badge = styled.div`
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  background-color: ${colours.brand.blue};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
`
