import styled from 'styled-components'

import { mediaQueries } from '@utils/mediaQueries'

export const SummaryGridArrangement = styled.div`
  display: grid;
  ${mediaQueries.to.breakpoint.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-template-areas: 'orderInfo' 'receiptInfo';
    column-gap: 40px;
    row-gap: 48px;
  }

  ${mediaQueries.from.breakpoint.tablet} {
    grid-template-columns: 1fr 400px;
    grid-template-rows: repeat(2, min-content);
    column-gap: 40px;
    grid-template-areas: 'orderInfo receiptInfo';
  }
`
