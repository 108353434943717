import { getCurrentUser } from 'aws-amplify/auth'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AccountFormsWidthBoundary } from '@components/atoms/Content/AccountFormsWidthBoundary'
import { Footer } from '@components/atoms/Footer/Footer'
import { Fonts } from '@components/atoms/typography/Fonts'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { LoginActionArgs, LoginForm } from '@components/organisms/forms/LoginForm/LoginForm'
import { colours } from '@configs/colours'
import { shareKeyPaths } from '@configs/urls'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useUserContext } from '@hooks/useUserContext'
import { Flows } from '@services/api.types'
import { receiveClaim } from '@utils/claimHelpers'
import { mediaQueries } from '@utils/mediaQueries'

const Account: React.FC = () => {
  const { t } = useTranslation()
  const { hashFlows, setEmailDelayInfo } = useHashFlowContext()
  const navigate = useNavigate()
  const { shareKey = '' } = useParams()
  const location = useLocation()
  const { setEmail, setIsLoggedIn } = useUserContext()

  const initialEmail = new URLSearchParams(location.search).get('email')

  const flow = hashFlows[shareKey]?.flow
  const skip_claim_step = hashFlows[shareKey]?.skip_claim_step

  if (typeof flow === 'undefined')
    return <Navigate to={generatePath(shareKeyPaths.confetti, { shareKey })} />

  const loginAction = async ({ unconfirmed, username: userEmail }: LoginActionArgs) => {
    if (unconfirmed) {
      await receiveClaim({
        username: null,
        email: userEmail,
        shareKey,
        unconfirmed_user: true,
        navigate,
        setEmailDelayInfo,
        setEmail,
        setIsLoggedIn,
      })
    }

    const { username, signInDetails } = await getCurrentUser()
    const email = signInDetails?.loginId

    if (!email || !username) throw new Error('Not enough information to receive claim')

    await receiveClaim({
      username,
      email,
      shareKey,
      unconfirmed_user: false,
      navigate,
      setEmailDelayInfo,
      setEmail,
      setIsLoggedIn,
    })
  }

  const maybeLaterAction = async (email: string | null) => {
    if (!email && skip_claim_step) throw new Error('Could not determine email')
    const transformedEmail = email ? email.trim().toLowerCase() : null

    await receiveClaim({
      username: null,
      email: transformedEmail,
      shareKey,
      unconfirmed_user: false,
      navigate,
      setEmailDelayInfo,
      setEmail,
      setIsLoggedIn,
    })
  }

  return (
    <Root>
      <AccountFormsWidthBoundary>
        <Arrangement>
          <StyledLoginForm
            footer={(form) =>
              form === 'login' && (
                <AccountFormFooter>
                  <SignUpAction>
                    <Blue>{t('noAccountQuestion')}</Blue>
                    <WideLink
                      to={
                        generatePath(shareKeyPaths.signUp, { shareKey }) +
                        (initialEmail ? `?email=${initialEmail}` : '')
                      }
                    >
                      <BlueLink>{t('forms.actions.signUp')}</BlueLink>
                    </WideLink>
                  </SignUpAction>
                  {flow === Flows.ShopAccountOptional && (
                    <Formik
                      enableReinitialize
                      initialValues={{ email: initialEmail }}
                      onSubmit={({ email }) => maybeLaterAction(email)}
                    >
                      <WithTranslateFormErrors>
                        <Form>
                          <SecondaryButton type="submit">{t('maybeLater')}</SecondaryButton>
                        </Form>
                      </WithTranslateFormErrors>
                    </Formik>
                  )}
                </AccountFormFooter>
              )
            }
            initialEmail={initialEmail}
            loginAction={loginAction}
            throwWhenAccountUnconfirmed={false}
          >
            {(form) => (
              <>
                {form === 'login' && (
                  <TopInfo>
                    <Greeting>{t('welcomeBack')}</Greeting>
                    {flow === Flows.ShopAccountMandatory && (
                      <Subtitle>{t('accountRequiredToClaim')}</Subtitle>
                    )}
                  </TopInfo>
                )}
                {form === 'forgotPassword' && (
                  <TopInfo>
                    <Greeting>{t('didYouForgetPassword')}</Greeting>
                    <Subtitle>{t('enterEmailToRemindPassword')}</Subtitle>
                  </TopInfo>
                )}
                {form === 'forgotPasswordProceed' && (
                  <TopInfo>
                    <Greeting>{t('almostThere')}</Greeting>
                    <Subtitle>{t('enterCodeAndNewPassword')}</Subtitle>
                  </TopInfo>
                )}
                {form === 'confirmSignInWithNewPasswordRequired' && (
                  <TopInfo>
                    <Greeting>{t('newPassword')}</Greeting>
                    <Subtitle>{t('passwordChangeRequired')}</Subtitle>
                  </TopInfo>
                )}
              </>
            )}
          </StyledLoginForm>
        </Arrangement>
      </AccountFormsWidthBoundary>
      <Footer />
    </Root>
  )
}

const AccountFormFooter = styled.div`
  margin-top: 80px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-top: 48px;
  }
`

const StyledLoginForm = styled(LoginForm)`
  margin-bottom: 64px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 48px;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(180deg, #fff 1%, rgb(234, 238, 239) 100%);
`

const TopInfo = styled.div`
  margin-bottom: 40px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: calc(13 * 8px);
  }
`

const Blue = styled(Fonts.BodyLarge)`
  color: ${colours.blues[100]};
`

const SignUpAction = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`

const BlueLink = styled(Fonts.BodyLarge)`
  color: ${colours.blues[100]};
  text-decoration: underline;
  cursor: pointer;
`

const Arrangement = styled.div`
  margin-top: 32px;
`

const WideLink = styled(Link)`
  display: block;
  margin-bottom: 16px;
`

const Greeting = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 24px;
  text-align: center;
`

const Subtitle = styled(Fonts.BodyLarge)`
  color: ${colours.mist[800]};
  text-align: center;
`

export default Account
