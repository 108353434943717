import { TFunction } from 'i18next'

import * as y from 'yup'

import { CatalogueProduct } from '@services/api.types'
import { FeePaymentOptionEnum } from '@typeDeclarations/feePaymentOption'
import { calculateCardValueWithFee } from '@utils/calculateCardValueWithFee'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'

export const getValidationSchema = (
  language: string,
  t: TFunction,
  product?: CatalogueProduct,
  maxPurchaseQuantity?: number | null,
  totalCardsInCart?: number | null,
  maxQuantitityOfThisType?: number | null,
  totalCardsOfThisType?: number | null,
  maxPurchaseAmount?: number | null,
  totalAmountInCart?: number | null,
  feePaymentOption?: FeePaymentOptionEnum | null,
  feeFlat?: number | null,
  feePercentage?: number | null,
) => {
  const maxQtyCount = maxPurchaseQuantity ?? undefined
  const maxQuantityOfThisTypeCount = maxQuantitityOfThisType ?? undefined
  const maxAmountCount = maxPurchaseAmount ?? undefined
  const maxValue = product ? (!Number.isNaN(+product.max_value) ? +product.max_value : Infinity) : 0
  const minValue = product ? (!Number.isNaN(+product.min_value) ? +product.min_value : 0) : 0

  return y.object({
    quantity: y
      .number()
      .integer(t('forms.validation.integer'))
      .positive(t('forms.validation.positive'))
      .min(1, ({ min }) => t('forms.validation.minAmount', { value: min }))
      .test(
        'dontExceedMaxQuantity',
        t('forms.validation.quantityExceeded', { maxCount: maxQtyCount }),
        (fieldValue) => {
          if (typeof maxPurchaseQuantity !== 'number') return true
          if (typeof totalCardsInCart !== 'number') return true
          if (!fieldValue) return true

          return fieldValue + totalCardsInCart <= maxPurchaseQuantity
        },
      )
      .test(
        'dontExceedQuantityOfThisType',
        t('forms.validation.quantityOfTypeExceeded', { count: maxQuantityOfThisTypeCount }),
        (fieldValue) => {
          if (typeof maxQuantitityOfThisType !== 'number') return true
          if (typeof totalCardsOfThisType !== 'number') return true
          if (!fieldValue) return true

          return fieldValue + totalCardsOfThisType <= maxQuantitityOfThisType
        },
      )
      .required(t('forms.validation.required')),
    amount: y
      .number()
      .positive(t('forms.validation.positive'))
      .required(t('forms.validation.required'))
      .min(product ? (!Number.isNaN(+product.min_value) ? +product.min_value : 0) : 0, ({ min }) =>
        t('forms.validation.redeemMin', {
          localisedPrice: getLocalisedPrice(language, min, product?.currency),
        }),
      )
      .max(maxValue, ({ max }) =>
        t('forms.validation.redeemMax', {
          localisedPrice: getLocalisedPrice(language, max, product?.currency),
        }),
      )
      .test(
        'dontExceedMaxAmount',
        t('forms.validation.amountExceeded', {
          balance: getLocalisedPrice(language, maxAmountCount, product?.currency),
        }),
        (fieldValue) => {
          if (typeof maxPurchaseAmount !== 'number') return true
          if (typeof totalAmountInCart !== 'number') return true
          if (!fieldValue) return true
          return fieldValue + totalAmountInCart <= maxPurchaseAmount
        },
      )
      .test('maxTwoDecimals', t('forms.validation.maxTwoDecimals'), (fieldValue) => {
        if (typeof fieldValue !== 'number') return true
        const [, decimals] = fieldValue.toString().split('.')
        if (decimals && decimals.length > 2) return false
        return true
      })
      .test(
        'withDeductedFeeAboveMin',
        ({ value }) => {
          if (typeof value !== 'number') return true
          return t('forms.validation.withDeductedFeeBelowMinimum', {
            localisedPrice: getLocalisedPrice(language, minValue, product?.currency),
            localisedPriceAfterDeduction: getLocalisedPrice(
              language,
              calculateCardValueWithFee(value, feePaymentOption, feeFlat, feePercentage)
                .cardBalance,
              product?.currency,
            ),
          })
        },
        (fieldValue) => {
          const { cardBalance } = calculateCardValueWithFee(
            fieldValue,
            feePaymentOption,
            feeFlat,
            feePercentage,
          )

          return cardBalance > 0 && cardBalance >= minValue
        },
      ),
  })
}
