import { api } from '@services/api'

import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'

import { parseConfigValues } from './parseConfigValues'

export const evaluateLimits = async (values: CheckoutFormikValues, mainKey: string) => {
  const totalQuantityOfCards = values.cards.reduce((acc, cur) => acc + cur.quantity, 0)
  const totalPurchaseAmount = values.cards.reduce((acc, cur) => acc + cur.quantity * cur.amount, 0)

  const { config } = await api.cardDetail(mainKey)
  const { maxPurchaseAmount, maxPurchaseQuantity } = parseConfigValues(config)

  const totalAmountViolated = maxPurchaseAmount !== null && totalPurchaseAmount > maxPurchaseAmount
  const totalQuantityViolated =
    maxPurchaseQuantity !== null && totalQuantityOfCards > maxPurchaseQuantity

  const violated = totalAmountViolated || totalQuantityViolated

  return {
    violated,
    totalAmount: {
      violated: totalAmountViolated,
      current: totalPurchaseAmount,
      max: maxPurchaseAmount,
    },
    totalQuantity: {
      violated: totalQuantityViolated,
      current: totalQuantityOfCards,
      max: maxPurchaseQuantity,
    },
  }
}

export type LimitsViolations = Awaited<ReturnType<typeof evaluateLimits>>
