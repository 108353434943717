import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { WhiteCard } from '@components/atoms/cards/WhiteCard'
import { Chip } from '@components/atoms/Chip/Chip'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { colours } from '@configs/colours'
import { Languages } from '@constants/language'
import { zIndexes } from '@constants/zIndexes'
import { useCartContext } from '@hooks/useCart'
import { useSettingsContext } from '@hooks/useSettings'
import { getCurrencySymbol } from '@utils/getCurrencySymbol'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'

export const BrandMobileHeader: React.FC = () => {
  const { i18n } = useTranslation()
  const { slotImage } = useSettingsContext()
  const { getCurrentMainChoiceCard } = useCartContext()
  const { debit_value, currency } = getCurrentMainChoiceCard() ?? {}
  const [error, setError] = useState(false)

  const language = i18n.language as Languages
  const noBalance =
    (typeof debit_value === 'number' || typeof debit_value === 'string') &&
    Number(debit_value) === 0

  return (
    <Root>
      <Content>
        <StyledChip $noBalance={noBalance}>
          {noBalance
            ? getCurrencySymbol(currency) + ' - '
            : getLocalisedPrice(language, debit_value, currency)}
        </StyledChip>
        {slotImage && !error && (
          <SlotImage $faded={noBalance} onError={() => setError(true)} src={slotImage} />
        )}
        {(!slotImage || error) && <WhiteCard />}
      </Content>
    </Root>
  )
}

const Root = styled(WidthBoundary)`
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
  margin: 16px 0;
`

const Content = styled.div`
  position: relative;
`

const SlotImage = styled.img<{ $faded?: boolean }>`
  width: 100%;
  aspect-ratio: 1.6;
  outline: 4px solid rgba(255, 255, 255, 0.7);
  outline-offset: -4px;
  border-radius: 16px;
  opacity: ${(p) => (p.$faded ? 0.4 : 1)};
`

const StyledChip = styled(Chip)<{ $noBalance?: boolean }>`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: ${(p) => (p.$noBalance ? colours.brand.purple : colours.prisma.black)};
  z-index: ${zIndexes.baseOnTop};
`
