import { signOut } from 'aws-amplify/auth'
import React, { PropsWithChildren, createContext } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { useSavedState } from '@hooks/useSavedState'
import { getLocalStorageKey } from '@utils/getLocalStorageKey'

type UserContext = {
  email: string
  isLoggedIn: boolean
  setEmail: (e?: string) => void
  setIsLoggedIn: (value: boolean) => void
  logOut: () => Promise<void>
}

const userContextInit = {
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  setEmail: () => {},
  email: '',
  logOut: async () => {},
}

export const UserContext = createContext<UserContext>(userContextInit)

type UserContextProvider = {
  values?: {
    isLoggedIn?: boolean
    email?: string
  }
}

export const UserContextProvider: React.FC<PropsWithChildren<UserContextProvider>> = ({
  children,
  values: { isLoggedIn: initialIsLoggedIn, email: initialEmail } = {},
}) => {
  const initialIsLoggedInValue = initialIsLoggedIn ? JSON.stringify(initialIsLoggedIn) : undefined
  const initialEmailValue = initialEmail ? initialEmail : undefined

  const [isLoggedInRaw, setIsLoggedInRaw] = useSavedState(
    getLocalStorageKey('isLoggedIn'),
    initialIsLoggedInValue,
  )

  const [emailRaw, setEmailRaw] = useSavedState(getLocalStorageKey('email'), initialEmailValue)

  const isLoggedIn = isLoggedInRaw === 'true' ? true : false
  const email = emailRaw ? emailRaw : ''

  const setIsLoggedIn = (loggedIn: boolean) => {
    datadogRum.setUserProperty('is_loggedid', loggedIn)
    setIsLoggedInRaw(loggedIn ? 'true' : 'false')
  }

  const setEmail = (email?: string) => {
    datadogRum.setUserProperty('email', email ? email : '')
    setEmailRaw(email ? email : '')
  }

  const logOut = async () => {
    await signOut()
    setIsLoggedIn(false)
    setEmail('')
    datadogRum.clearUser()
  }

  return (
    <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, email, setEmail, logOut }}>
      {children}
    </UserContext.Provider>
  )
}
