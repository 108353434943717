import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { TitleHeaderH2CSS } from '@components/atoms/typography/css/TitleHeaderH2CSS'

export const TitleHeaderH2: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => <Root {...props}>{children}</Root>

const Root = styled.div`
  ${TitleHeaderH2CSS}
`
