const DDPrefix = '[Datadog Info]'

export const datadogMessages = {
  attemptedOrderWhenOrderIdPresent: `${DDPrefix} This cart has proceeded to order once and another attempt has been made`,
  cardsOrderedPaymentless: `${DDPrefix} Cards ordered without the need of payment`,
  checkoutEntered: `${DDPrefix} Checkout entered`,
  choiceCardAdded: `${DDPrefix} Choice card added (top up)`,
  choiceCardAddingFailed: `${DDPrefix} Choice card failed to add`,
  choiceCardClaimed: `${DDPrefix} Choice card claimed`,
  flowEntered: `${DDPrefix} Flow entered - `,
  httpError: `${DDPrefix} Http error`,
  notInstanceOfError: `${DDPrefix} Error that occurred is not an instance of Error`,
  paymentStarted: `${DDPrefix} Payment started`,
  paymentSucceeded: `${DDPrefix} Payment succeeded`,
  shopEntered: `${DDPrefix} Shop entered`,
  unknownError: `${DDPrefix} An unknown error occurred`,
  userIdNotProvided: `${DDPrefix} userId was not provided`,
  userIdNotReturned: `${DDPrefix} userId was not returned from aws cognito`,
}
