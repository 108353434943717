export class HttpError extends Error {
  status: number
  name: string
  raw?: string
  json: unknown
  sourcePrefix?: string

  constructor(
    message: string | undefined,
    status: string | number,
    text?: string,
    sourcePrefix?: 'Y',
  ) {
    super(message)
    this.status = +status
    this.raw = text
    this.name = `[Response error ${status}]`
    this.sourcePrefix = sourcePrefix

    try {
      const json = (text ? JSON.parse(text) : null) as unknown
      this.json = json
    } catch {
      this.json = null
    }
  }
}

HttpError.stackTraceLimit = 25
