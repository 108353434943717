import { LanguageCodes } from './language'

export const getAboutLinks = (): Record<
  `${LanguageCodes}` | 'cimode',
  { conditions: { href: string }; privacy: { href: string } }
> => ({
  cimode: {
    conditions: { href: 'https://yesty.io/en/nl/terms-and-conditions/' },
    privacy: { href: 'https://yesty.io/en/nl/privacy-statement/' },
  },
  nl: {
    conditions: { href: 'https://yesty.io/nl/nl/algemene-voorwaarden/' },
    privacy: { href: 'https://yesty.io/nl/nl/privacyverklaring/' },
  },
  de: {
    conditions: { href: 'https://yesty.io/de/nl/allgemeine-geschaeftsbedingungen/' },
    privacy: { href: 'https://yesty.io/de/nl/datenschutzerklaerung/' },
  },
  'en-GB': {
    conditions: { href: 'https://yesty.io/en/nl/terms-and-conditions/' },
    privacy: { href: 'https://yesty.io/en/nl/privacy-statement/' },
  },
  pl: {
    conditions: { href: 'https://yesty.io/pl/nl/regulamin/' },
    privacy: { href: 'https://yesty.io/pl/nl/prywatnosci/' },
  },
  fr: {
    conditions: { href: 'https://en.yesty.nl/privacy-statement/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  da: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  sv: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  no: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  it: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  fi: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  lv: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  lt: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  et: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  es: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  cs: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  hu: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  pt: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  el: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
  ro: {
    conditions: { href: 'https://en.yesty.nl/terms-and-conditions/' },
    privacy: { href: 'https://en.yesty.nl/privacy-statement/' },
  },
})
