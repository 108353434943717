import { PATHS, PATH_PARAMS, SUBPATHS } from '@constants/paths'

export const apiBaseURL = import.meta.env.VITE_API_ENDPOINT
export const apiTranslationsURL = import.meta.env.VITE_TRANSLATIONS_ENDPOINT

export const apiPaths = {
  claimCodeCardAdd: (claim_code: string) => `/shop/card/${claim_code}/add/`,
  claimCodeCardDetail: (claim_code: string) => `/shop/card/${claim_code}/`,
  claimCodeCardUpdateBalance: (claim_code: string) => `/shop/card/${claim_code}/update_balance/`,
  claimCodeCheckCardFlow: (claim_code: string) => `/shop/card/${claim_code}/check/`,
  claimCodeClaim: (claim_code: string) => `/shop/card/${claim_code}/claim/`,
  claimCodeShopCatalogue: (claim_code: string) => `/shop/card/${claim_code}/available-products/`,
  orderIdShopOrderDetail: (orderId: string) => `/shop/order/${orderId}/`,
  shareKeyClaim: (share_key: string) => `/shop/receive/${share_key}/claim/`,
  shareKeyInfo: (share_key: string) => `/shop/receive/${share_key}/`,
  shopOrder: () => `/shop/order/`,
  shopSurvey: () => `/survey/`,
  shareKeyByCodes: () => `/shop/receive/check/`,
  activate: (share_key: string) => `/shop/redeem/${share_key}/`,
  calculateFee: (pk: string | number) => `/shop/catalogue/${pk}/calculate-fee/`,
  calculateFees: () => `/shop/order/calculate-fees/`,
}

export const shareKeyPaths = {
  confetti: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.confetti}`,
  claim: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.claim}`,
  claimSkip: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.claimSkip}`,
  preparingChoiceCard: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.preparingChoiceCard}`,
  account: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.account}`,
  signUp: `/${PATHS.receive}/${PATH_PARAMS.shareKey}/${PATHS.signUp}`,
}

export const claimCodePaths = {
  account: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.account}`,
  catalogue: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.catalogue}`,
  product: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.catalogue}/${PATH_PARAMS.productId}`,
  claimCodeSelect: `/${PATHS.card}/${PATHS.claimCodeSelect}`,
  enter: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.enterCode}`,
  root: `/${PATHS.card}/${PATH_PARAMS.claimCode}`,
  signUp: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.signUp}`,
  welcome: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.welcome}`,
}

export const cartSteps = {
  main: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}`,
  overview: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}`,
  checkout: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}/${PATHS.checkout}`,
  checkoutReview: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}/${PATHS.checkout}/${SUBPATHS.checkoutReview}`,
  checkoutPaymentMethod: `/${PATHS.card}/${PATH_PARAMS.claimCode}/${PATHS.cart}/${PATHS.checkout}/${SUBPATHS.checkoutPaymentMethod}`,
}

export const orderSteps = {
  orderSummary: `/${PATHS.order}/${PATH_PARAMS.id}`,
}

export const refer = {
  root: `/${PATHS.refer}`,
}

export const panels = {
  root: `/${PATHS.panels}`,
}
