import { PropsWithChildren } from 'react'
import { styled } from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'

type Props = { count?: number }

export const Badge: React.FC<PropsWithChildren<Props>> = ({ children, count }) => {
  const hideBadge = typeof count !== 'number' || count < 0 || count % 1 !== 0
  const { hashFlows } = useHashFlowContext()
  const { mainKey } = useMainKeyContext()
  const maxPurchaseQuantity = mainKey ? hashFlows[mainKey]?.maxPurchaseQuantity : null

  return (
    <Root data-testid="badge-root" id="badge">
      {children}
      {!hideBadge && count > 0 && (
        <BadgeDot data-testid="badge">
          {count}
          {typeof maxPurchaseQuantity === 'number' && `/${maxPurchaseQuantity}`}
        </BadgeDot>
      )}
    </Root>
  )
}

const Root = styled.div`
  position: relative;
`

const BadgeDot = styled(Fonts.BodyRegular)`
  align-items: center;
  background: linear-gradient(45deg, ${colours.mandarin[100]}, ${colours.mandarin[40]});
  border-radius: 32px;
  color: ${colours.prisma.black};
  display: flex;
  font-weight: bold;
  height: 26px;
  justify-content: center;
  padding: 0 4px;
  position: absolute;
  right: 0;
  top: 0;
`
