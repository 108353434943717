import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ExtendedOrderList } from '@components/atoms/ExtendedOrderList/ExtendedOrderList'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { ZigZagBottom, ZigZagTop } from '@components/atoms/ZigZag/ZigZag'
import { colours } from '@configs/colours'
import { OrderItem } from '@typeDeclarations/orderItem'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  currency?: string
  tornAtTop?: boolean
  tornAtBottom?: boolean
  pastTense?: boolean
  reference?: string | null
  amountFromChoiceCards?: number
  balanceFromCards: number | undefined
  cardsRequireActivation?: boolean
  choiceCardsCount?: number
  items: OrderItem[]
  paymentProcessingFee?: PaymentProcessingFee | undefined
  priceFromCards: number | undefined
}

export const ReceiptSummarySection: React.FCS<Props> = ({
  className,
  currency,
  tornAtTop = false,
  pastTense = false,
  reference,
  amountFromChoiceCards,
  items,
  priceFromCards,
  paymentProcessingFee,
}) => {
  const { t } = useTranslation()

  return (
    <Root className={className}>
      {tornAtTop && <ZigZagTop />}
      <ExtendedSummaryWrapper $tornAtTop={tornAtTop}>
        <TitleArea>
          <YourOrderTitle>
            <SubHeader>
              <Suspense>
                <IconComponents.receipt />
              </Suspense>
              <span>{t('yourOrder')}</span>
            </SubHeader>
          </YourOrderTitle>
          {typeof reference === 'string' && <Reference>{reference}</Reference>}
        </TitleArea>
        <ExtendedOrderList
          amountFromChoiceCards={amountFromChoiceCards}
          currency={currency}
          items={items}
          pastTense={pastTense}
          paymentProcessingFee={paymentProcessingFee}
          priceFromCards={priceFromCards}
        />
      </ExtendedSummaryWrapper>
      <ZigZagBottom />
    </Root>
  )
}

const TitleArea = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const Reference = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.mist[500]};
`

const YourOrderTitle = styled.div`
  margin-bottom: 16px;
`

const SubHeader = styled(Fonts.TitleHeaderH2S)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ExtendedSummaryWrapper = styled.div<{ $tornAtTop?: boolean }>`
  position: relative;
  padding: 24px 16px 16px 16px;
  background-color: ${colours.prisma.white};

  ${mediaQueries.from.breakpoint.mobileL} {
    ${(p) =>
      p.$tornAtTop &&
      `box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.00);`}
    ${(p) =>
      !p.$tornAtTop &&
      `border-top-right-radius: 16px; border-top-left-radius: 16px; box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);`}
  }
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
`
