import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { BrandBar } from '@components/atoms/BrandBar/BrandBar'
import { Nav } from '@components/organisms/Nav/Nav'

import { colours } from '@configs/colours'
import { zIndexes } from '@constants/zIndexes'
import { useSettingsContext } from '@hooks/useSettings'

import yesty from '/images/brands/logos/yesty-new.png'

type Props = {
  noBrandBar?: boolean
  supportLinksSet?: Record<string, string>
}

const GeneralLayout: React.FC<Props> = ({ noBrandBar, supportLinksSet }) => {
  const { imageLogo } = useSettingsContext()

  return (
    <Root>
      <Header id="main-header">
        <Nav Logo={<LogoImg src={imageLogo ?? yesty} />} supportLinksSet={supportLinksSet} />
        {!noBrandBar && <BrandBar />}
      </Header>
      <LanguageSwitcherHandle id="modal-handle" />
      <Outlet />
    </Root>
  )
}

const LanguageSwitcherHandle = styled.div`
  height: 0;
  width: 100%;
  position: relative;
`

const Root = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`

const Header = styled.header`
  width: 100%;
  height: 72px;
  position: sticky;
  top: 0;
  z-index: ${zIndexes.baseOnTop + 3};
  background-color: ${colours.prisma.white};
`

const LogoImg = styled.img`
  height: 32px;
  cursor: pointer;
`

export default GeneralLayout
