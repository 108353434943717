import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Languages } from '@constants/language'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { localisedPriceFactory } from '@utils/getLocalisedPrice'
import { getSummaryValues } from '@utils/getSummaryValues'

type Props = {
  priceFromCards: number | undefined
  amountFromChoiceCards: number | undefined
  choiceCardsCount?: number
  currency: string | undefined
  paymentProcessingFee: PaymentProcessingFee | undefined
  calculatedChoiceCards?: number
  calculatedTotal?: number
}

export const PaymentSummary: React.FC<Props> = ({
  amountFromChoiceCards,
  calculatedChoiceCards,
  calculatedTotal,
  choiceCardsCount,
  currency,
  paymentProcessingFee,
  priceFromCards,
}) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language as Languages

  const {
    choiceCardsDiscountValue: summaryChoiceCardsDiscountValue,
    priceFromCards: summaryPriceFromCards,
  } = getSummaryValues({
    priceFromCards,
    amountFromChoiceCards,
    paymentProcessingFee,
    currency,
  })

  const formatPrice = localisedPriceFactory(language, currency)

  const hasPrecalculatedTotal = typeof calculatedTotal === 'number'
  const hasPrecalculatedChoiceCards = typeof calculatedChoiceCards === 'number'
  const total = hasPrecalculatedTotal ? calculatedTotal : summaryPriceFromCards
  const totalChoiceCards = hasPrecalculatedChoiceCards
    ? calculatedChoiceCards
    : summaryChoiceCardsDiscountValue

  return (
    <Root>
      <Entry>
        <RegularFont>{t('totalAmount')}</RegularFont>
        <ValueLight>{formatPrice(total)}</ValueLight>
      </Entry>
      <Entry>
        <RegularFont>
          {t('choiceCards')} {choiceCardsCount && `(${choiceCardsCount})`}
        </RegularFont>
        <ValueDark>-{formatPrice(totalChoiceCards)}</ValueDark>
      </Entry>
    </Root>
  )
}

const Root = styled.section``

const Entry = styled.div`
  display: flex;
  justify-content: space-between;
`

const RegularFont = styled(Fonts.BodyRegular)`
  color: ${colours.mist[800]};
`

const ValueDark = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.mist[800]};
`
const ValueLight = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.mist[600]};
`
