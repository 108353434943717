import {
  AuthError,
  confirmResetPassword,
  confirmSignIn,
  resetPassword,
  signIn,
  signOut,
} from 'aws-amplify/auth'
import { Form, Formik, FormikHelpers } from 'formik'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as y from 'yup'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { GeneralError } from '@components/molecules/forms/GeneralError/GeneralError'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { colours } from '@configs/colours'
import { MIN_PASSWORD_CHARACTERS } from '@configs/constants'
import { authMessages } from '@constants/authMessages'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useUserContext } from '@hooks/useUserContext'

import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'

export type LoginActionArgs = { username: string; password: string; unconfirmed: Maybe<boolean> }

type FormType =
  | 'login'
  | 'forgotPassword'
  | 'forgotPasswordProceed'
  | 'confirmSignInWithNewPasswordRequired'

type Form = FormType

type Props = {
  loginAction?: (args: LoginActionArgs) => void | Promise<void>
  initialEmail?: string | null
  throwWhenAccountUnconfirmed?: boolean
  children?: (form: Form) => React.ReactNode
  footer?: (form: Form) => React.ReactNode
}

export const LoginForm: React.FCS<Props> = ({
  children,
  initialEmail,
  loginAction,
  throwWhenAccountUnconfirmed = true,
  className,
  footer,
}) => {
  const [form, setForm] = useState<FormType>('login')
  const [generalError, setGeneralError] = useState(false)
  const [knownError, setKnownError] = useState<string>()
  const [unexpectedError, setUnexpectedError] = useState<string>()
  const [confirmAccountEmail, setConfirmAccountEmail] = useState('')
  const closeGeneralError = () => setGeneralError(false)

  const initialLoginValues = { email: '', password: '' }
  const initialForgotPasswordValues = { email: '' }
  const initialForgotPasswordProceedValues = { email: '', code: '', newPassword: '' }
  const initialConfirmSignInWithNewPasswordRequiredValues = { email: '', newPassword: '' }

  const { t } = useTranslation()
  const { isLoggedIn, setIsLoggedIn, setEmail, logOut } = useUserContext()

  const onLoginSubmit = async (
    { password, email }: typeof initialLoginValues,
    { setSubmitting }: FormikHelpers<typeof initialLoginValues>,
  ) => {
    const transformedEmail = email.trim().toLowerCase()

    try {
      setSubmitting(true)

      if (isLoggedIn) {
        await logOut()
        setIsLoggedIn(false)
        setEmail(undefined)
      }

      const { isSignedIn, nextStep } = await signIn({ username: transformedEmail, password })
      const unconfirmed = !isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_UP'

      const confirmSignInWithNewPasswordRequired =
        !isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'

      if (confirmSignInWithNewPasswordRequired) {
        setConfirmAccountEmail(transformedEmail)
        setForm('confirmSignInWithNewPasswordRequired')
        return
      }

      if (isSignedIn) {
        setIsLoggedIn(true)
        setEmail(transformedEmail)
      }

      if (unconfirmed && throwWhenAccountUnconfirmed) throw Error('User is not confirmed')

      await loginAction?.({ username: transformedEmail, password, unconfirmed })
    } catch (error) {
      setGeneralError(true)
      console.error('Error signing in:', error)
      if (error instanceof AuthError && error.message in authMessages) {
        setKnownError(error.message)
        setUnexpectedError(undefined)
        return
      }

      if (error instanceof Error && error.message) {
        setUnexpectedError(error.message)
        setKnownError(undefined)
        return
      }
    } finally {
      setSubmitting(false)
    }
  }

  const onConfirmSignInWithNewPasswordRequiredSubmit = async (
    { newPassword, email }: typeof initialConfirmSignInWithNewPasswordRequiredValues,
    { setSubmitting }: FormikHelpers<typeof initialConfirmSignInWithNewPasswordRequiredValues>,
  ) => {
    const transformedEmail = email.trim().toLowerCase()

    try {
      setSubmitting(true)

      if (isLoggedIn) {
        await logOut()
        setIsLoggedIn(false)
        setEmail(undefined)
      }

      const { isSignedIn, nextStep } = await confirmSignIn({ challengeResponse: newPassword })

      const unconfirmed = !isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_UP'

      if (isSignedIn) {
        setIsLoggedIn(true)
        setEmail(transformedEmail)
      }

      if (unconfirmed && throwWhenAccountUnconfirmed) throw Error('User is not confirmed')

      await loginAction?.({ username: transformedEmail, password: newPassword, unconfirmed })
    } catch (error) {
      setGeneralError(true)
      console.error('Error signing in:', error)
      if (error instanceof AuthError && error.message in authMessages) {
        setKnownError(error.message)
        setUnexpectedError(undefined)
        return
      }

      if (error instanceof Error && error.message) {
        setUnexpectedError(error.message)
        setKnownError(undefined)
        return
      }
    } finally {
      setSubmitting(false)
    }
  }

  const onForgotPasswordSubmit = async (
    { email: emailAsUsername }: typeof initialForgotPasswordValues,
    { setSubmitting }: FormikHelpers<typeof initialForgotPasswordValues>,
  ) => {
    const transformedEmailAsUsername = emailAsUsername.trim().toLowerCase()

    try {
      await signOut()
      setIsLoggedIn(false)
      setEmail('')

      await resetPassword({ username: transformedEmailAsUsername })
      setGeneralError(false)
      setForm('forgotPasswordProceed')
    } catch (error) {
      setGeneralError(true)
      console.error('error signing up:', error)

      if (error instanceof AuthError && error.message in authMessages) {
        setKnownError(error.message)
        setUnexpectedError(undefined)
        return
      }

      if (error instanceof Error && error.message) {
        setUnexpectedError(error.message)
        setKnownError(undefined)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const onForgotPasswordProceedSubmit = async (
    { code, email, newPassword }: typeof initialForgotPasswordProceedValues,
    { setSubmitting }: FormikHelpers<typeof initialForgotPasswordProceedValues>,
  ) => {
    const transformedEmail = email.trim().toLowerCase()

    try {
      await confirmResetPassword({
        username: transformedEmail,
        confirmationCode: code,
        newPassword,
      })

      const { isSignedIn, nextStep } = await signIn({
        username: transformedEmail,
        password: newPassword,
      })

      const unconfirmed = !isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_UP'

      if (isSignedIn) {
        setIsLoggedIn(true)
        setEmail(transformedEmail)
      }

      if (unconfirmed && throwWhenAccountUnconfirmed) throw Error('User not confirmed')

      await loginAction?.({ username: transformedEmail, password: newPassword, unconfirmed })
    } catch (error) {
      setGeneralError(true)
      console.error('Error signing in:', error)

      if (error instanceof AuthError && error.message in authMessages) {
        setKnownError(error.message)
        setUnexpectedError(undefined)
        return
      }

      if (error instanceof Error && error.message) {
        setUnexpectedError(error.message)
        setKnownError(undefined)
        return
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Root className={className}>
      {children?.(form)}
      {form === 'login' && (
        <Formik
          initialValues={{ ...initialLoginValues, email: initialEmail ?? '' }}
          onSubmit={onLoginSubmit}
          validateOnChange={false}
          validationSchema={y.object({
            email: y.string().email().trim().required(t('forms.validation.emailRequired')),
            password: y.string().required(t('forms.validation.passwordRequired')),
          })}
        >
          {({ isSubmitting }) => (
            <StyledForm>
              <StyledTextInput
                autoComplete="current-email"
                description={t('forms.fields.email')}
                name="email"
                placeholder={t('forms.placeholders.email')}
                type="text"
              />
              <LastTextInput
                autoComplete="current-password"
                canPreviewPassword
                description={t('forms.fields.password')}
                name="password"
                type="password"
              />
              <AdditionalAction
                onClick={() => {
                  setGeneralError(false)
                  setForm('forgotPassword')
                }}
              >
                {t('forms.actions.forgotPassword')}
              </AdditionalAction>
              <GeneralError $visible={generalError} onClick={closeGeneralError}>
                {knownError ? t('auth.' + knownError) : null}
                {unexpectedError ? unexpectedError : null}
              </GeneralError>
              <StyledPrimaryButton
                disabled={isSubmitting}
                iconRight={Icons.ArrowRight}
                type="submit"
              >
                {t('forms.actions.logIn')}
              </StyledPrimaryButton>
            </StyledForm>
          )}
        </Formik>
      )}
      {form === 'confirmSignInWithNewPasswordRequired' && (
        <Formik
          initialValues={{
            ...initialConfirmSignInWithNewPasswordRequiredValues,
            email: confirmAccountEmail,
          }}
          onSubmit={onConfirmSignInWithNewPasswordRequiredSubmit}
          validationSchema={y.object({
            email: y.string().trim().email().required(t('forms.validation.emailRequired')),
            newPassword: y
              .string()
              .min(
                MIN_PASSWORD_CHARACTERS,
                t('forms.validation.minCharacters', { count: MIN_PASSWORD_CHARACTERS }),
              )
              .required(t('forms.validation.newPasswordRequired')),
          })}
        >
          {({ isSubmitting }) => (
            <WithTranslateFormErrors>
              <StyledForm>
                <StyledTextInput
                  autoComplete="current-email"
                  description={t('forms.fields.email')}
                  disabled
                  name="email"
                  placeholder={t('forms.placeholders.email')}
                  type="text"
                />
                <StyledTextInput
                  autoComplete="new-password"
                  canPreviewPassword
                  description={t('forms.fields.newPassword')}
                  name="newPassword"
                  placeholder={t('forms.placeholders.newPassword')}
                  type="password"
                />
                <GeneralError $visible={generalError} onClick={closeGeneralError}>
                  {knownError ? t('auth.' + knownError) : null}
                  {unexpectedError ? unexpectedError : null}
                </GeneralError>
                <BigMarginBottomPrimaryButton
                  disabled={isSubmitting}
                  iconRight={Icons.ArrowRight}
                  type="submit"
                >
                  {t('forms.actions.logIn')}
                </BigMarginBottomPrimaryButton>
                <BackToLoginWrapper
                  onClick={() => {
                    setGeneralError(false)
                    setForm('login')
                  }}
                >
                  <Suspense>
                    <IconComponents.arrowLeft
                      fill={colours.blues[100]}
                      stroke={colours.blues[100]}
                    />
                    {t('backToLogin')}
                  </Suspense>
                </BackToLoginWrapper>
              </StyledForm>
            </WithTranslateFormErrors>
          )}
        </Formik>
      )}
      {form === 'forgotPassword' && (
        <Formik
          initialValues={{ ...initialForgotPasswordValues, email: initialEmail ?? '' }}
          onSubmit={onForgotPasswordSubmit}
          validationSchema={y.object({
            email: y
              .string()
              .trim()
              .email(t('forms.validation.validEmail'))
              .required(t('forms.validation.emailRequired')),
          })}
        >
          {({ isSubmitting }) => (
            <WithTranslateFormErrors>
              <StyledForm>
                <ForgotPasswordEmailTextInput
                  autoComplete="current-email"
                  description={t('forms.fields.email')}
                  name="email"
                  placeholder={t('forms.placeholders.email')}
                  type="text"
                />
                <GeneralError $visible={generalError} onClick={closeGeneralError}>
                  {knownError ? t('auth.' + knownError) : null}
                  {unexpectedError ? unexpectedError : null}
                </GeneralError>
                <BigMarginBottomPrimaryButton
                  disabled={isSubmitting}
                  iconRight={Icons.Envelope}
                  type="submit"
                >
                  {t('forms.actions.remindPassword')}
                </BigMarginBottomPrimaryButton>
                <BackToLoginWrapper
                  onClick={() => {
                    setGeneralError(false)
                    setForm('login')
                  }}
                >
                  <Suspense>
                    <IconComponents.arrowLeft
                      fill={colours.blues[100]}
                      stroke={colours.blues[100]}
                    />
                    {t('backToLogin')}
                  </Suspense>
                </BackToLoginWrapper>
              </StyledForm>
            </WithTranslateFormErrors>
          )}
        </Formik>
      )}

      {form === 'forgotPasswordProceed' && (
        <Formik
          initialValues={{ ...initialForgotPasswordProceedValues, email: initialEmail ?? '' }}
          onSubmit={onForgotPasswordProceedSubmit}
          validationSchema={y.object({
            email: y.string().trim().email().required(t('forms.validation.emailRequired')),
            code: y.string().required(t('forms.validation.required')),
            newPassword: y
              .string()
              .min(
                MIN_PASSWORD_CHARACTERS,
                t('forms.validation.minCharacters', { count: MIN_PASSWORD_CHARACTERS }),
              )
              .required(t('forms.validation.newPasswordRequired')),
          })}
        >
          {({ isSubmitting }) => (
            <WithTranslateFormErrors>
              <StyledForm>
                <StyledTextInput
                  autoComplete="current-email"
                  description={t('forms.fields.email')}
                  name="email"
                  placeholder={t('forms.placeholders.email')}
                  type="text"
                />
                <StyledTextInput
                  autoComplete="code-from-remind"
                  description={t('forms.fields.code')}
                  name="code"
                  placeholder={t('forms.placeholders.code')}
                  type="text"
                />
                <StyledTextInput
                  autoComplete="new-password"
                  canPreviewPassword
                  description={t('forms.fields.newPassword')}
                  name="newPassword"
                  placeholder={t('forms.placeholders.newPassword')}
                  type="password"
                />
                <GeneralError $visible={generalError} onClick={closeGeneralError}>
                  {knownError ? t('auth.' + knownError) : null}
                  {unexpectedError ? unexpectedError : null}
                </GeneralError>
                <BigMarginBottomPrimaryButton
                  disabled={isSubmitting}
                  iconRight={Icons.ArrowRight}
                  type="submit"
                >
                  {t('forms.actions.logIn')}
                </BigMarginBottomPrimaryButton>
                <BackToLoginWrapper
                  onClick={() => {
                    setGeneralError(false)
                    setForm('login')
                  }}
                >
                  <Suspense>
                    <IconComponents.arrowLeft
                      fill={colours.blues[100]}
                      stroke={colours.blues[100]}
                    />{' '}
                    {t('backToLogin')}
                  </Suspense>
                </BackToLoginWrapper>
              </StyledForm>
            </WithTranslateFormErrors>
          )}
        </Formik>
      )}
      {footer?.(form)}
    </Root>
  )
}

const Root = styled.div``

const BackToLoginWrapper = styled(Fonts.BodyLarge)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colours.blues[100]};
  cursor: pointer;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: 24px;
`

const LastTextInput = styled(TextInput)`
  margin-bottom: 12px;
`

const ForgotPasswordEmailTextInput = styled(TextInput)`
  margin-bottom: 72px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 64px;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 0;
`

const BigMarginBottomPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 112px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 80px;
  }
`

const AdditionalAction = styled(Fonts.BodyLarge)`
  align-items: center;
  color: ${colours.blues[100]};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  width: fit-content;
  align-self: flex-end;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 72px;
  }
`
