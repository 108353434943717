import React, { PropsWithChildren, useEffect, useRef } from 'react'
import styled from 'styled-components'

type ModalProps = {
  open?: boolean
  onClose?: () => void
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ children, open, onClose }) => {
  const modalRef = useRef<HTMLDialogElement>(null)

  useEffect(() => (open ? modalRef.current?.showModal() : modalRef.current?.close()), [open])

  return (
    <Dialog
      onCancel={(e) => {
        e.preventDefault()
        onClose?.()
      }}
      onClick={({ target }) => {
        if (target === modalRef.current) onClose?.()
      }}
      onClose={() => {
        console.log('Generic modal onClose')
      }}
      ref={modalRef}
    >
      <ContentContainer>{children}</ContentContainer>
    </Dialog>
  )
}

const ContentContainer = styled.div`
  width: 100%;
`

const Dialog = styled.dialog`
  border-radius: 8px;
  border: 0;
  padding: 0;
  margin: auto;
  box-shadow: 0px 0px 16px 0px rgba(100, 100, 100, 1);
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
