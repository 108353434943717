import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Languages } from '@constants/language'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { getSummaryValues } from '@utils/getSummaryValues'

type Props = {
  priceFromCards: number | undefined
  amountFromChoiceCards: number | undefined
  paymentProcessingFee: PaymentProcessingFee | undefined
  currency: string | undefined
  pastTense?: boolean
  calculatedToPay?: number
}

export const ToPay: React.FC<Props> = ({
  priceFromCards,
  amountFromChoiceCards,
  paymentProcessingFee,
  currency,
  pastTense,
  calculatedToPay,
}) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language as Languages

  const toPay =
    calculatedToPay ??
    getSummaryValues({
      priceFromCards,
      amountFromChoiceCards,
      paymentProcessingFee,
      currency,
    }).toPay

  return (
    <Root>
      <Entry>
        <Title>{pastTense ? t('paid') : t('toPay')}</Title>
        <Value>{getLocalisedPrice(language, toPay, currency)}</Value>
      </Entry>
    </Root>
  )
}

const Root = styled.div`
  padding: 8px;
`

const Entry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled(Fonts.Label)`
  text-transform: uppercase;
`
const Value = styled(Fonts.ButtonTextLink)`
  color: ${colours.plant[100]};
`
