import i18n from '@i18n'

export const insertSpaces = (inputString?: string | null, n?: number): string => {
  if (!inputString || !n) return ''
  let formattedString = ''
  for (let i = 0; i < inputString.length; i++) {
    formattedString += inputString[i]
    if ((i + 1) % n === 0 && i !== inputString.length - 1) {
      formattedString += ' '
    }
  }
  return formattedString
}

export const formattedDate = (expiry_date: string | null) => {
  if (!expiry_date) return ''
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }
  const dateObject = new Date(expiry_date)
  return new Intl.DateTimeFormat(i18n.language, options).format(dateObject)
}
