import { css } from 'styled-components'

export const IntroSubtextSmallCSS = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-decoration: none;
  text-transform: none;
`
