import { envs } from './envs'

export const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: envs.VITE_AMPLIFY_AUTH_USER_POOL_ID,
      userPoolClientId: envs.VITE_AMPLIFY_AUTH_USER_POOL_WEBCLIENT_ID,
    },
  },
}
