import { Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

import { Icons } from '@typeDeclarations/components/atoms/icons'
import { DivProps } from '@typeDeclarations/elements/div'

type Props = DivProps & {
  mainInfo?: React.ReactNode
  sideInfo?: React.ReactNode
  actionIcon?: Icons
  action?: () => void
}

export const ChoiceCardBar: React.FCS<Props> = ({
  mainInfo,
  sideInfo,
  action,
  className,
  style,
  actionIcon,
  ref: _ref,
  ...props
}) => {
  const I = actionIcon ? IconComponents[actionIcon] : IconComponents.plus

  return (
    <Root className={className} style={style} {...props}>
      <LeftGroup>
        <Suspense>
          <IconComponents.cardStack fill={colours.blues[110]} stroke={colours.blues[110]} />
        </Suspense>
        <CounterText>{mainInfo}</CounterText>
      </LeftGroup>
      <Cursor $canClick={!!action} onClick={() => action?.()}>
        {sideInfo}
        {action && (
          <Circle>
            <Suspense>
              <I fill={colours.blues[110]} stroke={colours.blues[110]} />
            </Suspense>
          </Circle>
        )}
      </Cursor>
    </Root>
  )
}

const CounterText = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.brand.blue};
`

const Root = styled(Fonts.BodyLarge)`
  height: 48px;
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  background-color: ${colours.blues[10]};
`

const LeftGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Cursor = styled.div<{ $canClick?: boolean }>`
  cursor: ${(p) => (p.$canClick ? 'pointer' : 'unset')};
  color: ${colours.brand.blue};
  line-height: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Circle = styled.div`
  background-color: ${colours.blues[30]};
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
