import { ReceiveConfig } from '@services/api.types'

export const parseConfigValues = (configValues: ReceiveConfig | undefined) => {
  if (!configValues)
    return {
      maxPurchaseQuantity: null,
      maxPurchaseAmount: null,
    }

  const maxPurchaseQuantityRaw = configValues.max_purchase_qty ?? undefined
  const maxPurchaseAmountRaw = configValues.max_purchase_value ?? undefined
  const maxPurchaseQuantity = Number.isNaN(Number(maxPurchaseQuantityRaw))
    ? null
    : Number(maxPurchaseQuantityRaw)
  const maxPurchaseAmount = Number.isNaN(Number(maxPurchaseAmountRaw))
    ? null
    : Number(maxPurchaseAmountRaw)

  return { maxPurchaseAmount, maxPurchaseQuantity }
}
