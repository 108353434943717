import { useTranslation } from 'react-i18next'

import { Banner } from '@components/atoms/tooltips/Banners/Banner'

type Props = {
  setError: undefined | ((arg: boolean) => void)
  setPaymentMethodBannerClosed: undefined | ((arg: boolean) => void)
}

export const NoPaymentMethodBanner: React.FC<Props> = ({
  setError,
  setPaymentMethodBannerClosed,
}) => {
  const { t } = useTranslation()

  return (
    <Banner
      onClose={() => {
        setError?.(false)
        setPaymentMethodBannerClosed?.(true)
      }}
      title={t('bannerNoPaymentMethod')}
      type="error"
    >
      {t('bannerNoPaymentMethodDescription')}
    </Banner>
  )
}
