import { NavLink, generatePath } from 'react-router-dom'

import styled from 'styled-components'

import { Badge } from '@components/atoms/Badge/Badge'
import { HighlightableIcon } from '@components/atoms/SelectedIcon/SelectedIcon'
import { colours } from '@configs/colours'
import { cartSteps, claimCodePaths } from '@configs/urls'
import { zIndexes } from '@constants/zIndexes'
import { useCartContext } from '@hooks/useCart'
import { useMainKeyContext } from '@hooks/useMainKey'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'

export const BottomNav: React.FC = () => {
  const { mainKey } = useMainKeyContext()
  const { aggregatedCart } = useCartContext()

  const cartCount = mainKey
    ? aggregatedCart.carts[mainKey]?.cards.reduce((acc, { quantity }) => acc + +quantity, 0)
    : 0

  if (!mainKey) return null

  return (
    <BottomBar>
      <NavLink to={generatePath(claimCodePaths.catalogue, { claimCode: mainKey })}>
        {({ isActive }) => <HighlightableIcon icon={Icons.Shop} isHighlighted={isActive} />}
      </NavLink>
      <HighlightableIcon icon={Icons.HeadphonesFace} isDisabled />
      <NavLink to={generatePath(cartSteps.main, { claimCode: mainKey })}>
        {({ isActive }) => (
          <Badge count={cartCount}>
            <HighlightableIcon icon={Icons.Cart} isHighlighted={isActive} />
          </Badge>
        )}
      </NavLink>
    </BottomBar>
  )
}

const BottomBar = styled.div`
  align-items: center;
  background-color: ${colours.prisma.white};
  bottom: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 72px;
  justify-content: space-around;
  position: sticky;
  width: 100%;
  z-index: ${zIndexes.baseOnTop};
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`
