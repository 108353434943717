import { useEffect } from 'react'

type Props = {
  color?: string | null
}

export const ThemeBar: React.FC<Props> = ({ color }) => {
  useEffect(() => {
    // This works in very limited amount of cases
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color

    document.querySelectorAll("meta[name='theme-color']").forEach((node) => {
      try {
        node.remove()
      } catch {
        console.warn('[Theme bar] Cannot remove theme-color node')
      }
    })

    const meta = document.createElement('meta')
    meta.setAttribute('name', 'theme-color')
    meta.setAttribute('content', `${color}`)

    document.getElementsByTagName('head')[0].prepend(meta)
  }, [color])

  return null
}
