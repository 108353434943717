import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AccountFormsWidthBoundary } from '@components/atoms/Content/AccountFormsWidthBoundary'
import { Footer } from '@components/atoms/Footer/Footer'
import { Fonts } from '@components/atoms/typography/Fonts'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { GeneralError } from '@components/molecules/forms/GeneralError/GeneralError'
import { SignUpForm, SubmitAction } from '@components/organisms/forms/SignUpForm/SignUpForm'
import { colours } from '@configs/colours'
import { shareKeyPaths } from '@configs/urls'
import { datadogMessages } from '@constants/datadog'
import { datadogLogs } from '@datadog/browser-logs'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useUserContext } from '@hooks/useUserContext'
import { Flows } from '@services/api.types'
import { receiveClaim } from '@utils/claimHelpers'

const SignUp: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { shareKey = '' } = useParams()
  const { hashFlows, setEmailDelayInfo } = useHashFlowContext()
  const location = useLocation()
  const [generalError, setGeneralError] = useState(false)
  const { setEmail, setIsLoggedIn } = useUserContext()
  const initialEmail = new URLSearchParams(location.search).get('email')

  const flow = hashFlows[shareKey]?.flow
  const skip_claim_step = hashFlows[shareKey]?.skip_claim_step

  const signUpAction: SubmitAction = async ({ email, userSub, password }) => {
    if (!userSub) {
      datadogLogs.logger.warn(datadogMessages.userIdNotProvided, { env: import.meta.env.PROD })
    }

    try {
      await receiveClaim({
        username: userSub ?? null,
        email,
        shareKey,
        unconfirmed_user: false,
        password,
        navigate,
        setEmailDelayInfo,
        setEmail,
        setIsLoggedIn,
      })
    } catch (e) {
      console.error('Cannot receive claim: ', e)
    }
  }

  const claimWithoutNewAccount = async () => {
    try {
      if (!initialEmail && skip_claim_step) throw Error('Email not provided')
      const transformedEmail = initialEmail ? initialEmail.trim().toLowerCase() : null
      await receiveClaim({
        username: null,
        email: transformedEmail,
        shareKey,
        unconfirmed_user: false,
        navigate,
        setEmailDelayInfo,
        setEmail,
        setIsLoggedIn,
      })
    } catch (e) {
      setGeneralError(true)
      console.error('Cannot receive claim: ', e)
    }
  }

  return (
    <Root>
      <AccountFormsWidthBoundary>
        <Arrangement>
          <GeneralError
            $visible={generalError}
            onClick={() => {
              setGeneralError(false)
            }}
          />
          <SignUpForm
            footer={() => {
              return (
                <Blue>
                  {t('alreadyAMember')}
                  <Underlined to={generatePath(shareKeyPaths.account, { shareKey })}>
                    {t('forms.actions.logIn')}
                  </Underlined>
                </Blue>
              )
            }}
            initialEmail={initialEmail}
            submitAction={signUpAction}
          >
            {() => (
              <TopInfo>
                <Greeting>{t('signUp')}</Greeting>
                {flow === Flows.ShopAccountMandatory && (
                  <Subtitle>{t('accountRequiredToClaimSignUp')}</Subtitle>
                )}
              </TopInfo>
            )}
          </SignUpForm>
          {hashFlows[shareKey]?.flow === Flows.ShopAccountOptional && (
            <Formik initialValues={{}} onSubmit={claimWithoutNewAccount}>
              <WithTranslateFormErrors>
                <Form>
                  <StyledSecondaryButton type="submit">{t('maybeLater')}</StyledSecondaryButton>
                </Form>
              </WithTranslateFormErrors>
            </Formik>
          )}
        </Arrangement>
      </AccountFormsWidthBoundary>
      <Footer />
    </Root>
  )
}

export default SignUp

const TopInfo = styled.div`
  margin-bottom: 64px;
`

const Greeting = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 24px;
  text-align: center;
`

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 16px;
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(180deg, #fff 1%, rgb(234, 238, 239) 100%);
`

const Arrangement = styled.div`
  margin-top: 32px;
`

const Subtitle = styled(Fonts.BodyLarge)`
  margin-bottom: 40px;
  color: ${colours.mist[800]};
  text-align: center;
`

const Blue = styled(Fonts.BodyLarge)`
  color: ${colours.brand.blue};
  display: flex;
  gap: 8px;
  justify-content: center;
  text-align: center;
`

const Underlined = styled(Link)`
  text-decoration: underline;
`
