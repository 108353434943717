import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import * as y from 'yup'

import { AboutFooter } from '@components/atoms/About/About'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { BasicErrorComponent } from '@components/atoms/Error/Error'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import { TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { colours, gradients } from '@configs/colours'
import { shareKeyPaths } from '@configs/urls'
import { shopResponseErrors } from '@constants/shopResponseErrors'
import { HttpError } from '@errors/httpError'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { api } from '@services/api'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'

import eonLogo from '/images/logo-eon.png'
import eonBg from '/images/bg-eon.png'

import ADASDE from '/images/brands/eon/partners/ADAS-DE.png'
import AMZDE from '/images/brands/eon/partners/AMZ-DE.png'
import BCHERDE from '/images/brands/eon/partners/BCHER-DE.png'
import DOUGDE from '/images/brands/eon/partners/DOUG-DE.png'
import ERNSTDE from '/images/brands/eon/partners/ERNST-DE.png'
import FOOTDE from '/images/brands/eon/partners/FOOT-DE.png'
import HFRESHDE from '/images/brands/eon/partners/HFRESH-DE.png'
import HMDE from '/images/brands/eon/partners/HM-DE.png'
import IKEA_DE from '/images/brands/eon/partners/IKEA_DE.png'
import JACQUEDE from '/images/brands/eon/partners/JACQUE-DE.png'
import JSHWZDE from '/images/brands/eon/partners/JSHWZ-DE.png'
import MMKTDE from '/images/brands/eon/partners/MMKT-DE.png'
import OTTODE from '/images/brands/eon/partners/OTTO-DE.png'
import RITUALDE2 from '/images/brands/eon/partners/RITUAL-DE-2.png'
import TCHIBODE from '/images/brands/eon/partners/TCHIBO-DE.png'
import THALIADE from '/images/brands/eon/partners/THALIA-DE.png'
import TKMDE from '/images/brands/eon/partners/TKM-DE.png'
import ZALDE from '/images/brands/eon/partners/ZAL-DE.png'

import { newAndOldCardNumber } from '@utils/regexes'

const getValidationSchema = (t: TFunction) =>
  y.object({
    cardNumber: y
      .string()
      .matches(newAndOldCardNumber, t('forms.validation.cardNumber'))
      .required(t('forms.validation.required')),
    securityCode: y
      .number()
      .typeError(t('forms.validation.number'))
      .integer(t('forms.validation.integer'))
      .required(t('forms.validation.required')),
  })

const Page: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [errorDescription, setErrorDescription] = useState<string>()
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [partnersExpanded, setPartnersExpanded] = useState(false)

  const initialValues = { cardNumber: '', securityCode: '' }

  const onSubmit = async ({ cardNumber, securityCode }: typeof initialValues) => {
    try {
      setErrorOccurred(false)
      const { share_key } = await api.shareKeyByCodes(cardNumber, securityCode)

      const cataloguePath = generatePath(shareKeyPaths.confetti, { shareKey: share_key })
      navigate(cataloguePath)
    } catch (e) {
      const module = '[Code obtainer]'
      const message = 'Something went wrong obtaining share key from card number and pin'
      setErrorOccurred(true)
      console.error(`${module}: ${message}`, e)

      if (e instanceof HttpError && e.json !== null && typeof e.json === 'object') {
        if (Number(e.status) === 503) {
          setErrorDescription('backend.temporarily_unavailable')
          return
        }
        const nonFieldError =
          'non_field_errors' in e.json && Array.isArray(e.json.non_field_errors)
            ? (e.json.non_field_errors[0] as string)
            : undefined
        if (nonFieldError && nonFieldError in shopResponseErrors)
          setErrorDescription('backend.' + nonFieldError)
      }
    }
  }

  return (
    <Root>
      <Content>
        <BarWrapper>
          <WidthBoundary>
            <Grid>
              <Info>
                <img src={eonLogo} width={200} />
                <ImgEonBg src={eonBg} width={400} />
              </Info>
              <FormFrame>
                <InputDescription>{t('eon.getCardTitle')}</InputDescription>
                <RegularGray>{t('eon.getCardDescription')}</RegularGray>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={getValidationSchema(t)}
                >
                  {({ isSubmitting }) => {
                    return (
                      <WithTranslateFormErrors>
                        <StyledForm>
                          <CardNumberInput
                            autoComplete="cardNumber"
                            description={t('forms.fields.activationCode')}
                            name="cardNumber"
                            placeholder={t('forms.placeholders.activationCode')}
                          />
                          <SecurityCodeInput
                            autoComplete="securityCode"
                            description={t('forms.fields.pin')}
                            name="securityCode"
                            placeholder={t('forms.placeholders.pin')}
                          />
                          {!isSubmitting ? (
                            <PrimaryButton iconRight={Icons.Gift} type="submit">
                              {t('forms.actions.openYourGift')}
                            </PrimaryButton>
                          ) : (
                            <LoadingButton />
                          )}
                          <ErrorWrapper>
                            {errorOccurred && (
                              <BasicErrorComponent onClose={() => setErrorOccurred(false)}>
                                {errorDescription ? t(errorDescription) : t('somethingWentWrong')}
                              </BasicErrorComponent>
                            )}
                          </ErrorWrapper>
                        </StyledForm>
                      </WithTranslateFormErrors>
                    )
                  }}
                </Formik>
              </FormFrame>
            </Grid>
          </WidthBoundary>
        </BarWrapper>
        <WidthBoundary>
          <ContentWrapper>
            <Title>{t('eon.receivedChoicaCardQuestion')}</Title>
            <Fonts.IntroSubtextLarge>{t('eon.encouragement')}</Fonts.IntroSubtextLarge>
            <Ol>
              <li>{t('eon.stepsOne')}</li>
              <li>{t('eon.StepTwo')}</li>
              <li>{t('eon.stepThree')}</li>
              <li>{t('eon.stepFour')}</li>
            </Ol>
            <Title>{t('eon.redeemAtOurPartners')}</Title>
          </ContentWrapper>
        </WidthBoundary>
        <WidthBoundary>
          <Partners>
            <Img src={ADASDE} />
            <Img src={AMZDE} />
            <Img src={BCHERDE} />
            <Img src={DOUGDE} />
            <Img src={ERNSTDE} />
            {partnersExpanded && (
              <>
                <Img src={FOOTDE} />
                <Img src={HFRESHDE} />
                <Img src={HMDE} />
                <Img src={IKEA_DE} />
                <Img src={JACQUEDE} />
                <Img src={JSHWZDE} />
                <Img src={MMKTDE} />
                <Img src={OTTODE} />
                <Img src={RITUALDE2} />
                <Img src={TCHIBODE} />
                <Img src={THALIADE} />
                <Img src={TKMDE} />
                <Img src={ZALDE} />
              </>
            )}
          </Partners>
          <MoreLink onClick={() => setPartnersExpanded((p) => !p)}>
            {partnersExpanded ? t('less') : t('more')}
          </MoreLink>
        </WidthBoundary>
        <WidthBoundary>
          <ContentWrapper>
            <Title>{t('eon.doYouHaveQuestion')}</Title>
            <Fonts.BodyLarge>{t('eon.doYouHaveQuestionExplanation')}</Fonts.BodyLarge>
          </ContentWrapper>
        </WidthBoundary>
      </Content>
      <AboutFooter $noBackground />
    </Root>
  )
}

const MoreLink = styled.div`
  margin: auto;
  text-align: center;
  padding: 24px;
  ${BodyLargeCSS};
  color: ${colours.brand.green};
  font-weight: bold;
  cursor: pointer;
`

const Title = styled.div`
  margin-bottom: 16px;
  ${TitleHeaderH1SCSS}
`

const Img = styled.img`
  border-radius: 8px;
  ${mediaQueries.to.breakpoint.tablet} {
    width: 140px;
    height: 87px;
  }
  ${mediaQueries.from.breakpoint.tablet} {
    width: 219px;
    height: 137px;
  }
`

const Partners = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

const Ol = styled.ol`
  ${BodyLargeCSS};
  padding-left: 0;
  list-style-position: inside;
  margin-bottom: 32px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
  width: 800px;
  ${mediaQueries.to.breakpoint.desktop} {
    width: 100%;
  }
`

const BarWrapper = styled.div`
  background-color: #ea1c0a;
  padding: 32px 0;
  width: 100%;
  margin-bottom: 32px;
`

const InputDescription = styled(Fonts.TitleHeaderH2)`
  margin: 0 0 8px 0;
  text-align: left;
`

const RegularGray = styled(Fonts.BodyRegular)`
  color: ${colours.mist[900]};
  margin-bottom: 32px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  gap: 64px;
  justify-items: center;
  align-items: start;
  margin-bottom: 64px;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const CardNumberInput = styled(TextInput)`
  margin-bottom: 24px;
`

const SecurityCodeInput = styled(TextInput)`
  margin-bottom: 32px;
  width: 50%;
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 10;
  width: 100%;
  ${gradients.defaultBackground}
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 10;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-top: 0;
  width: 100%;
`

const StyledForm = styled(Form)`
  width: 100%;
`

const ErrorWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
`

const FormFrame = styled.div`
  width: min(440px, 96vw);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 28px 24px 24px;
  gap: 32px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 24px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px);
`

export default Page

const ImgEonBg = styled.img`
  width: 400px;
  ${mediaQueries.to.breakpoint.mobileL} {
    width: 200px;
  }
`
