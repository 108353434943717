import { CSSProperties, Suspense } from 'react'

import { useTranslation } from 'react-i18next'

import { generatePath, useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { OrderList } from '@components/atoms/OrderList/OrderList'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { cartSteps } from '@configs/urls'
import { useCheckoutContext } from '@hooks/useCheckout'
import { useMainKeyContext } from '@hooks/useMainKey'

export const SimpleSummarySection: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  const navigate = useNavigate()
  const { mainKey } = useMainKeyContext()
  const { t } = useTranslation()
  const { items } = useCheckoutContext()

  return (
    <Root style={style}>
      <ReviewOrderTitle>
        <SubHeader>{t('reviewYourOrder')}</SubHeader>
        <ChangeOrderAction
          onClick={() => {
            navigate(generatePath(cartSteps.main, { claimCode: mainKey }))
          }}
        >
          <BlueText>{t('changeOrder')}</BlueText>
          <Suspense>
            <IconComponents.pen
              fill={colours.brand.blue}
              height="16px"
              stroke={colours.brand.blue}
            />
          </Suspense>
        </ChangeOrderAction>
      </ReviewOrderTitle>
      <OrderList items={items} />
    </Root>
  )
}

const SubHeader = styled(Fonts.TitleHeaderH2S)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ReviewOrderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BlueText = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.brand.blue};
  width: min-content;
  white-space: nowrap;
`

const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`

const ChangeOrderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`
