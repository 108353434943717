import { css } from 'styled-components'

export const BodyLargeCSS = css`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
`
