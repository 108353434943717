import { Suspense, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Tooltip } from '@components/atoms/tooltips/Tooltips/Tooltip'
import { TagTextCSS } from '@components/atoms/typography/css/TagTextCSS'
import { colours } from '@configs/colours'
import { zIndexes } from '@constants/zIndexes'
import { useOutsideClick } from '@hooks/useOutsideClick'
import { concatenate } from '@utils/concatenate'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'

import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  feeFlat?: number | null
  feePercentage?: number | null
  isFeeDeducted?: boolean | null
  isFeePaid?: boolean | null
  currency?: string
}

export const FeeInfoStrip: React.FCS<Props> = ({
  className,
  feeFlat,
  feePercentage,
  isFeeDeducted,
  isFeePaid,
  currency,
}) => {
  const [hold, setHold] = useState(false)
  const { i18n, t } = useTranslation()

  const rootRef = useRef<HTMLDivElement>(null)

  useOutsideClick(rootRef, () => setHold(false))

  const hasFee =
    (typeof feePercentage === 'number' && feePercentage > 0) ||
    (typeof feeFlat === 'number' && feeFlat > 0)

  const percentageFee =
    typeof feePercentage === 'number' && feePercentage !== 0 && `${feePercentage}%`
  const flatFee =
    typeof feeFlat === 'number' &&
    feeFlat !== 0 &&
    getLocalisedPrice(i18n.language, feeFlat, currency)
  const concatenatedFees = concatenate([percentageFee, flatFee], { separator: ' + ' })
  const feesCount = +!!percentageFee + +!!flatFee

  if (!hasFee) return null

  return (
    <Root className={className} ref={rootRef}>
      <Text $hold={hold} onClick={() => setHold((v) => !v)}>
        <Suspense>
          <IconComponents.circledInfo stroke={colours.brand.yellow} />
        </Suspense>
        {concatenatedFees}
        {' - '}
        {t('fee')}
        {isFeeDeducted && (
          <StyledTooltip
            tip="top"
            title={t('fee.tooltip.deducted.title', { count: feesCount, fees: concatenatedFees })}
            type="dark"
          >
            {t('fee.deducted.explanation', { count: feesCount, fees: concatenatedFees })}
          </StyledTooltip>
        )}
        {isFeePaid && (
          <StyledTooltip
            tip="top"
            title={t('fee.tooltip.paid.title', { count: feesCount, fees: concatenatedFees })}
            type="dark"
          >
            {t('fee.paid.explanation', { count: feesCount, fees: concatenatedFees })}
          </StyledTooltip>
        )}
      </Text>
    </Root>
  )
}

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 32px;
  left: -64px;
  width: 200%;
  margin-left: 16px;

  ${mediaQueries.to.breakpoint.mobileL} {
    right: 0;
    width: 96vw;
  }
`

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: rgba(20, 26, 27, 0.5);
`

const Text = styled.div<{ $hold?: boolean }>`
  ${TagTextCSS};
  margin: 4px 12px;
  width: 100%;
  color: ${colours.brand.yellow};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
  z-index: ${zIndexes.baseOnTop};

  ${mediaQueries.to.breakpoint.desktop} {
    margin: 2px;
  }

  ${mediaQueries.from.breakpoint.desktop} {
    & aside {
      white-space: normal;
      display: none;
    }
    &:hover aside {
      white-space: normal;
      display: flex;
    }
  }

  ${mediaQueries.to.breakpoint.desktop} {
    ${(p) =>
      !p.$hold &&
      css`
        & aside {
          display: none;
        }
      `}
  }
`
