import { Form, Formik } from 'formik'
import Fraction from 'fraction.js'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { datadogLogs } from '@datadog/browser-logs'

import { CartNotInitialized } from '@components/molecules/CartNotInitialized/CartNotInitialized'
import { Cart as CartOrganism } from '@components/organisms/Checkout/Cart'
import { Payment as PaymentOrganism } from '@components/organisms/Checkout/Payment'
import { Review as ReviewOrganism } from '@components/organisms/Checkout/Review'
import { claimCodePaths, orderSteps } from '@configs/urls'
import { datadogMessages } from '@constants/datadog'
import { PATHS, SUBPATHS } from '@constants/paths'
import { rangeErrors } from '@constants/shopResponseErrors'
import { HttpError } from '@errors/httpError'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useCartContext } from '@hooks/useCart'
import { useCheckoutContext } from '@hooks/useCheckout'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useProductsContext } from '@hooks/useProducts'
import { useUserContext } from '@hooks/useUserContext'
import { getValidationSchema } from '@schemas/pages/card/[claim-code]/cart/overview/schema'
import { api } from '@services/api'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'
import { determineIfDeducted } from '@utils/determineIfDeducted'
import { LimitsViolations, evaluateLimits } from '@utils/evaluateLimits'
import { getMainChoiceCardCurrency } from '@utils/getMainChoiceCardCurrency'

const REDIRECT_TIMEOUT = 3000

const CartOverview: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const { aggregatedCart, clearCart, getOrderId, setOrderId, setCart, saveCart } = useCartContext()
  const { mainKey } = useMainKeyContext()
  const { productsErrorCode, reloadProducts } = useProductsContext()
  const { isLoggedIn, email } = useUserContext()
  const { hashFlows } = useHashFlowContext()
  const { setCheckoutValues } = useCheckoutContext()

  const [error, setError] = useState<string | boolean | undefined>(false)
  const [violationError, setViolationError] = useState<LimitsViolations>()
  const persistForm = useRef(true)

  const dismissViolationError = () => setViolationError(undefined)

  const getInitialEmail = (): string => {
    if (isLoggedIn) return email
    if (mainKey) return hashFlows[mainKey]?.prefillEmail ?? ''
    return ''
  }

  const isMainCart = !Object.values(params).includes(PATHS.checkout)
  const isReviewCheckout = Object.values(params).includes(SUBPATHS.checkoutReview)
  const isPaymentCheckout = Object.values(params).includes(SUBPATHS.checkoutPaymentMethod)

  if (mainKey === null) return <CartNotInitialized />
  const currentCart = aggregatedCart.carts[mainKey]
  if (!currentCart) return

  const initialCheckoutValues: CheckoutFormikValues = {
    ...currentCart,
    email: currentCart.email || getInitialEmail() || '',
    choiceCardInput: '',
    paymentMethod: '',
  }

  const onSubmit = async (values: CheckoutFormikValues) => {
    const f = (x: number) => new Fraction(x)

    const orderId = getOrderId()

    if (orderId) {
      datadogLogs.logger.info(datadogMessages.attemptedOrderWhenOrderIdPresent)
      return
    }

    try {
      const violations = await evaluateLimits(values, mainKey)
      if (violations.violated) {
        setViolationError(violations)
        return
      }

      persistForm.current = true
      setError(false)

      const communication_email = values.email.trim().toLowerCase()
      const payment_method = values.paymentMethod || null
      const primary_claim_code = mainKey || ''

      const purchased_products_from_form = values.cards.map((card) => ({
        value: card.amount,
        amount: card.quantity,
        product: Number(card.id),
        used_fee_value: 0,
      }))

      // blank state lets you get fee values from backend
      // for individual cards
      const firstResponse = await api.calculateFees({
        payment_method,
        purchased_products: purchased_products_from_form,
        used_giftcards: [{ debit_value: 0, claim_code: mainKey }],
        primary_claim_code,
      })

      // we use returned values to add fees to individual cards
      const purchased_products_with_fees = values.cards.map((card, idx) => {
        const isFeeDeducted = determineIfDeducted(card.feePaymentOption, card.denominatedValues)

        return {
          value: isFeeDeducted
            ? f(card.amount).sub(firstResponse.products_with_fees[idx].fee).valueOf()
            : card.amount,
          amount: card.quantity,
          product: Number(card.id),
          used_fee_value: isFeeDeducted ? firstResponse.products_with_fees[idx].fee : 0,
        }
      })

      // second call lets you get correct total order value if there were no choice cards used
      // this lets you conclude how to debit choice cards
      const secondResponse = await api.calculateFees({
        payment_method,
        purchased_products: purchased_products_with_fees,
        used_giftcards: [{ debit_value: 0, claim_code: mainKey }],
        primary_claim_code,
      })

      let totalWithFees = secondResponse.order_value.total_amount_with_product_fee

      const used_giftcards = values.choiceCardsToUse
        .map(({ claim_code, debit_value }) => ({
          claim_code,
          debit_value,
        }))
        .map(({ claim_code, debit_value }) => {
          const middle = Math.max(f(totalWithFees).sub(debit_value).valueOf(), 0)
          const deducted = f(totalWithFees).sub(middle).valueOf()
          totalWithFees = f(totalWithFees).sub(deducted).valueOf()

          return { claim_code, debit_value: deducted }
        })

      // with correct individual cards with fees
      // and used choice cards
      // we get correct final amounts to pay
      const thirdResponse = await api.calculateFees({
        payment_method,
        purchased_products: purchased_products_with_fees,
        used_giftcards,
        primary_claim_code,
      })

      // total, as payment processing fee is added by mollie
      // the value total_with_fee we get from the backend is just to show on the ui
      const payment_amount = thirdResponse.payment_fee.total

      const result = await api.shopOrder({
        communication_email,
        payment_amount,
        payment_method,
        primary_claim_code,
        purchased_products: purchased_products_with_fees,
        used_giftcards,
      })

      const cart = setOrderId(result.pk)
      saveCart(cart)

      const paymentUrl = result.payment?.payment_url

      if (!paymentUrl) {
        datadogLogs.logger.info(datadogMessages.cardsOrderedPaymentless)
        navigate(generatePath(orderSteps.orderSummary, { id: result.pk }))
        return
      }

      datadogLogs.logger.info(datadogMessages.paymentStarted)
      window.location.href = paymentUrl
    } catch (e) {
      persistForm.current = false

      if (e instanceof HttpError && e.json !== null && typeof e.json === 'object') {
        const info = e.json
        if ('detail' in info && typeof info.detail === 'string' && info.detail in rangeErrors) {
          setError(info.detail)
          await reloadProducts()
          clearCart()
          setTimeout(
            () => navigate(generatePath(claimCodePaths.root, { claimCode: mainKey })),
            REDIRECT_TIMEOUT,
          )
          return
        }
      }
      setError(e?.toString())
      console.error('Error proceeding to payment', e)
    }
  }

  if (
    productsErrorCode === HttpCodes.get(HttpCodeNames.Forbidden) ||
    productsErrorCode === HttpCodes.get(HttpCodeNames.Unauthroized) ||
    mainKey !== params.claimCode
  ) {
    return <Navigate to={generatePath(claimCodePaths.root, { claimCode: params.claimCode })} />
  }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialCheckoutValues}
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={getValidationSchema(t)}
    >
      {({ values }) => {
        setCart(values)
        setCheckoutValues(values)

        return (
          <Root>
            <TrengoWidgetStyle />
            <WithTranslateFormErrors>
              <StyledForm>
                {isMainCart && (
                  <CartOrganism currency={getMainChoiceCardCurrency(aggregatedCart, mainKey)} />
                )}

                {isReviewCheckout && (
                  <ReviewOrganism currency={getMainChoiceCardCurrency(aggregatedCart, mainKey)} />
                )}

                {isPaymentCheckout && (
                  <PaymentOrganism
                    currency={getMainChoiceCardCurrency(aggregatedCart, mainKey)}
                    dismissViolationError={dismissViolationError}
                    error={error}
                    setError={setError}
                    violationError={violationError}
                  />
                )}
              </StyledForm>
            </WithTranslateFormErrors>
          </Root>
        )
      }}
    </Formik>
  )
}

const TrengoWidgetStyle = createGlobalStyle`
  div#trengo-web-widget {
    display: none;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  width: 100%;
`

const StyledForm = styled(Form)`
  flex-grow: 10;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`

export default CartOverview
