import { HttpError } from '@errors/httpError'

import { fetchApi } from './fetchApi'

export const fetchApiOrThrow = async (path: string, options?: RequestInit): Promise<Response> => {
  const res = await fetchApi(path, options)
  const status = res.status

  if (!res.ok) {
    const raw = await res.text()
    const httpError = new HttpError(raw, status, raw, 'Y')

    throw httpError
  }

  return res
}
