import { css } from 'styled-components'

export const LabelInputFieldCSS = css`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: none;
`
