import { signIn } from 'aws-amplify/auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AccountFormsWidthBoundary } from '@components/atoms/Content/AccountFormsWidthBoundary'
import { Footer } from '@components/atoms/Footer/Footer'
import { Fonts } from '@components/atoms/typography/Fonts'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { SignUpForm } from '@components/organisms/forms/SignUpForm/SignUpForm'
import { colours } from '@configs/colours'
import { claimCodePaths } from '@configs/urls'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useUserContext } from '@hooks/useUserContext'
import { api } from '@services/api'
import { Flows } from '@services/api.types'
import { mediaQueries } from '@utils/mediaQueries'

const SignUp: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { claimCode = '' } = useParams()
  const { hashFlows } = useHashFlowContext()
  const { setEmail, setIsLoggedIn } = useUserContext()

  return (
    <Root>
      <Arrangement>
        <AccountFormsWidthBoundary>
          <SignUpForm
            footer={() => {
              return (
                <Blue>
                  {t('alreadyAMember')}
                  <Underlined to={generatePath(claimCodePaths.account, { claimCode })}>
                    {t('forms.actions.logIn')}
                  </Underlined>
                </Blue>
              )
            }}
            submitAction={async ({ email, password, userSub }) => {
              try {
                await api.claimCodeClaim(email, claimCode, userSub)
                await api.checkCardFlow(claimCode)

                const { isSignedIn, nextStep } = await signIn({ username: email, password })

                if (!isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_UP')
                  throw new Error('Unable to confirm the account')

                setEmail(email)
                setIsLoggedIn(true)
                navigate(generatePath(claimCodePaths.welcome, { claimCode }))
              } catch (e) {
                console.error('Something went wrong when signing in after creating account:', e)
                throw e
              }
            }}
          >
            {() => (
              <TopInfo>
                <Greeting>{t('pleaseSignUp')}</Greeting>
              </TopInfo>
            )}
          </SignUpForm>
          {hashFlows[claimCode]?.flow === Flows.ShopAccountOptional && (
            <WideLink to={generatePath(claimCodePaths.welcome, { claimCode })}>
              <SecondaryButton>{t('maybeLater')}</SecondaryButton>
            </WideLink>
          )}
        </AccountFormsWidthBoundary>
      </Arrangement>
      <Footer />
    </Root>
  )
}

const TopInfo = styled.div`
  margin-bottom: 64px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 72px;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(180deg, #fff 1%, rgb(234, 238, 239) 100%);
`

const Greeting = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 24px;
  text-align: center;
`

const Arrangement = styled.div`
  margin-top: 32px;
`

const WideLink = styled(Link)`
  width: 100%;
  margin-top: 16px;
  display: block;
`

const Blue = styled(Fonts.BodyLarge)`
  color: ${colours.brand.blue};
  display: flex;
  gap: 8px;
  justify-content: center;
  text-align: center;
`

const Underlined = styled(Link)`
  text-decoration: underline;
`

export default SignUp
