import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Languages } from '@constants/language'
import { ProductWithFee } from '@typeDeclarations/api/calculateFees'
import { OrderItem } from '@typeDeclarations/orderItem'
import { calculateCardValueWithFee } from '@utils/calculateCardValueWithFee'
import { localisedPriceFactory } from '@utils/getLocalisedPrice'

type Props = { items?: OrderItem[]; currency?: string; calculatedItems?: ProductWithFee[] }

export const DetailedList: React.FC<Props> = ({ items, currency, calculatedItems }) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language as Languages

  if (calculatedItems) {
    const formatPrice = localisedPriceFactory(language, currency)

    return (
      <Root>
        {calculatedItems.map((item, idx) => {
          return (
            <Row key={`${idx}-${item.amount}`}>
              <ColumnLeft>
                <RowTitle>{item.name}</RowTitle>
                <div>
                  <BodyXSmall700>
                    {item.amount} x {t('ecard')}
                  </BodyXSmall700>
                  <BodyXSmall800Bold>
                    {' · '}
                    {formatPrice(item.value)}
                  </BodyXSmall800Bold>
                  {!!item.fee && (
                    <div>
                      <BodyXSmall700>{t('plusServiceFee')}</BodyXSmall700>
                      <BodyXSmall800Bold>
                        {' · '}
                        {formatPrice(item.fee)}
                      </BodyXSmall800Bold>
                    </div>
                  )}
                </div>
              </ColumnLeft>
              <ColumnRight>{formatPrice((item.value + item.fee) * item.amount)}</ColumnRight>
            </Row>
          )
        })}
      </Root>
    )
  }

  return (
    <Root>
      {items?.map((item, idx) => {
        const parsedAmount = !Number.isNaN(Number(item.amount)) ? Number(item.amount) : undefined
        const parsedQuantity = !Number.isNaN(Number(item.quantity)) ? Number(item.quantity) : 0
        const calculatedFees = calculateCardValueWithFee(
          parsedAmount,
          item.feePaymentOption,
          item.feeFlat,
          item.feePercentage,
          item.denominatedValues,
        )

        const balance = item.cardBalance ?? calculatedFees.cardBalance
        const price = item.cardPrice ?? calculatedFees.cardPrice
        const formatPrice = localisedPriceFactory(language, currency)
        const localisedFeeSingle = formatPrice(item.fee ?? calculatedFees.fee)
        const localisedCardBalanceSingle = formatPrice(balance)
        const localisedCardPriceMultiple = formatPrice(price * parsedQuantity)

        return (
          <Row key={`${item.id ?? idx}-${item.amount}-${item.quantity}`}>
            <ColumnLeft>
              <RowTitle>{item.title}</RowTitle>
              <div>
                <BodyXSmall700>
                  {parsedQuantity} x {t('ecard')}
                </BodyXSmall700>
                <BodyXSmall800Bold>
                  {' · '}
                  {localisedCardBalanceSingle}
                </BodyXSmall800Bold>
                {!!(item.fee ?? calculatedFees.fee) && (
                  <div>
                    <BodyXSmall700>{t('plusServiceFee')}</BodyXSmall700>
                    <BodyXSmall800Bold>
                      {' · '}
                      {localisedFeeSingle}
                    </BodyXSmall800Bold>
                  </div>
                )}
              </div>
            </ColumnLeft>
            <ColumnRight>{localisedCardPriceMultiple}</ColumnRight>
          </Row>
        )
      })}
    </Root>
  )
}

const Root = styled.div``

const Row = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const RowTitle = styled(Fonts.BodyLarge)`
  color: ${colours.brand.dark};
`

const ColumnLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`

const ColumnRight = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.mist[800]};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const BodyXSmall700 = styled(Fonts.BodyXSmall)`
  color: ${colours.mist[700]};
  display: inline;
`

const BodyXSmall800Bold = styled(Fonts.BodyXSmall)`
  color: ${colours.mist[800]};
  display: inline;
  font-weight: 600;
`
