import React from 'react'

import { BodyLarge } from '@components/atoms/typography/elements/BodyLarge'
import { BodyRegular } from '@components/atoms/typography/elements/BodyRegular'
import { BodyXSmall } from '@components/atoms/typography/elements/BodyXSmall'
import { ButtonCTALabelLarge } from '@components/atoms/typography/elements/ButtonCTALabelLarge'
import { ButtonCTALabelSmall } from '@components/atoms/typography/elements/ButtonCTALabelSmall'
import { ButtonTextLink } from '@components/atoms/typography/elements/ButtonTextLink'
import { IntroSubtextLarge } from '@components/atoms/typography/elements/IntroSubtextLarge'
import { IntroSubtextSmall } from '@components/atoms/typography/elements/IntroSubtextSmall'
import { Label } from '@components/atoms/typography/elements/Label'
import { LabelInputField } from '@components/atoms/typography/elements/LabelInputField'
import { MiscelaneousBig } from '@components/atoms/typography/elements/MiscelaneousBig'
import { MiscelaneousSpecialTitleBold } from '@components/atoms/typography/elements/MiscelaneousSpecialTitleBold'
import { ParagraphTitle } from '@components/atoms/typography/elements/ParagraphTitle'
import { TagText } from '@components/atoms/typography/elements/TagText'
import { TitleCardTitles } from '@components/atoms/typography/elements/TitleCardTitles'
import { TitleHeaderH1L } from '@components/atoms/typography/elements/TitleHeaderH1L'
import { TitleHeaderH1S } from '@components/atoms/typography/elements/TitleHeaderH1S'
import { TitleHeaderH2 } from '@components/atoms/typography/elements/TitleHeaderH2'
import { TitleHeaderH2S } from '@components/atoms/typography/elements/TitleHeaderH2S'

type StaticProps = {
  TagText: typeof TagText
  Label: typeof Label
  BodyRegular: typeof BodyRegular
  BodyLarge: typeof BodyLarge
  BodyXSmall: typeof BodyXSmall
  ParagraphTitle: typeof ParagraphTitle
  TitleHeaderH1L: typeof TitleHeaderH1L
  TitleHeaderH1S: typeof TitleHeaderH1S
  TitleHeaderH2: typeof TitleHeaderH2
  MiscelaneousSpecialTitleBold: typeof MiscelaneousSpecialTitleBold
  MiscelaneousBig: typeof MiscelaneousBig
  IntroSubtextLarge: typeof IntroSubtextLarge
  IntroSubtextSmall: typeof IntroSubtextSmall
  ButtonCTALabelSmall: typeof ButtonCTALabelSmall
  ButtonCTALabelLarge: typeof ButtonCTALabelLarge
  ButtonTextLink: typeof ButtonTextLink
  LabelInputField: typeof LabelInputField
  TitleCardTitles: typeof TitleCardTitles
  TitleHeaderH2S: typeof TitleHeaderH2S
}

export const Fonts: React.FC & StaticProps = () => null

Fonts.TagText = TagText
Fonts.Label = Label
Fonts.BodyRegular = BodyRegular
Fonts.BodyLarge = BodyLarge
Fonts.BodyXSmall = BodyXSmall
Fonts.ParagraphTitle = ParagraphTitle
Fonts.TitleHeaderH1L = TitleHeaderH1L
Fonts.TitleHeaderH1S = TitleHeaderH1S
Fonts.TitleHeaderH2 = TitleHeaderH2
Fonts.MiscelaneousSpecialTitleBold = MiscelaneousSpecialTitleBold
Fonts.MiscelaneousBig = MiscelaneousBig
Fonts.IntroSubtextLarge = IntroSubtextLarge
Fonts.IntroSubtextSmall = IntroSubtextSmall
Fonts.ButtonCTALabelSmall = ButtonCTALabelSmall
Fonts.ButtonCTALabelLarge = ButtonCTALabelLarge
Fonts.ButtonTextLink = ButtonTextLink
Fonts.LabelInputField = LabelInputField
Fonts.TitleCardTitles = TitleCardTitles
Fonts.TitleHeaderH2S = TitleHeaderH2S

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
// 100	Thin (Hairline)
// 200	Extra Light (Ultra Light)
// 300	Light
// 400	Normal (Regular)
// 500	Medium
// 600	Semi Bold (Demi Bold)
// 700	Bold
// 800	Extra Bold (Ultra Bold)
// 900	Black (Heavy)
// 950	Extra Black (Ultra Black)
