import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { ButtonCTALabelLargeCSS } from '@components/atoms/typography/css/ButtonCTALabelLargeCSS'

export const ButtonCTALabelLarge: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => <Root {...props}>{children}</Root>

const Root = styled.div`
  ${ButtonCTALabelLargeCSS}
`
