import { useField } from 'formik'
import { Suspense, useState } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { colours } from '@configs/colours'

type InputStates = { disabled?: boolean; $hasError?: boolean }

const TextInputContainer = styled.div<InputStates>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  input {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 8px;
    border: 1px solid ${(p) => (p.$hasError ? colours.error.red : colours.mist[400])};
    color: ${colours.mist[800]};
    font-family: 'Nunito Sans';
    height: 24px;
    padding: 8px 16px;
    &::placeholder {
      color: ${colours.mist[300]};
    }

    ${(p) =>
      p.disabled ? colours.mist[700] : p.$hasError ? colours.error.red : colours.mist[900]};

    &:focus,
    &:active,
    &:hover {
      border: 1px solid
        ${(p) =>
          p.disabled ? colours.mist[700] : p.$hasError ? colours.error.red : colours.mist[700]};
    }

    &:focus + div,
    &:active + div,
    &:hover + div {
      color: ${(p) =>
        p.disabled ? colours.mist[700] : p.$hasError ? colours.error.red : colours.prisma.black};
    }
  }
`

const LabelText = styled.div<InputStates>`
  background-color: ${colours.prisma.white};
  border-radius: 8px;
  color: ${(p) =>
    p.disabled ? colours.mist[700] : p.$hasError ? colours.error.red : colours.brand.dark};
  font-family: 'Nunito Sans';
  font-size: 14px;
  height: 20px;
  left: 12px;
  line-height: 20px;
  padding: 0 6px;
  position: absolute;
  top: -10px;
  width: fit-content;
`

const InputWrapper = styled.div`
  color: ${colours.mist[900]};
  position: relative;
  width: 100%;
  input:focus {
    outline: none;
  }
  input:focus::placeholder {
    color: transparent;
  }
`

const InputError = styled.span`
  color: ${colours.error.red};
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 16px;
  line-height: 16px;
  margin-top: 4px;
  position: relative;
`

const Label = styled.label`
  display: flex;
  flex-direction: column-reverse;
`

type CustomFieldProps = {
  description?: string
  name: string
  errorText?: string | null
  canPreviewPassword?: boolean
  showLabel?: boolean
} & React.HTMLProps<HTMLInputElement>

/**
 * Text or password input component
 */
export const TextInput: React.FC<CustomFieldProps> = ({
  description,
  placeholder = '',
  autoComplete,
  canPreviewPassword = false,
  showLabel = true,
  type,
  disabled,
  style,
  className,
  children,
  ...props
}) => {
  const [field, meta] = useField(props)
  const [obfuscated, setObfuscated] = useState(true)
  const textType = 'text'
  const hasError = !!meta.error

  if (type && type !== 'text' && type !== 'password') {
    return <span>Only text and password types are accepted by this component</span>
  }

  return (
    <TextInputContainer
      $hasError={hasError}
      className={className}
      disabled={disabled}
      style={style}
    >
      <InputWrapper>
        <Label htmlFor={props.name}>
          <input
            autoComplete={autoComplete}
            disabled={disabled}
            placeholder={placeholder}
            type={canPreviewPassword ? (obfuscated ? type : textType) : type}
            {...field}
            {...props}
          />
          <LabelText $hasError={hasError} disabled={disabled}>
            {description && showLabel ? description : ''}
          </LabelText>
          {children}
        </Label>
        {canPreviewPassword ? (
          <Suspense>
            <Preview onClick={() => setObfuscated(!obfuscated)}>
              {!obfuscated ? (
                <IconComponents.eyeClosed fill={colours.mist[400]} stroke={colours.mist[400]} />
              ) : (
                <IconComponents.eyeOpen fill={colours.mist[400]} stroke={colours.mist[400]} />
              )}
            </Preview>
          </Suspense>
        ) : null}
      </InputWrapper>
      {hasError && <InputError>{meta.error}</InputError>}
    </TextInputContainer>
  )
}

const Preview = styled.div`
  line-height: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-8px, -50%);
`
