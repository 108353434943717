import { PropsWithChildren, Suspense } from 'react'

import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { cartSteps } from '@configs/urls'
import { zIndexes } from '@constants/zIndexes'
import { useMainKeyContext } from '@hooks/useMainKey'
import { mediaQueries } from '@utils/mediaQueries'

export const TopSection: React.FC<PropsWithChildren<{ asPlaceholder?: boolean }>> = ({
  asPlaceholder,
  children,
}) => {
  const { t } = useTranslation()
  const { mainKey } = useMainKeyContext()
  const navigate = useNavigate()

  return (
    <TopWrapper>
      <div style={{ gridArea: 'center' }}>{children}</div>
      {!asPlaceholder && (
        <Close
          onClick={() => navigate(generatePath(cartSteps.main, { claimCode: mainKey }))}
          style={{ gridArea: 'right' }}
        >
          <VisibleFromTablet>
            <Suspense>
              <IconComponents.arrowLeft fill={colours.mist[900]} stroke={colours.mist[900]} />
            </Suspense>
            <ReturnDescription>{t('returnToCart')}</ReturnDescription>
          </VisibleFromTablet>
          <Suspense>
            <CloseIcon fill={colours.mist[900]} stroke={colours.mist[900]} />
          </Suspense>
        </Close>
      )}
    </TopWrapper>
  )
}

const ReturnDescription = styled(Fonts.ButtonCTALabelLarge)`
  white-space: nowrap;
`

const CloseIcon = styled(IconComponents.cross)`
  width: 16px;
  height: 16px;
`

const TopWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'left center right';
  justify-content: flex-end;
  position: relative;
  position: sticky;
  right: 0px;
  top: 0px;
  z-index: ${zIndexes.baseOnTop};
  height: 48px;
  padding-top: 8px;
  ${mediaQueries.from.breakpoint.desktop} {
    height: 100px;
  }
`

const VisibleFromTablet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${mediaQueries.to.breakpoint.tablet} {
    display: none;
  }
`

const Close = styled.div`
  color: ${colours.mist[800]};
  background-color: ${colours.mist[200]};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-left: auto;
  ${mediaQueries.from.breakpoint.tablet} {
    display: flex;
    gap: 16px;
    width: fit-content;
    border-radius: 160px;
    padding: 0 32px;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    margin-top: 24px;
  }
`
