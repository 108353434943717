import { PropsWithChildren, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { DivProps } from '@typeDeclarations/elements/div'

type Props = {
  $visible?: boolean
} & DivProps

export const GeneralError: React.FC<PropsWithChildren<Props>> = ({
  $visible,
  children,
  ref: _ref,
  ...props
}) => {
  const { t } = useTranslation()

  return $visible ? (
    <Root {...props}>
      {children ? <Message>{children}</Message> : t('generalError')}
      <Circle>
        <Suspense>
          <IconComponents.cross
            fill={colours.tomato[110]}
            height="12"
            stroke={colours.tomato[110]}
            width="12"
          />
        </Suspense>
      </Circle>
    </Root>
  ) : null
}

const Root = styled(Fonts.BodyLarge)`
  align-items: center;
  color: ${colours.tomato[110]};
  cursor: pointer;
  display: flex;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: auto;
  padding: 8px 0;
`

const Message = styled.div``

const Circle = styled.div`
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  background-color: ${colours.tomato[10]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`
