import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BasicChip } from '@components/atoms/chips/BasicChip'
import { Languages } from '@constants/language'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'

type Props = { debitValue?: number | string; currency?: string }

export const WhiteCard: React.FCS<Props> = ({ className, currency, debitValue }) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language as Languages

  return (
    <Root className={className}>
      <TopInfo>
        <div>
          <svg
            fill="none"
            height="31"
            viewBox="0 0 33 31"
            width="33"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2183_5452)">
              <path
                d="M31.2441 19.2655V23.6032C31.2441 24.0963 31.0334 24.5691 30.6584 24.9177C30.2833 25.2664 29.7746 25.4622 29.2441 25.4622H3.24414C2.71371 25.4622 2.205 25.2664 1.82993 24.9177C1.45485 24.5691 1.24414 24.0963 1.24414 23.6032V19.2655C2.30501 19.2655 3.32242 18.8738 4.07257 18.1766C4.82271 17.4793 5.24414 16.5336 5.24414 15.5475C5.24414 14.5614 4.82271 13.6158 4.07257 12.9185C3.32242 12.2212 2.30501 11.8295 1.24414 11.8295V7.49182C1.24414 6.99878 1.45485 6.52593 1.82993 6.1773C2.205 5.82867 2.71371 5.63281 3.24414 5.63281H29.2441C29.7746 5.63281 30.2833 5.82867 30.6584 6.1773C31.0334 6.52593 31.2441 6.99878 31.2441 7.49182V11.8295C30.1833 11.8295 29.1659 12.2212 28.4157 12.9185C27.6656 13.6158 27.2441 14.5614 27.2441 15.5475C27.2441 16.5336 27.6656 17.4793 28.4157 18.1766C29.1659 18.8738 30.1833 19.2655 31.2441 19.2655Z"
                stroke="#FF5500"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <line
                stroke="#FF5500"
                strokeDasharray="2 2"
                strokeWidth="1.5"
                x1="10.3281"
                x2="10.3281"
                y1="5.63281"
                y2="25.4622"
              />
            </g>
            <defs>
              <clipPath id="clip0_2183_5452">
                <rect
                  fill="white"
                  height="29.7441"
                  transform="translate(0.244141 0.675781)"
                  width="32"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <BasicChip>
          {getLocalisedPrice(language, debitValue ? Number(debitValue) : undefined, currency)}
        </BasicChip>
      </TopInfo>
      <BottomInfo>
        <div>
          <DummyCardName>{t('choiceCardWhiteCard')}</DummyCardName>
          <DummyCardNumber>000 212 343 000</DummyCardNumber>
        </div>
        <svg
          fill="none"
          height="41"
          viewBox="0 0 40 41"
          width="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3828 8.89844L25.298 20.1484L14.3828 31.3984"
            stroke="#FF5500"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
      </BottomInfo>
    </Root>
  )
}

const Root = styled.div`
  align-items: center;
  background: rgba(245, 245, 245, 0.8);
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 2px 4px 36px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: space-between;
  padding: 32px 32px 24px;
  transform-origin: center;
  width: 100%;
`

const TopInfo = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const BottomInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
`

const DummyCardName = styled.div`
  color: #141a1b;
  flex-grow: 0;
  flex: none;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  height: 48px;
  letter-spacing: -0.04em;
  line-height: 48px;
  order: 0;
  width: 236px;
  white-space: nowrap;

  ${mediaQueries.to.breakpoint.tablet} {
    font-size: 24px;
  }
`

const DummyCardNumber = styled.div`
  width: 128px;
  height: 24px;
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ff5500;
  flex: none;
  order: 1;
  flex-grow: 0;
  white-space: nowrap;
`
