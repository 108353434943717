import { datadogLogs } from '@datadog/browser-logs'
import { CatalogueProduct } from '@services/api.types'
import { FeePaymentOptionEnum } from '@typeDeclarations/feePaymentOption'

import { calculateCardValueWithFee } from './calculateCardValueWithFee'

export const getSuggestedPriceChoices = (
  { value_choices, min_value, max_value }: CatalogueProduct,
  globalMaxPurchaseValue?: number | null,
  feePaymentOption?: FeePaymentOptionEnum | null,
  feeFlat?: number | null,
  feePercentage?: number | null,
) => {
  const min = Number(min_value)
  const max = Number(max_value)

  const filterUniqe = (c: number, i: number, s: number[]) => i === s.indexOf(c)
  const filterWithinRange = (min: number, max: number) => (v: number) => v <= max && v >= min
  const filterGlobalMax = (globalMaxPurchaseValue?: number | null) => (v: number) => {
    if (typeof globalMaxPurchaseValue !== 'number') return true
    return v <= globalMaxPurchaseValue
  }
  const filterDeductedFeeBelowThreshlod =
    (
      feePaymentOption?: FeePaymentOptionEnum | null,
      feeFlat?: number | null,
      feePercentage?: number | null,
    ) =>
    (suggestedAmount: number) => {
      const { cardBalance } = calculateCardValueWithFee(
        suggestedAmount,
        feePaymentOption,
        feeFlat,
        feePercentage,
      )

      return cardBalance > 0 && cardBalance >= min
    }

  const hasValueChoices = Array.isArray(value_choices) && value_choices.length > 0
  const hasMinAndMax = !Number.isNaN(min) && !Number.isNaN(max)

  if (hasValueChoices)
    return value_choices
      .filter(filterWithinRange(min, max))
      .filter(filterGlobalMax(globalMaxPurchaseValue))
      .filter(filterDeductedFeeBelowThreshlod(feePaymentOption, feeFlat, feePercentage))
  if (hasMinAndMax)
    return [min, max]
      .filter(filterUniqe)
      .filter(filterGlobalMax(globalMaxPurchaseValue))
      .filter(filterDeductedFeeBelowThreshlod(feePaymentOption, feeFlat, feePercentage))

  datadogLogs.logger.warn('Cannot determine price value choices' + import.meta.env.PROD)

  return []
}
