import { t } from 'i18next'

import { HttpError } from '@errors/httpError'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'

const HttpErrors = new Map<number | undefined, { title: string; description: string }>([
  [
    HttpCodes.get(HttpCodeNames.BadRequest),
    {
      title: t('httpErrors.badRequest.title'),
      description: t('httpErrors.badRequest.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.Unauthroized),
    {
      title: t('httpErrors.unauthorized.title'),
      description: t('httpErrors.unauthorized.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.Forbidden),
    {
      title: t('httpErrors.forbidden.title'),
      description: t('httpErrors.forbidden.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.NotFound),
    {
      title: t('httpErrors.notFound.title'),
      description: t('httpErrors.notFound.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.MethodNotAllowed),
    {
      title: t('httpErrors.methodNotAllowed.title'),
      description: t('httpErrors.methodNotAllowed.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.TooManyRequests),
    {
      title: t('httpErrors.tooManyRequests.title'),
      description: t('httpErrors.tooManyRequests.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.InternalServerError),
    {
      title: t('httpErrors.internalServerError.title'),
      description: t('httpErrors.internalServerError.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.NotImplemented),
    {
      title: t('httpErrors.notImplemented.title'),
      description: t('httpErrors.notImplemented.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.BadGateway),
    {
      title: t('httpErrors.badGateway.title'),
      description: t('httpErrors.badGateway.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.ServiceUnavailable),
    {
      title: t('httpErrors.serviceUnavailable.title'),
      description: t('httpErrors.serviceUnavailable.description'),
    },
  ],
  [
    HttpCodes.get(HttpCodeNames.GatewayTimeout),
    {
      title: t('httpErrors.gatewayTimeout.title'),
      description: t('httpErrors.gatewayTimeout.description'),
    },
  ],
])

export const getHttpErrorInfo = (e: unknown) => {
  if (!(e instanceof HttpError)) return { description: '', title: '' }

  return (
    HttpErrors.get(e.status) ?? {
      title: t('httpErrors.errorCallingApi.title'),
      description: t('httpErrors.errorCallingApi.description'),
    }
  )
}
