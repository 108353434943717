export const afterDelay = (callback: () => void | Promise<void>, timeout: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      void (async () => {
        try {
          await callback()
          resolve(undefined)
        } catch (e) {
          console.warn('Something went wrong invoking function after delay', e)
        }
      })()
    }, timeout)
  })
