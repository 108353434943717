import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { LabelInputFieldCSS } from '@components/atoms/typography/css/LabelInputFieldCSS'

export const LabelInputField: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => <Root {...props}>{children}</Root>

const Root = styled.div`
  ${LabelInputFieldCSS}
`
