import { useFormikContext } from 'formik'
import React, { PropsWithChildren, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Modal } from '@components/atoms/modals/Modal'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { TitleHeaderH2SCSS } from '@components/atoms/typography/css/TitleHeaderH2SCSS'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'

import { colours } from '@configs/colours'
import { concatenate } from '@utils/concatenate'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'

type ConfirmAddCardWithFee = {
  currency?: string
  feeFlat?: number | null
  feePercentage?: number | null
  isFeeDeducted?: boolean | null
  isFeePaid?: boolean | null
  onClose?: () => void
  open?: boolean
}

export const ConfirmAddCardWithFee: React.FC<PropsWithChildren<ConfirmAddCardWithFee>> = ({
  currency,
  feeFlat,
  feePercentage,
  isFeeDeducted,
  isFeePaid,
  onClose,
  open,
}) => {
  const { i18n, t } = useTranslation()
  const { submitForm } = useFormikContext()

  const hasFee =
    (typeof feePercentage === 'number' && feePercentage > 0) ||
    (typeof feeFlat === 'number' && feeFlat > 0)
  const percentageFee =
    typeof feePercentage === 'number' && feePercentage !== 0 && `${feePercentage}%`
  const flatFee =
    typeof feeFlat === 'number' &&
    feeFlat !== 0 &&
    getLocalisedPrice(i18n.language, feeFlat, currency)
  const fees = concatenate([percentageFee, flatFee], { separator: ' + ' })
  const feesCount = +!!percentageFee + +!!flatFee

  if (!hasFee) throw new Error('[Modal] Expeceted fee to be present')

  return (
    <Modal onClose={onClose} open={open}>
      <Root>
        <Suspense>
          <OuterInfoCircle>
            <InnerInfoCircle>
              <IconComponents.info fill={colours.prisma.black} stroke={colours.prisma.black} />
            </InnerInfoCircle>
          </OuterInfoCircle>
        </Suspense>
        <Title>{t('additionalServiceFee')}</Title>
        <Text>
          {isFeeDeducted && t('fee.deducted.explanation', { count: feesCount, fees })}
          {isFeePaid && t('fee.paid.explanation', { count: feesCount, fees })}
        </Text>
        <PrimaryButton
          onClick={() => {
            void submitForm()
            onClose?.()
          }}
        >
          {t('forms.actions.confirm')}
        </PrimaryButton>
      </Root>
    </Modal>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  max-width: 400px;
`

const OuterInfoCircle = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colours.sunflower[20]};
  border-radius: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
`

const InnerInfoCircle = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colours.sunflower[40]};
  border-radius: 100%;
  box-sizing: border-box;
`

const Title = styled.div`
  ${TitleHeaderH2SCSS};
  margin-bottom: 16px;
`

const Text = styled.div`
  ${BodyLargeCSS};
  margin-bottom: 24px;
  color: ${colours.mist[600]};
`
