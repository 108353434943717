import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { WhiteCard } from '@components/atoms/cards/WhiteCard'
import { Chip } from '@components/atoms/Chip/Chip'
import { Languages } from '@constants/language'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  currency?: string
  placeholderSlot?: boolean
  debitValue?: string
  slotImage?: string | null
}

export const CardStack: React.FCS<Props> = ({
  currency,
  placeholderSlot,
  debitValue,
  slotImage,
  className,
}) => {
  const { i18n } = useTranslation()
  const langauge = i18n.language as Languages
  const [loadImageError, setLoadImageError] = useState(false)

  return (
    <CardStackRoot className={className}>
      <PresentationCard>
        <ShadowCard />
        <GhostCard />
        {(!slotImage || loadImageError) && (
          <ImageCard>
            <WhiteCard currency={currency} debitValue={debitValue} />
          </ImageCard>
        )}
        {!loadImageError && slotImage && !placeholderSlot && (
          <ImageCard>
            <SlotImage onError={() => setLoadImageError(true)} src={slotImage} />
            {(typeof debitValue === 'string' || typeof debitValue === 'number') && (
              <StyledChip>{getLocalisedPrice(langauge, Number(debitValue), currency)}</StyledChip>
            )}
          </ImageCard>
        )}
      </PresentationCard>
    </CardStackRoot>
  )
}

const CardStackRoot = styled.div`
  --w: 400px;
  --h: 260px;
  --desktop-rotation: 30deg;
  --mobile-rotation: 20deg;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  & > *:only-child {
    margin-left: auto;
    margin-right: auto;
  }
  ${mediaQueries.from.breakpoint.mobileL} {
    height: calc(var(--h) * cos(var(--mobile-rotation)) + var(--w) * sin(var(--mobile-rotation)));
  }
  ${mediaQueries.from.breakpoint.desktop} {
    height: calc(var(--h) * cos(var(--desktop-rotation)) + var(--w) * sin(var(--desktop-rotation)));
  }
`

const PresentationCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`

const ShadowCard = styled.div`
  background: rgba(255, 255, 255, 0.6);
  border-radius: 32px;
  grid-column: 1;
  grid-row: 1;
  height: var(--h);
  width: var(--w);
  ${mediaQueries.from.breakpoint.mobileL} {
    transform: rotate(calc(-1 * var(--mobile-rotation)));
  }
  ${mediaQueries.from.breakpoint.desktop} {
    transform: rotate(calc(-1 * var(--desktop-rotation)));
  }
`

const GhostCard = styled.div`
  background: #ffffff;
  border-radius: 32px;
  grid-column: 1;
  grid-row: 1;
  height: var(--h);
  width: var(--w);
  ${mediaQueries.from.breakpoint.mobileL} {
    transform: rotate(-10deg);
  }
  ${mediaQueries.from.breakpoint.desktop} {
    transform: rotate(-15deg);
  }
`

const ImageCard = styled.div`
  grid-column: 1;
  grid-row: 1;
  margin: 18px;
  pointer-events: none;
  ${mediaQueries.from.breakpoint.mobileL} {
    transform: rotate(-10deg);
  }
  ${mediaQueries.from.breakpoint.desktop} {
    transform: rotate(-15deg);
  }
`

const SlotImage = styled.img`
  border-radius: 16px;
  box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.2);
  height: 224px;
  outline-offset: -2px;
  outline: 2px solid rgba(255, 255, 255, 0.4);
  width: 360px;
  background-color: white;
`

const StyledChip = styled(Chip)`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0 12px;
  border-radius: 16px;
`
