export enum Vectors {
  Shop = 'shop',
  Furniture = 'furniture',
  GameConsole = 'gameConsole',
  ShoppingBags = 'shoppingBags',
  Gift = 'gift',
  Monitor = 'monitor',
  Hourglass = 'hourglass',
  SmileHappy = 'smileHappy',
  SmileIndifferent = 'smileIndifferent',
  SmileSad = 'smileSad',
  SmileSmile = 'smileSmile',
  SmileUnhappy = 'smileUnhappy',
  Celebration = 'celebration',
}
