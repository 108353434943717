import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { DivProps } from '@typeDeclarations/elements/div'

export const Chip: React.FCS<PropsWithChildren<DivProps>> = ({
  children,
  className,
  ref: _ref,
  ...props
}) => (
  <Root className={className} {...props}>
    {children}
  </Root>
)

const Root = styled(Fonts.ButtonCTALabelLarge)`
  align-items: center;
  background-color: ${colours.brand.dark};
  border-radius: 12px;
  color: ${colours.mist[50]};
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 0 8px;
`
