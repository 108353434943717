import { keyframes } from 'styled-components'

export const floating = keyframes`
0% {
  transform: rotate(0deg);
  top: 0px;
};

20% {
  transform: rotate(7deg);
  top: 20px;
}

40% {
  transform: rotate(0deg);
  top: 30px;
}

60% { 
  transform: rotate(-7deg);
  top: 15px;
}

70% {
  transform: rotate(-4deg);
  top: 10px;
};

80% {
  transform: rotate(-4deg);
  top: 5px;
};

90% {
  transform: rotate(-1deg);
}

100% {
  transform: rotate(0deg);
  top: 0px;
};
`
