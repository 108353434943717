import React, { PropsWithChildren, Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { DivProps } from '@typeDeclarations/elements/div'

type Arrangement = 'center' | 'right' | 'left' | 'spaced'

type Props = DivProps & {
  titleText?: React.ReactNode
  chevronPosition?: 'left' | 'right'
  arrangement?: Arrangement
  chevron?: React.ReactNode
  expanded: boolean
  onExpandedChange: () => void
  chevronColor?: string
  CustomTitleComponent?: React.ComponentType<PropsWithChildren>
}

export const Accordion: React.FC<PropsWithChildren<Props>> = ({
  children,
  chevron,
  chevronPosition = 'right',
  arrangement = 'spaced',
  expanded,
  onExpandedChange,
  titleText: title,
  chevronColor = '#000',
  ...props
}) => (
  <Root {...props}>
    <TitleBar
      $arrangement={arrangement}
      onClick={() => {
        onExpandedChange()
      }}
    >
      <TitleContent $chevronPosition={chevronPosition}>{title}</TitleContent>
      <Suspense>
        <Indicator $rotated={!expanded}>
          {chevron ? chevron : <IconComponents.chevron fill={chevronColor} stroke={chevronColor} />}
        </Indicator>
      </Suspense>
    </TitleBar>
    {expanded && <Content>{children}</Content>}
  </Root>
)

const Root = styled.div``

const TitleBar = styled.div<{ $arrangement: Arrangement }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: ${(p) => p.$arrangement === 'spaced' && 'space-between'};
  justify-content: ${(p) => p.$arrangement === 'center' && 'center'};
  justify-content: ${(p) => p.$arrangement === 'left' && 'flex-start'};
  justify-content: ${(p) => p.$arrangement === 'right' && 'flex-end'};
`

const TitleContent = styled.div<{ $chevronPosition?: 'right' | 'left' }>`
  order: ${(p) => p.$chevronPosition === 'left' && 2};
  order: ${(p) => p.$chevronPosition === 'right' && 0};
`

const Indicator = styled.div<{ $rotated?: boolean }>`
  margin-left: 4px;
  order: 1;
  transform: ${(p) => (p.$rotated ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.2s ease-in-out;
`

const Content = styled.div``
