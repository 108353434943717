import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours } from '@configs/colours'
import { PATHS } from '@constants/paths'
import { floating } from '@styles/animations/floating'
import { wave } from '@styles/animations/wave'

export const CartNotInitialized: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Root>
      <Container>
        <DesktopCircle>
          <Circle>
            <Suspense>
              <FloatingGift>
                <VectorsDict.gift fill={colours.mandarin[110]} />
              </FloatingGift>
              <Svg
                fill="none"
                height="24"
                viewBox="0 0 1215 24"
                width="1215"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M523.133 18.9884L388.725 4.43435C334.674 -1.4184 274.218 -1.47751 220.037 4.257L83.972 18.9884C57.085 21.8997 28.6131 23.4558 0.117188 23.5V24H607.117H1214.12V23.5469C1186.04 23.4968 1157.97 21.9691 1130.13 18.9884L995.725 4.43435C941.674 -1.4184 881.218 -1.47751 827.037 4.257L690.972 18.9884C664.085 21.8997 635.613 23.4558 607.117 23.5V23.5469C579.044 23.4968 550.973 21.9691 523.133 18.9884Z"
                  fill={colours.brand.blue}
                  fillRule="evenodd"
                />
              </Svg>
            </Suspense>
            <WaterFill />
          </Circle>
        </DesktopCircle>
        <Title>{t('cartNotInitialized')}</Title>
        <Subtitle>{t('cartNotInitializedDescription')}</Subtitle>
        <StyledPrimaryButton onClick={() => navigate(PATHS.root)}>
          {t('forms.actions.home')}
        </StyledPrimaryButton>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  background: linear-gradient(180deg, #fff 1%, rgb(234, 238, 239) 100%);
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
`

const Title = styled(Fonts.TitleHeaderH2S)`
  margin-bottom: 16px;
`

const Subtitle = styled(Fonts.BodyLarge)`
  color: ${colours.mist[400]};
  margin-bottom: 16px;
  max-width: 400px;
  text-align: center;
`

const DesktopCircle = styled.div``

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 32px;
`

const FloatingGift = styled.div`
  position: relative;
  animation: ${floating} 3s linear infinite;
`

const Svg = styled.svg`
  position: absolute;
  top: 178px;
  animation: ${wave} 3s linear infinite;
`

const WaterFill = styled.div`
  background-color: ${colours.brand.blue};
  height: 140px;
  width: 304px;
  position: absolute;
  top: 202px;
  z-index: 2;
`

const Circle = styled.div`
  align-items: center;
  background-color: ${colours.mist[100]};
  border-radius: 100%;
  display: flex;
  height: 304px;
  justify-content: center;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  width: 304px;
  z-index: 3;
`
