import { useTranslation } from 'react-i18next'

import { styled } from 'styled-components'

import { BodyXSmallCSS } from '@components/atoms/typography/css/BodyXSmallCSS'
import { colours } from '@configs/colours'
import { Languages } from '@constants/language'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { getSummaryValues } from '@utils/getSummaryValues'

type Props = {
  priceFromCards: number | undefined
  amountFromChoiceCards: number | undefined
  currency: string | undefined
  paymentProcessingFee: PaymentProcessingFee | undefined
  calculatedPaymentProcessingFee?: number
}

export const PaymentProcessingFeeComponent: React.FC<Props> = ({
  priceFromCards,
  amountFromChoiceCards,
  currency,
  paymentProcessingFee,
  calculatedPaymentProcessingFee,
}) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language as Languages

  const summaryValues = getSummaryValues({
    priceFromCards,
    amountFromChoiceCards,
    paymentProcessingFee,
    currency,
  })

  const localisedPaymentProcessingFee = calculatedPaymentProcessingFee
    ? getLocalisedPrice(language, calculatedPaymentProcessingFee, currency)
    : summaryValues.paymentProcessingFeeValue
    ? getLocalisedPrice(language, summaryValues.paymentProcessingFeeValue, summaryValues.currency)
    : null

  return localisedPaymentProcessingFee ? (
    <Root>
      <Text>{t('creditCardTransactionCost')}</Text>
      <Text>
        {'+'} {localisedPaymentProcessingFee}
      </Text>
    </Root>
  ) : null
}

const Root = styled.div`
  border-radius: 16px;
  background-color: ${colours.blues[10]};
  border: 1px solid ${colours.blues[10]};
  box-sizing: border-box;
  padding: 12px;
  display: flex;
  justify-content: space-between;
`

const Text = styled.span`
  ${BodyXSmallCSS};
  color: ${colours.blues[110]};
`
