import { useTranslation } from 'react-i18next'

import { Banner } from '@components/atoms/tooltips/Banners/Banner'
import { generalErrors, rangeErrors } from '@constants/shopResponseErrors'

type Props = {
  error?: string | boolean
  setError: undefined | ((value: boolean) => void)
  setOrderError: undefined | ((value: boolean) => void)
}

export const GenericErrorBanner: React.FC<Props> = ({ error, setError, setOrderError }) => {
  const { t } = useTranslation()

  const getErrorDescription = (error: string | boolean | undefined): string | undefined => {
    if (typeof error !== 'string') return t('bannerUnableToProcessOrderDescription')
    if (error in rangeErrors) return `${t('backend.' + error)}. ${t('youWillBeRedirected')}`
    if (error in generalErrors) return t('backend.' + error)
  }

  return (
    <Banner
      onClose={() => {
        setError?.(false)
        setOrderError?.(false)
      }}
      title={t('bannerUnableToProcessOrder')}
      type="error"
    >
      {getErrorDescription(error)}
    </Banner>
  )
}
