import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'

import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { Icons } from '@typeDeclarations/components/atoms/icons'

export const Cta: React.FC<{
  actionHandler?: () => void
  actionDisabled?: boolean
  type?: 'button' | 'submit'
  text?: string
  loading?: boolean
  iconRight?: Icons
}> = ({ actionDisabled, actionHandler, type = 'button', text, loading, iconRight }) => {
  const { t } = useTranslation()

  return loading ? (
    <LoadingButton />
  ) : (
    <PrimaryButton
      disabled={actionDisabled}
      iconRight={iconRight}
      onClick={() => actionHandler?.()}
      type={type}
    >
      {text ? text : t('forms.actions.submit')}
    </PrimaryButton>
  )
}
