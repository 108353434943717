import { AggregatedCart } from '@context/cart'

export const getMainChoiceCardCurrency = (
  aggregatedCart: AggregatedCart,
  mainKey?: string | null,
): string | undefined => {
  if (!mainKey) return
  return aggregatedCart.carts[mainKey]?.choiceCardsToUse.find(
    ({ claim_code }) => claim_code === mainKey,
  )?.currency
}
