type F<T extends unknown[], U> = (...args: T) => U | undefined

export const once = <T extends unknown[], U>(fn: F<T, U>) => {
  let run = false
  return (...args: Parameters<F<T, U>>): ReturnType<F<T, U>> => {
    let r
    if (!run) r = fn.apply(this, args)
    run = true
    return r
  }
}
