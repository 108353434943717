import { getCurrentUser } from 'aws-amplify/auth'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as y from 'yup'

import { BasicErrorComponent } from '@components/atoms/Error/Error'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { CannotClaimEmptyCard } from '@components/organisms/modals/CannotClaimEmptyCard/CannotClaimEmptyCard'
import { shareKeyPaths } from '@configs/urls'
import { datadogMessages } from '@constants/datadog'
import { shopResponseErrors } from '@constants/shopResponseErrors'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { HttpError } from '@errors/httpError'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useUserContext } from '@hooks/useUserContext'
import { Flows, ReceiveInfo } from '@services/api.types'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { DivProps } from '@typeDeclarations/elements/div'
import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { HashType } from '@typeDeclarations/hashType'
import { checkUserExists } from '@utils/checkUserExists'
import { receiveClaim } from '@utils/claimHelpers'

type Props = DivProps & {
  shareKey: string
  data: ReceiveInfo | undefined
}

export const ClaimForm: React.FC<Props> = ({ data, shareKey, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dispatchHashFlows, setEmailDelayInfo } = useHashFlowContext()
  const {
    isLoggedIn,
    email: currentlyLoggedInUserEmail,
    logOut,
    setEmail,
    setIsLoggedIn,
  } = useUserContext()
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [errorDescription, setErrorDescription] = useState<string>()
  const [cannotClaimEmptyCardModalOpen, setCannotClaimEmptyCardModalOpen] = useState(false)

  const receiveClaimForKnownUser = async (email: string, shareKey: string) => {
    const { username } = await getCurrentUser()
    await receiveClaim({
      username,
      email,
      shareKey,
      unconfirmed_user: false,
      navigate,
      setEmailDelayInfo,
      setEmail,
      setIsLoggedIn,
    })
  }
  const receiveClaimForUnknownUser = async (email: string, shareKey: string) => {
    await receiveClaim({
      username: null,
      email,
      shareKey,
      unconfirmed_user: false,
      navigate,
      setEmailDelayInfo,
      setEmail,
      setIsLoggedIn,
    })
  }

  useEffect(() => {
    if (!data?.flow) return

    dispatchHashFlows({
      type: HashFlowActions.SetHashFlow,
      payload: { hash: shareKey, flow: data.flow, type: HashType.ShareKey },
    })

    datadogRum.setGlobalContextProperty('shareKey', {
      shareKey,
      flow: data.flow,
    })
  }, [data?.flow, shareKey, dispatchHashFlows])

  const prefillEmail = data?.prefill_email ?? ''
  const initialValues = { email: prefillEmail }
  const cardEmpty = Number(data?.balance) === 0

  const schema = y.object({
    email: y.string().email().trim().required(t('forms.validation.emailRequired')),
  })

  const redirectBasedOnIfUserExists = async (email: string) => {
    const userExists = await checkUserExists(email)

    if (userExists) {
      navigate({
        pathname: generatePath(shareKeyPaths.account, { shareKey }),
        search: createSearchParams({ email }).toString(),
      })
      return
    }

    navigate({
      pathname: generatePath(shareKeyPaths.signUp, { shareKey }),
      search: createSearchParams({ email }).toString(),
    })
  }

  const onSubmit = async ({ email: emailRaw }: typeof initialValues) => {
    if (cardEmpty) {
      setCannotClaimEmptyCardModalOpen(true)
      return
    }

    const email = emailRaw.trim().toLowerCase()

    if (!data?.flow) return
    datadogLogs.logger.info(datadogMessages.choiceCardClaimed)

    setErrorOccurred(false)

    try {
      switch (data.flow) {
        case Flows.ShopGuest:
          if (isLoggedIn) {
            await logOut()
          }
          await receiveClaimForUnknownUser(email, shareKey)
          return

        case Flows.ShopAccountMandatory:
          if (!isLoggedIn) {
            await redirectBasedOnIfUserExists(email)
            return
          }

          if (email !== currentlyLoggedInUserEmail) {
            await logOut()
            await redirectBasedOnIfUserExists(email)
            return
          }
          await receiveClaimForKnownUser(email, shareKey)
          return

        case Flows.ShopAccountOptional:
          if (!isLoggedIn) {
            await redirectBasedOnIfUserExists(email)
            return
          }

          if (email !== currentlyLoggedInUserEmail) {
            await logOut()
            await redirectBasedOnIfUserExists(email)
            return
          }

          await receiveClaimForKnownUser(email, shareKey)
          return
      }
    } catch (e) {
      setErrorOccurred(true)

      console.error('Something went wrong claiming card:', e)
      setErrorDescription(undefined)

      if (e instanceof HttpError && e.json !== null && typeof e.json === 'object') {
        const nonFieldError =
          'non_field_errors' in e.json && Array.isArray(e.json.non_field_errors)
            ? (e.json.non_field_errors[0] as string)
            : undefined
        if (nonFieldError && nonFieldError in shopResponseErrors)
          setErrorDescription('backend.' + nonFieldError)
      }
    }
  }

  return (
    <Root {...props}>
      <CannotClaimEmptyCard
        onClose={() => setCannotClaimEmptyCardModalOpen(false)}
        open={cannotClaimEmptyCardModalOpen}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <WithTranslateFormErrors>
          <Form>
            <StyledTextInput
              description={t('forms.fields.email')}
              name="email"
              placeholder={t('forms.placeholders.email')}
              type="text"
            />
            {errorOccurred && (
              <BasicErrorComponent onClose={() => setErrorOccurred(false)}>
                {errorDescription ? t(errorDescription) : t('somethingWentWrong')}
              </BasicErrorComponent>
            )}
            <PrimaryButton disabled={!data?.flow} iconRight={Icons.ArrowRight} type="submit">
              {t('forms.actions.claim')}
            </PrimaryButton>
          </Form>
        </WithTranslateFormErrors>
      </Formik>
    </Root>
  )
}

const StyledTextInput = styled(TextInput)`
  margin-bottom: 16px;
`

const Root = styled.div``
