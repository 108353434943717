import { signIn } from 'aws-amplify/auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AccountFormsWidthBoundary } from '@components/atoms/Content/AccountFormsWidthBoundary'
import { Footer } from '@components/atoms/Footer/Footer'
import { Fonts } from '@components/atoms/typography/Fonts'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { LoginActionArgs, LoginForm } from '@components/organisms/forms/LoginForm/LoginForm'
import { colours } from '@configs/colours'
import { claimCodePaths } from '@configs/urls'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useUserContext } from '@hooks/useUserContext'
import { api } from '@services/api'
import { Flows } from '@services/api.types'
import { mediaQueries } from '@utils/mediaQueries'

const Account: React.FC = () => {
  const { t } = useTranslation()
  const { hashFlows } = useHashFlowContext()
  const { claimCode = '' } = useParams()
  const navigate = useNavigate()
  const { setEmail, setIsLoggedIn } = useUserContext()

  const flow = hashFlows[claimCode]?.flow

  const loginAction = async ({ username, password, unconfirmed }: LoginActionArgs) => {
    try {
      await api.checkCardFlow(claimCode)
      if (unconfirmed) {
        const { isSignedIn, nextStep } = await signIn({ username, password })

        if (isSignedIn) {
          setEmail(username)
          setIsLoggedIn(true)
        }

        if (nextStep.signInStep === 'CONFIRM_SIGN_UP')
          throw new Error('Account confirmation failed')
      }

      navigate(generatePath(claimCodePaths.welcome, { claimCode }))
    } catch (e) {
      console.error('Something went wrong when logging in: ', e)
    }
  }

  return (
    <Root>
      <AccountFormsWidthBoundary>
        <Arrangement>
          <StyledLoginForm
            footer={(form) => {
              return (
                form === 'login' && (
                  <AccountFormFooter>
                    <SignUpAction>
                      <Blue>{t('noAccountQuestion')}</Blue>
                      <WideLink to={generatePath(claimCodePaths.signUp, { claimCode })}>
                        <BlueLink>{t('forms.actions.signUp')}</BlueLink>
                      </WideLink>
                    </SignUpAction>
                    {flow === Flows.ShopAccountOptional && (
                      <WideLink to={generatePath(claimCodePaths.welcome, { claimCode })}>
                        <SecondaryButton>{t('maybeLater')}</SecondaryButton>
                      </WideLink>
                    )}
                  </AccountFormFooter>
                )
              )
            }}
            loginAction={loginAction}
            throwWhenAccountUnconfirmed={false}
          >
            {(form) => {
              return (
                <>
                  {form === 'login' && (
                    <TopInfo>
                      <Greeting>{t('welcomeBack')}</Greeting>
                      {flow === Flows.ShopAccountMandatory && (
                        <Subtitle>{t('loginRequired')}</Subtitle>
                      )}
                    </TopInfo>
                  )}
                  {form === 'forgotPassword' && (
                    <TopInfo>
                      <Greeting>{t('didYouForgetPassword')}</Greeting>
                      <Subtitle>{t('enterEmailToRemindPassword')}</Subtitle>
                    </TopInfo>
                  )}
                  {form === 'forgotPasswordProceed' && (
                    <TopInfo>
                      <Greeting>{t('almostThere')}</Greeting>
                      <Subtitle>{t('enterCodeAndNewPassword')}</Subtitle>
                    </TopInfo>
                  )}
                  {form === 'confirmSignInWithNewPasswordRequired' && (
                    <TopInfo>
                      <Greeting>{t('newPassword')}</Greeting>
                      <Subtitle>{t('passwordChangeRequired')}</Subtitle>
                    </TopInfo>
                  )}
                </>
              )
            }}
          </StyledLoginForm>
        </Arrangement>
      </AccountFormsWidthBoundary>
      <Footer />
    </Root>
  )
}

const Blue = styled(Fonts.BodyLarge)`
  color: ${colours.blues[100]};
`

const BlueLink = styled(Fonts.BodyLarge)`
  color: ${colours.blues[100]};
  text-decoration: underline;
  cursor: pointer;
`

const AccountFormFooter = styled.div`
  margin-top: 80px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-top: 48px;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background: ${colours.prisma.background};
  background: linear-gradient(180deg, #fff 1%, rgb(234, 238, 239) 100%);
`

const Arrangement = styled.div`
  margin-top: 32px;
`

const WideLink = styled(Link)`
  display: block;
  margin-bottom: 16px;
`

const Greeting = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
`

const Subtitle = styled(Fonts.BodyLarge)`
  margin-bottom: 40px;
  color: ${colours.mist[800]};
  text-align: center;
`

const TopInfo = styled.div`
  margin-bottom: 40px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: calc(12 * 8px);
  }
`

const StyledLoginForm = styled(LoginForm)`
  margin-bottom: 64px;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 48px;
  }
`

const SignUpAction = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`

export default Account
