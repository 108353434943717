import { css } from 'styled-components'

export const colours = {
  brand: {
    blue: '#0076FF',
    dark: '#141A1B',
    green: '#28CBA2',
    orange: '#f36e21',
    purple: '#5C00BA',
    yellow: '#FFC700',
  },
  error: {
    red: '#FF3939',
  },
  prisma: {
    black: '#000',
    white: '#fff',
    background: '#fbfbfd',
  },
  mist: {
    50: '#F9FBFC',
    100: '#F2F4F5',
    200: '#EDF0F1',
    300: '#DDE0E2',
    400: '#C6C9CC',
    500: '#A1A3A4',
    600: '#898C8D',
    700: '#727676',
    800: '#4F5354',
    900: '#2C3132',
  },
  blues: {
    10: '#E5F1FF',
    20: '#CCE4FF',
    30: '#A6CFFF',
    40: '#80BAFF',
    60: '#58A5FF',
    80: '#3391FF',
    100: '#0076FF',
    110: '#006AE5',
  },
  plant: {
    10: '#E9FAF6',
    20: '#D4F5EC',
    30: '#B4EDDE',
    40: '#93E5D1',
    60: '#72DCC1',
    80: '#53D5B5',
    100: '#28CBA2',
    110: '#24B792',
  },
  tomato: {
    10: '#FFEBEB',
    20: '#FFD7D7',
    30: '#FFBABA',
    40: '#FF9C9C',
    60: '#FF7D7D',
    80: '#FF6161',
    100: '#FF3939',
    110: '#E53333',
    120: '#b22d2d',
  },
  mandarin: {
    10: '#FFEEE5',
    20: '#FFDDCC',
    30: '#FFC3A6',
    40: '#FFAA80',
    60: '#FF8F58',
    80: '#FF7733',
    100: '#FF5500',
    110: '#E54D00',
  },
  plum: {
    10: '#EFE5F8',
    20: '#DECCF1',
    30: '#C6A6E7',
    40: '#AD80DC',
    60: '#9458D2',
    80: '#7C33C8',
    100: '#5C00BA',
    110: '#5300A7',
    115: '#3e057e',
  },
  sunflower: {
    10: '#FFF9E5',
    20: '#FFF4CC',
    30: '#FFEBA6',
    40: '#FFE380',
    60: '#FFDA58',
    80: '#FFD233',
    100: '#FFC700',
    110: '#E5B300',
  },
}

export const gradients = {
  defaultBackground: css`
    background: linear-gradient(180deg, #ffffff 0%, #f4f6f7 50%, #edf0f1 100%);
  `,
}
