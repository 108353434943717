import styled from 'styled-components'

export const ZigZagBottom = styled.div<{ $backgroundColor?: string }>`
  background: ${(p) => (p.$backgroundColor ? p.$backgroundColor : 'white')};
  height: 16px;
  --mask: conic-gradient(from -35deg at bottom, #0000, #000 0deg 70deg, #0000 70deg) 50% / 20px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
`

export const ZigZagTop = styled.div<{ $backgroundColor?: string }>`
  background: ${(p) => (p.$backgroundColor ? p.$backgroundColor : 'white')};
  height: 16px;
  --mask: conic-gradient(from 145deg at top, #0000, #000 1deg 70deg, #0000 70deg) 50% / 22px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
`
