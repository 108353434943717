import { useState } from 'react'

export const useSavedState = (
  key: string,
  initialState?: string,
): [string | null, (newValue: string | null) => void] => {
  const init =
    localStorage.getItem(key) === null && typeof initialState !== 'undefined'
      ? (localStorage.setItem(key, initialState), initialState)
      : localStorage.getItem(key)

  const [state, setState] = useState(init)

  const setAndSaveState = (newValue: string | null) => {
    setState(newValue)

    if (newValue === null) {
      localStorage.removeItem(key)
      return
    }

    localStorage.setItem(key, newValue)
  }

  return [state, setAndSaveState]
}
