export enum PaymentStatus {
  OPEN = 'open',
  CANCELED = 'canceled',
  PENDING = 'pending',
  EXPIRED = 'expired',
  FAILED = 'failed',
  PAID = 'paid',
  REFUNDED = 'refunded',
  CHARGED_BACK = 'charged_back',
}
