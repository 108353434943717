import { Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

import { Icons } from '@typeDeclarations/components/atoms/icons'
import { DivProps } from '@typeDeclarations/elements/div'

type Props = DivProps & {
  mainInfo?: React.ReactNode
  sideInfo?: React.ReactNode
  actionIcon?: Icons
  action?: () => void
}

export const ChoiceCardBarV2: React.FCS<Props> = ({
  mainInfo,
  sideInfo,
  action,
  className,
  style,
  actionIcon,
  ref: _ref,
  ...props
}) => {
  const I = actionIcon ? IconComponents[actionIcon] : IconComponents.plus

  return (
    <Root className={className} style={style} {...props}>
      <LeftGroup>
        <Parallelogram />
        <CounterText>{mainInfo}</CounterText>
      </LeftGroup>
      <Cursor $canClick={!!action} onClick={() => action?.()}>
        {sideInfo}
        {action && (
          <Circle>
            <Suspense>
              <I fill={colours.blues[110]} stroke={colours.blues[110]} />
            </Suspense>
          </Circle>
        )}
      </Cursor>
    </Root>
  )
}

const Parallelogram = styled.div`
  box-sizing: border-box;
  width: 24px;
  min-width: 24px;
  height: 15px;
  min-height: 15px;
  background: #141a1b;
  border: 0.2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1.5px 2px rgba(163, 163, 163, 0.25);
  border-radius: 4px;
  transform: matrix(0.87, -0.5, 0.87, 0.5, 0, 0);
`

const CounterText = styled(Fonts.TagText)`
  color: ${colours.prisma.black};
  font-weight: 400;
`

const Root = styled(Fonts.BodyLarge)`
  min-height: 52px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  background-color: ${colours.mist[100]};
`

const LeftGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Cursor = styled.div<{ $canClick?: boolean }>`
  cursor: ${(p) => (p.$canClick ? 'pointer' : 'unset')};
  color: ${colours.brand.blue};
  line-height: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Circle = styled.div`
  background-color: ${colours.blues[30]};
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
