export enum HttpCodeNames {
  Unauthroized = 'Unauthorized',
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  NotFound = 'NotFound',
  BadRequest = 'BadRequest',
  MethodNotAllowed = 'MethodNotAllowed',
  TooManyRequests = 'TooManyRequests',
  NotImplemented = 'NotImplemented',
  BadGateway = 'BadGateway',
  ServiceUnavailable = 'ServiceUnavailable',
  GatewayTimeout = 'GatewayTimeout',
}

export const HttpCodes = new Map([
  [HttpCodeNames.BadRequest, 400],
  [HttpCodeNames.Unauthroized, 401],
  [HttpCodeNames.Forbidden, 403],
  [HttpCodeNames.NotFound, 404],
  [HttpCodeNames.MethodNotAllowed, 405],
  [HttpCodeNames.TooManyRequests, 429],
  [HttpCodeNames.InternalServerError, 500],
  [HttpCodeNames.NotImplemented, 501],
  [HttpCodeNames.BadGateway, 502],
  [HttpCodeNames.ServiceUnavailable, 503],
  [HttpCodeNames.GatewayTimeout, 504],
])
