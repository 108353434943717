import { Accordion } from '@components/atoms/Accordion/Accordion'
import { TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { ButtonCTALabelLargeCSS } from '@components/atoms/typography/css/ButtonCTALabelLargeCSS'
import { TitleHeaderH2CSS } from '@components/atoms/typography/css/TitleHeaderH2CSS'
import { colours } from '@configs/colours'
import { mediaQueries } from '@utils/mediaQueries'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const ReferFriendFAQ: React.FC = () => {
  const { t } = useTranslation()

  const [faqFirst, setFaqFirst] = useState(true)
  const [faqSecond, setFaqSecond] = useState(false)
  const [faqThird, setFaqThird] = useState(false)
  const [faqFourth, setFaqFourth] = useState(false)
  const [faqFifth, setFaqFifth] = useState(false)

  return (
    <>
      <FAQTitle>{t('faq')}</FAQTitle>
      <Accordion
        expanded={faqFirst}
        onExpandedChange={() => setFaqFirst((v) => !v)}
        titleText={<AccordionTitle>{t('refer.faq.titleFirst')}</AccordionTitle>}
      >
        <AccordionText>{t('refer.faq.textFirst')}</AccordionText>
      </Accordion>
      <Accordion
        expanded={faqSecond}
        onExpandedChange={() => setFaqSecond((v) => !v)}
        titleText={<AccordionTitle>{t('refer.faq.titleSecond')}</AccordionTitle>}
      >
        <AccordionText>{t('refer.faq.textSecond')}</AccordionText>
      </Accordion>
      <Accordion
        expanded={faqThird}
        onExpandedChange={() => setFaqThird((v) => !v)}
        titleText={<AccordionTitle>{t('refer.faq.titleThird')}</AccordionTitle>}
      >
        <AccordionText>{t('refer.faq.textThird')}</AccordionText>
      </Accordion>
      <Accordion
        expanded={faqFourth}
        onExpandedChange={() => setFaqFourth((v) => !v)}
        titleText={<AccordionTitle>{t('refer.faq.titleFourth')}</AccordionTitle>}
      >
        <AccordionText>{t('refer.faq.textFourth')}</AccordionText>
      </Accordion>
      <Accordion
        expanded={faqFifth}
        onExpandedChange={() => setFaqFifth((v) => !v)}
        titleText={<AccordionTitle>{t('refer.faq.titleFifth')}</AccordionTitle>}
      >
        <AccordionText>{t('refer.faq.textFifth')}</AccordionText>
      </Accordion>
    </>
  )
}

const FAQTitle = styled.div`
  color: ${colours.brand.dark};
  margin-bottom: 24px;
  ${mediaQueries.from.breakpoint.tablet} {
    ${TitleHeaderH1SCSS};
  }
  ${mediaQueries.to.breakpoint.tablet} {
    ${TitleHeaderH2CSS}
  }
`

const AccordionTitle = styled.div`
  color: ${colours.brand.dark};
  ${ButtonCTALabelLargeCSS};
  margin: 12px 0 12px 0;
`

const AccordionText = styled.div`
  ${BodyRegularCSS};
  color: ${colours.mist[700]};
  margin-bottom: 24px;
`
