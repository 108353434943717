import styled, { css } from 'styled-components'

import { mediaQueries } from '@utils/mediaQueries'

export const WidthBoundaryCSS = css`
  ${mediaQueries.to.breakpoint.desktop} {
    padding-right: max(calc((100% / 2) - calc(1200px / 2)), 16px);
    padding-left: max(calc((100% / 2) - calc(1200px / 2)), 16px);
  }

  ${mediaQueries.from.breakpoint.desktop} {
    padding-right: max(calc((100% / 2) - calc(1200px / 2)), 24px);
    padding-left: max(calc((100% / 2) - calc(1200px / 2)), 24px);
  }
`

export const WidthBoundary = styled.div`
  ${WidthBoundaryCSS}
`
