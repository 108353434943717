import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import CardStack from '/images/card-stack.png'

import { BoxWithTick } from '@components/atoms/BoxWithTick/BoxWithTick'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PaymentMethods } from '@components/molecules/checkout/PaymentMethods'
import { colours } from '@configs/colours'

import { cartSteps } from '@configs/urls'
import { useCheckoutContext } from '@hooks/useCheckout'
import { useMainKeyContext } from '@hooks/useMainKey'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  creditCardDisabled?: boolean
  idealDisabled?: boolean
  paymentMethodChoiceDisabled?: boolean
  paymentRequired?: boolean
  onValueChange?: (newValue: unknown) => void
}

export const PaymentSelector: React.FC<PropsWithChildren<Props>> = ({
  paymentMethodChoiceDisabled,
  creditCardDisabled,
  idealDisabled,
  paymentRequired,
  onValueChange,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { mainKey } = useMainKeyContext()
  const { choiceCardsCount } = useCheckoutContext()

  return (
    <Root>
      <Description>
        <div>
          <Title>
            {paymentRequired ? t('paymentRequiredTitle') : t('paymentNotRequiredTitle')}
          </Title>
          <Subtitle>
            {paymentRequired ? t('selectPreferredPaymentMethod') : t('noPaymentRequired')}
          </Subtitle>
        </div>
        <ChoiceCardsLink
          onClick={() => navigate(generatePath(cartSteps.main, { claimCode: mainKey }))}
        >
          {t('moreChoiceCardsQuestion')}
        </ChoiceCardsLink>
      </Description>
      <PaymentMethodsWrapper>
        <BoxWithTick>
          <BoxContent>
            <BoxImage src={CardStack} />
            {t('yourChoiceCards', { number: choiceCardsCount })}
          </BoxContent>
        </BoxWithTick>
        <PaymentMethods
          choiceDisabled={paymentMethodChoiceDisabled}
          creditCardDisabled={creditCardDisabled}
          idealDisabled={idealDisabled}
          onValueChange={(newValue) => onValueChange?.(newValue)}
        />
      </PaymentMethodsWrapper>
    </Root>
  )
}

const Root = styled.div``

const Title = styled(Fonts.TitleHeaderH2S)`
  width: 100%;
`

const ChoiceCardsLink = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.brand.blue};
  text-decoration: underline;
  cursor: pointer;
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`

const Subtitle = styled(Fonts.BodyLarge)`
  width: 100%;
  color: ${colours.mist[800]};
`

const PaymentMethodsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  ${mediaQueries.to.breakpoint.tablet} {
    display: flex;
    flex-direction: column;
  }
  ${mediaQueries.within.breakpoint.tablet} {
    display: flex;
    flex-direction: column;
  }
`

const Description = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: grid;
  gap: 32px;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: 1fr max-content;
  }
  ${mediaQueries.within.breakpoint.tablet} {
    grid-template-columns: 1fr;
  }
`

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  ${mediaQueries.to.breakpoint.tablet} {
    flex-direction: row;
    gap: 8px;
  }
`

const BoxImage = styled.img`
  height: 92px;
  ${mediaQueries.to.breakpoint.tablet} {
    height: 40px;
  }
`
