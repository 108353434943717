import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { LabelCSS } from '@components/atoms/typography/css/LabelCSS'

export const Label: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => <Root {...props}>{children}</Root>

const Root = styled.div`
  ${LabelCSS}
`
