import { useField } from 'formik'
import { Suspense, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { ButtonCTALabelLargeCSS } from '@components/atoms/typography/css/ButtonCTALabelLargeCSS'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { DivProps } from '@typeDeclarations/elements/div'

type Props = {
  name: string
  description?: string
  max?: number
  min?: number
  sign?: string
  $step?: number
  onMinusClick?: () => void
  onPlusClick?: () => void
  placeholder?: string | number
  step?: string
  disabled?: boolean
  onValueChange?: (v: unknown) => void
} & DivProps

export const PlusMinusNumberInput: React.FC<Props> = ({
  className,
  description,
  max,
  min,
  name,
  sign,
  $step = 1,
  onMinusClick,
  onPlusClick,
  placeholder,
  lang,
  step,
  disabled,
  onValueChange,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(name)
  const inputRef = useRef<HTMLInputElement>(null)
  const { current: onValueChangeHandler } = useRef(onValueChange)

  useEffect(() => {
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && 'value' in mutation.target) {
          onValueChangeHandler?.(mutation.target.value)
        }
      })
    })

    if (inputRef.current) mutationObserver.observe(inputRef.current, { attributes: true })

    return () => mutationObserver.disconnect()
  }, [])

  const minDisabled = typeof min === 'number' && field.value <= min
  const maxDisabled = typeof max === 'number' && field.value >= max
  const plusButtonDisabled = disabled || maxDisabled
  const minusButtonDisabled = disabled || minDisabled

  return (
    <Root className={className} {...props}>
      {description && <Description>{description}</Description>}
      <InputArea $error={error}>
        <ButtonWrapper
          $disabled={minusButtonDisabled}
          onClick={() => {
            if (!minusButtonDisabled) {
              void setValue(Number(field.value) - $step)
              onMinusClick?.()
            }
          }}
          type="button"
        >
          <Suspense>
            <IconComponents.minus />
          </Suspense>
        </ButtonWrapper>
        <InputAndGhost>
          <Input
            disabled={disabled}
            lang={lang}
            ref={inputRef}
            step={step}
            type="number"
            {...field}
            role="input"
          />
          {!field.value && field.value !== 0 && (
            <FakePlaceholder $sign={sign} role="placeholder">
              {placeholder}
            </FakePlaceholder>
          )}
          {!!field.value && (
            <TrailingSign $disabled={disabled} $sign={sign}>
              {inputRef.current && inputRef.current.value}
            </TrailingSign>
          )}
        </InputAndGhost>
        <ButtonWrapper
          $disabled={plusButtonDisabled}
          onClick={() => {
            if (!plusButtonDisabled) {
              void setValue(Number(field.value) + $step)
              onPlusClick?.()
            }
          }}
          type="button"
        >
          <Suspense>
            <IconComponents.plus />
          </Suspense>
        </ButtonWrapper>
      </InputArea>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Description = styled(Fonts.BodyRegular)`
  color: ${colours.mist[700]};
`

const InputArea = styled.div<{ $error?: string | boolean }>`
  position: relative;
  align-items: center;
  background-color: ${colours.prisma.white};
  border-radius: 8px;
  border: 1px solid ${(p) => (p.$error ? colours.tomato[80] : colours.mist[100])};
  display: flex;
  height: 40px;
  justify-content: flex-start;
  width: 100%;
`

const InputAndGhost = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TrailingSign = styled.div<{ $sign?: string; $disabled?: boolean }>`
  ${ButtonCTALabelLargeCSS};
  color: rgba(0, 0, 0, 0);
  left: 50%;
  top: 50%;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: 24px;
  &::before {
    content: '${(props) => props.$sign ?? ''}';
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    color: ${(p) => (p.$disabled ? colours.mist[300] : colours.prisma.black)};
    line-height: 23px;
  }
`

const FakePlaceholder = styled.section<{ $sign?: string }>`
  ${ButtonCTALabelLargeCSS};
  position: absolute;
  text-align: center;
  pointer-events: none;
  width: fit-content;
  height: 24px;
  color: ${colours.mist[400]};
  &::before {
    content: '${(props) => props.$sign ?? ''}';
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    line-height: 23px;
  }
`

const Input = styled.input`
  all: unset;
  ${ButtonCTALabelLargeCSS};
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  &:focus + section {
    display: none;
  }

  ${({ disabled }) => disabled && `color: ${colours.mist[300]}`}
`

const ButtonWrapper = styled.button<{ $disabled?: boolean }>`
  all: unset;
  min-width: 28px;
  width: 28px;
  height: 28px;
  background-color: ${(p) => (p.$disabled ? colours.mist[300] : colours.brand.dark)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colours.prisma.white};
  cursor: ${(p) => (p.$disabled ? 'unset' : 'pointer')};
  margin: 0 6px;
`
