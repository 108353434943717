import { createElement, useRef } from 'react'
import { styled } from 'styled-components'

import Diamond from '@components/atoms/dummyCards/Diamond'
import Dots from '@components/atoms/dummyCards/Dots'
import RandomWithBlackText from '@components/atoms/dummyCards/RandomBgWithBlack'
import Stripe from '@components/atoms/dummyCards/Stripe'
import { DivProps } from '@typeDeclarations/elements/div'
import { randomInt } from '@utils/randomInt'

type GiftCardProps = {
  title?: string
  description?: string
  dummyImage?: boolean
}

const MAX_DESCRIPTION_LENGTH = 150

export const DummyGiftCardVertical: React.FC<GiftCardProps & DivProps> = ({
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra, tortor sed ultricies mollis, est nunc pellentesque elit, quis dapibus augue quam vel turpis. Duis ac sem libero.',
  title = 'Lorem Ipsum',
  ...props
}) => {
  const dummies = [RandomWithBlackText, Diamond, Dots, Stripe]
  const dummy = dummies[randomInt(0, dummies.length)]
  const dummyRef = useRef(dummy)

  return (
    <Root {...props}>
      <Content>{createElement(dummyRef.current)}</Content>
      <Content>
        {title && <CardTitle>{title}</CardTitle>}
        {description && (
          <CardDescription>
            {description.length > MAX_DESCRIPTION_LENGTH
              ? description.substring(0, MAX_DESCRIPTION_LENGTH) + '...'
              : description}
          </CardDescription>
        )}
      </Content>
    </Root>
  )
}

const Root = styled.section`
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  padding: 8px;
  width: 100%;
`

const Content = styled.div`
  flex-basis: 50%;
`

const CardTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
`

const CardDescription = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
  word-break: break-all;
  /* */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 3lh;
`
