import { HttpError } from '@errors/httpError'

export const extractAddChoiceCardError = (e: HttpError) => {
  const t = (s: string) => s

  const additionErrors = {
    card_not_found: t('choiceCardAddition.cardNotFound'),
    permission_error: t('choiceCardAddition.permissionError'),
    flow_mismatch: t('choiceCardAddition.flowMismatch'),
    organization_mismatch: t('choiceCardAddition.organizationMismatch'),
    currency_mismatch: t('choiceCardAddition.currencyMismatch'),
    self_addition_error: t('choiceCardAddition.selfAdditionError'),
  }

  if (typeof e.json === 'object' && e.json !== null && 'detail' in e.json) {
    const detail = e.json.detail
    if (detail === 'card_not_found') return additionErrors.card_not_found
    if (Array.isArray(detail) && typeof detail[0] === 'string') {
      const key = detail[0] as keyof typeof additionErrors
      if (key in additionErrors) return additionErrors[key]
    }
  }
}
