export const lightenOrDarken = (
  hsl: [number, number, number],
  amount: number,
  options?: LightenOrDarkenOptions,
): [number, number, number] => {
  const { max, min } = Math
  const [h, s, l] = hsl
  return [h, s, max(options?.minLightness ?? 0, min(options?.maxLightness ?? 1, amount + l))]
}

type LightenOrDarkenOptions = {
  maxLightness?: number
  minLightness?: number
}
