import React, { PropsWithChildren, Suspense } from 'react'

import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { colours } from '@configs/colours'
import { Icons } from '@typeDeclarations/components/atoms/icons'

type SecondaryButtonProps = { iconLeft?: Icons; iconRight?: Icons }

export const SecondaryButton: React.FCS<
  PropsWithChildren<SecondaryButtonProps & React.ComponentProps<'button'>>
> = ({ className, children, iconLeft, iconRight, ...props }) => {
  const L = iconLeft ? IconComponents[iconLeft] : 'div'
  const R = iconRight ? IconComponents[iconRight] : 'div'

  return (
    <Suspense>
      <Root disabled={props.disabled} {...props} className={className}>
        {iconLeft && <L />}
        {children && <span style={{ whiteSpace: 'nowrap' }}>{children}</span>}
        {iconRight && <R />}
      </Root>
    </Suspense>
  )
}

const Root = styled.button`
  /* Button/CTA Label - Large */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  /* Button/CTA Label - Large */

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 12px;
  padding: 0 16px;

  height: 48px;
  width: 100%;

  background-color: ${colours.prisma.white};
  color: ${colours.prisma.black};
  border: 1px solid ${colours.mist[200]};

  & svg,
  & path {
    stroke: ${colours.prisma.black};
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    background-color: ${colours.mist[50]};
  }

  &:disabled {
    color: ${colours.mist[400]};
    background-color: ${colours.mist[100]};
    & svg,
    & path {
      stroke: ${colours.mist[400]};
    }
  }

  &:active:not(:disabled) {
    background-color: ${colours.mist[50]};
  }
`
