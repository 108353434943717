import { css } from 'styled-components'

export const BodyXSmallCSS = css`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
`
