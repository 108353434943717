import Fraction from 'fraction.js'

import { FeePaymentOptionEnum } from '@typeDeclarations/feePaymentOption'

export const calculateCardValueWithFee = (
  amount?: number,
  feeType?: FeePaymentOptionEnum | null,
  flat?: number | null,
  percentage?: number | null,
  denominatedValues?: null | boolean,
) => {
  if (!amount) throw new Error('[Fee calculation] No amount provided')
  const normalizedPercentage = typeof percentage === 'number' ? percentage : 0
  const normalizedFlat = typeof flat === 'number' ? flat : 0

  const fee = new Fraction(amount)
    .mul(new Fraction(normalizedPercentage).div(100))
    .add(new Fraction(normalizedFlat))

  if (denominatedValues && feeType === FeePaymentOptionEnum.DeductFromCard) {
    const feeValue = fee.round(2).valueOf()

    return {
      baseAmount: amount,
      cardBalance: amount,
      cardPrice: amount + feeValue,
      fee: feeValue,
      feeType,
      isFeeDeducted: true,
      isFeePaid: false,
      normalizedFlat,
      normalizedPercentage,
      feeFlat: flat,
      feePercentage: percentage,
    }
  }

  if (feeType === FeePaymentOptionEnum.FeeViaPayment) {
    const feeValue = fee.round(2).valueOf()
    const cardPriceValue = new Fraction(amount).add(fee).round(2).valueOf()

    return {
      baseAmount: amount,
      cardBalance: amount,
      cardPrice: cardPriceValue,
      fee: feeValue,
      feeType,
      isFeeDeducted: false,
      isFeePaid: true,
      normalizedFlat,
      normalizedPercentage,
      feeFlat: flat,
      feePercentage: percentage,
    }
  }

  if (feeType === FeePaymentOptionEnum.DeductFromCard) {
    const feeValue = fee.round(2).valueOf()
    const cardBalanceValue = new Fraction(amount).sub(fee).round(2).valueOf()

    return {
      baseAmount: amount,
      cardBalance: cardBalanceValue,
      cardPrice: amount,
      fee: feeValue,
      feeType,
      isFeeDeducted: true,
      isFeePaid: false,
      normalizedFlat,
      normalizedPercentage,
      feeFlat: flat,
      feePercentage: percentage,
    }
  }

  return {
    baseAmount: amount,
    cardBalance: amount,
    cardPrice: amount,
    fee: 0,
    feeType: undefined,
    isFeeDeducted: undefined,
    isFeePaid: undefined,
    normalizedFlat,
    normalizedPercentage,
  }
}
