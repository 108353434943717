import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { WhiteCard } from '@components/atoms/cards/WhiteCard'
import { Chip } from '@components/atoms/Chip/Chip'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { colours } from '@configs/colours'
import { capitalizedLanguageMap } from '@constants/language'
import { useCartContext } from '@hooks/useCart'
import { useSettingsContext } from '@hooks/useSettings'
import { ShopBanner } from '@services/api.types'
import { getCurrencySymbol } from '@utils/getCurrencySymbol'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'

type Props = { debit_value?: number | undefined; currency?: string | undefined }

export const BrandHeader: React.FC<Props> = ({
  debit_value: propsDebitValue,
  currency: propsCurrency,
}) => {
  const { colorBar, slotImage, shopBannerType, languageSpecifics } = useSettingsContext()
  const { i18n, t } = useTranslation()
  const { getCurrentMainChoiceCard } = useCartContext()
  const { debit_value: contextDebitValue, currency: contextCurrency } =
    getCurrentMainChoiceCard() ?? {}
  const language = i18n.language

  const debit_value =
    typeof propsCurrency !== 'undefined' && typeof propsDebitValue !== 'undefined'
      ? propsDebitValue
      : contextDebitValue
  const currency =
    typeof propsCurrency !== 'undefined' && typeof propsDebitValue !== 'undefined'
      ? propsCurrency
      : contextCurrency

  const shopText = languageSpecifics?.find(
    (el) =>
      el.language === capitalizedLanguageMap[i18n.language as keyof typeof capitalizedLanguageMap],
  )?.shop_text

  const noBalance =
    (typeof debit_value === 'number' || typeof debit_value === 'string') &&
    Number(debit_value) === 0

  return (
    <Root $baseColor={colorBar}>
      {shopBannerType === ShopBanner.IconsRotated && (
        <RotatedIcons>
          <Suspense>
            <RotatedShop fill={colours.prisma.white} height="200" width="200" />
            <RotatedGameConsole fill={colours.prisma.white} height="300" width="300" />
            <RotatedFurniture fill={colours.prisma.white} height="215" width="215" />
            <RotatedShoppingBags fill={colours.prisma.white} height="135" width="135" />
          </Suspense>
        </RotatedIcons>
      )}
      {shopBannerType === ShopBanner.IconsVertical && (
        <VerticalIcons>
          <Suspense>
            <VerticalShop fill={colours.prisma.white} height="135" width="135" />
            <VerticalFurniture fill={colours.prisma.white} height="145" width="145" />
            <VerticalShoppingBags fill={colours.prisma.white} height="135" width="135" />
            <VerticalGameConsole fill={colours.prisma.white} height="130" width="130" />
          </Suspense>
        </VerticalIcons>
      )}
      <Foreground>
        {shopBannerType === ShopBanner.Text && <LoudHeader>{shopText ?? t('shop')}</LoudHeader>}
        <PresentationCard>
          <ShadowCard />
          <GhostCard />
          {!slotImage && (
            <ImageCard>
              <WhiteCard currency={currency} debitValue={debit_value} />
            </ImageCard>
          )}
          {slotImage && (
            <ImageCard>
              <SlotImage $faded={noBalance} src={slotImage} />
              {(typeof debit_value === 'number' || typeof debit_value === 'string') && (
                <StyledChip $noBalance={noBalance}>
                  {noBalance
                    ? getCurrencySymbol(currency) + ' - '
                    : getLocalisedPrice(language, Number(debit_value), currency)}
                </StyledChip>
              )}
            </ImageCard>
          )}
        </PresentationCard>
      </Foreground>
    </Root>
  )
}

const RotatedShop = styled(VectorsDict.shop)`
  position: absolute;
  top: 0;
  left: calc(50% - 875px);
  transform: rotate(-15deg);
  opacity: 0.2;
`

const RotatedGameConsole = styled(VectorsDict.gameConsole)`
  transform: rotate(15deg);
  opacity: 0.2;
  position: absolute;
  top: -92px;
  left: calc(50% + 600px);
`

const RotatedShoppingBags = styled(VectorsDict.shoppingBags)`
  opacity: 0.2;
  position: absolute;
  transform: rotate(-15deg);
  left: calc(50% + 384px);
  bottom: 64px;
`

const RotatedFurniture = styled(VectorsDict.furniture)`
  position: absolute;
  transform: rotate(12deg);
  opacity: 0.2;
  left: calc(50% - 528px);
  bottom: 32px;
`

const RotatedIcons = styled.aside`
  height: 100%;
  width: 100%;
  color: white;
  position: absolute;
  left: 0%;
  overflow: hidden;
`

const VerticalIcons = styled.aside`
  height: 100%;
  width: 100%;
  color: white;
  position: absolute;
  left: 0%;
  overflow: hidden;
`

const VerticalShop = styled(VectorsDict.shop)`
  opacity: 0.2;
  position: absolute;
  left: calc(50% - 875px);
  bottom: 72px;
`

const VerticalFurniture = styled(VectorsDict.furniture)`
  opacity: 0.2;
  position: absolute;
  left: calc(50%);
  left: calc(50% - 528px);
  bottom: 128px;
`

const VerticalShoppingBags = styled(VectorsDict.shoppingBags)`
  opacity: 0.2;
  position: absolute;
  left: calc(50% + 284px);
  bottom: 64px;
`
const VerticalGameConsole = styled(VectorsDict.gameConsole)`
  opacity: 0.2;
  position: absolute;
  left: calc(50% + 720px);
  top: 72px;
`

const Root = styled.div<{ $baseColor?: string | null }>`
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
  position: relative;
  width: 100%;
  height: 368px;
  ${mediaQueries.to.breakpoint.desktop} {
    height: 312px;
  }
  background: linear-gradient(355deg, rgba(255, 255, 255, 0.3) 5%, rgba(255, 255, 255, 0) 85%),
    linear-gradient(
      0deg,
      ${({ $baseColor }) => `#${$baseColor}`},
      ${({ $baseColor }) => `#${$baseColor}`}
    );
  background: linear-gradient(355deg, rgba(255, 255, 255, 0.3) 5%, rgba(255, 255, 255, 0) 85%),
    linear-gradient(
      0deg,
      ${({ $baseColor }) => `${$baseColor}`},
      ${({ $baseColor }) => `${$baseColor}`}
    );
`

const Foreground = styled(WidthBoundary)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:only-child {
    margin-left: auto;
    margin-right: auto;
  }

  ${mediaQueries.to.breakpoint.desktop} {
    justify-content: center;
  }
`

const PresentationCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transform: translate(-20px, 32px);
`

const ShadowCard = styled.div`
  background: rgba(255, 255, 255, 0.6);
  border-radius: 32px;
  grid-column: 1;
  grid-row: 1;
  height: 260px;
  transform: rotate(-30deg);
  width: 400px;

  ${mediaQueries.to.breakpoint.desktop} {
    transform: rotate(-20deg);
  }
`

const GhostCard = styled.div`
  background: #ffffff;
  border-radius: 32px;
  grid-column: 1;
  grid-row: 1;
  height: 260px;
  transform: rotate(-15deg);
  width: 400px;

  ${mediaQueries.to.breakpoint.desktop} {
    transform: rotate(-10deg);
  }
`

const ImageCard = styled.div`
  grid-column: 1;
  grid-row: 1;
  margin: 18px;
  pointer-events: none;
  transform: rotate(-15deg);

  ${mediaQueries.to.breakpoint.desktop} {
    transform: rotate(-10deg);
  }
`

const SlotImage = styled.img<{ $faded?: boolean }>`
  border-radius: 16px;
  box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.2);
  height: 224px;
  outline-offset: -2px;
  outline: 2px solid rgba(255, 255, 255, 0.4);
  width: 360px;
  opacity: ${(p) => (p.$faded ? 0.4 : 1)};
`

const StyledChip = styled(Chip)<{ $noBalance?: boolean }>`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: ${(p) => (p.$noBalance ? colours.brand.purple : colours.prisma.black)};
`

const LoudHeader = styled.div`
  color: ${colours.prisma.white};
  font-family: 'Poppins';
  font-size: 120px;
  font-style: normal;
  font-weight: 500;
  line-height: 160px;
  text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);

  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`
