import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { TitleHeaderH2SCSS } from '@components/atoms/typography/css/TitleHeaderH2SCSS'

export const TitleHeaderH2S: React.FC<
  PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({ children, ...props }) => <Root {...props}>{children}</Root>

const Root = styled.div`
  ${TitleHeaderH2SCSS}
`
