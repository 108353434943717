import { HttpError } from '@errors/httpError'
import { api } from '@services/api'

export const checkOwnerMismatch = async (claimCode: string): Promise<boolean | undefined> => {
  try {
    await api.cardDetail(claimCode)
  } catch (e) {
    // For now 403 is thrown only if the card does not belong to the specific user (for logged in users)
    if (e instanceof HttpError && e.status === 403) {
      return true
    }
  }
}
