import { fetchApiOrThrow } from './fetchApiOrThrow'

export const fetchApiDataWithStatusOrThrow = async <T>(
  path: string,
  options?: RequestInit,
): Promise<{ status: number; data: T }> => {
  const response = await fetchApiOrThrow(path, options)
  const status = response.status
  const data = (await response.json()) as T

  return { status, data }
}

export type DataWithStatus<T> = { status: number; data: T }
