import { ForwardedRef, PropsWithChildren, Suspense, forwardRef } from 'react'
import { styled } from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

type Props = {
  type: 'light' | 'dark'
  tip: 'left' | 'right' | 'top' | 'bottom' | 'none' | 'all'
  title?: React.ReactNode
  icon?: boolean
  onClose?: () => void
  className?: string
}

const typePropertiesMap = {
  light: { bgColor: colours.prisma.white, color: colours.brand.dark },
  dark: { bgColor: colours.brand.dark, color: colours.prisma.white },
}

export const Tooltip = forwardRef(
  (
    { children, type, tip, title, icon, onClose, className }: PropsWithChildren<Props>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <aside className={className} ref={ref}>
      <TopBottomTipWrapper>
        {(tip === 'top' || tip === 'all') && <TipTop $type={type} id="tip-top" />}
        <LeftRightTipWrapper>
          {(tip === 'left' || tip === 'all') && <TipLeft $type={type} id="tip-left" />}
          <Root $type={type}>
            <Content>
              <Title>
                <Left>
                  {icon && (
                    <Suspense>
                      <IconComponents.circledInfo
                        fill={typePropertiesMap[type].color}
                        stroke={typePropertiesMap[type].color}
                      />
                    </Suspense>
                  )}
                  <div>{title}</div>
                </Left>
                <Action>
                  {onClose && (
                    <CloseWrapper>
                      <Suspense>
                        <IconComponents.closeOutline
                          fill={typePropertiesMap[type].color}
                          stroke={typePropertiesMap[type].color}
                        />
                      </Suspense>
                    </CloseWrapper>
                  )}
                </Action>
              </Title>
              {children}
            </Content>
          </Root>
          {(tip === 'right' || tip === 'all') && <TipRight $type={type} id="tip-right" />}
        </LeftRightTipWrapper>
        {(tip === 'bottom' || tip === 'all') && <TipBottom $type={type} id="tip-bottom" />}
      </TopBottomTipWrapper>
    </aside>
  ),
)

Tooltip.displayName = 'Tooltip'

const Root = styled(Fonts.BodyRegular)<{ $type: Props['type'] }>`
  position: relative;
  background: ${({ $type }) => typePropertiesMap[$type].bgColor};
  color: ${({ $type }) => typePropertiesMap[$type].color};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Content = styled.div``
const Action = styled.div``

const Left = styled.div`
  display: flex;
  gap: 4px;
`

const CloseWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: -8px;
  left: 8px;
  line-height: 0;
`

const Title = styled(Fonts.ButtonCTALabelLarge)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`

const TopBottomTipWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const LeftRightTipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const TipTop = styled.div<{ $type: Props['type'] }>`
  width: 16px;
  height: 8px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: ${({ $type }) => typePropertiesMap[$type].bgColor};
  z-index: 2;
`
const TipBottom = styled.div<{ $type: Props['type'] }>`
  width: 16px;
  height: 8px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  background-color: ${({ $type }) => typePropertiesMap[$type].bgColor};
  z-index: 2;
`
const TipLeft = styled.div<{ $type: Props['type'] }>`
  width: 8px;
  height: 16px;
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
  background-color: ${({ $type }) => typePropertiesMap[$type].bgColor};
  transform: translateX(1px);
  z-index: 2;
`

const TipRight = styled.div<{ $type: Props['type'] }>`
  width: 8px;
  height: 16px;
  clip-path: polygon(100% 50%, 0 100%, 0 0);
  background-color: ${({ $type }) => typePropertiesMap[$type].bgColor};
  transform: translateX(-1px);
  z-index: 2;
`
