import { TFunction } from 'i18next'
import * as y from 'yup'

export const getValidationSchema = (t: TFunction) =>
  y.object({
    email: y.string().email().required(t('forms.validation.emailRequired')),
    givenName: y
      .string()
      .min(3, t('forms.validation.atLeastCharacters', { count: 3 }))
      .required(t('forms.validation.nameRequired')),
    lastName: y
      .string()
      .min(3, t('forms.validation.atLeastCharacters', { count: 3 }))
      .required(t('forms.validation.lastNameRequired')),
    phoneNumber: y
      .string()
      .min(5, t('forms.validation.atLeastCharacters', { count: 5 }))
      .required(t('forms.validation.phoneNumberRequired')),
    phoneAreaCode: y
      .string()
      .min(1, t('forms.validation.atLeastCharacters', { count: 1 }))
      .required(t('forms.validation.phoneAreaCodeRequired')),
    termsAccepted: y
      .bool()
      .oneOf([true], t('forms.validation.termsMustBeAccepted'))
      .required(t('forms.validation.termsMustBeAccepted')),
  })
