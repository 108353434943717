import { FormikContextType } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type FCT = FormikContextType<unknown>

export const useTranslateFormErrors = ({ errors, touched, setFieldTouched }: FCT) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    function handler(_language: string) {
      setTimeout(() => {
        if (typeof errors === 'object' && typeof touched === 'object') {
          Object.keys(errors).forEach((fieldName) => {
            void setFieldTouched(fieldName)
          })
        }
      })
    }

    i18n.on('languageChanged', handler)

    return () => i18n.off('languageChanged', handler)
  }, [errors, setFieldTouched, touched, i18n])
}
