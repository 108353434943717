import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

export const SuccessButton: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Root>
      <Suspense>
        <IconComponents.checkmark fill={colours.prisma.white} stroke={colours.prisma.white} />
        {t('success')}
      </Suspense>
    </Root>
  )
}

const Root = styled(Fonts.ButtonCTALabelLarge)`
  align-items: center;
  background-color: ${colours.plant[110]};
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: ${colours.prisma.white};
  display: flex;
  gap: 8px;
  height: 48px;
  justify-content: center;
  padding: 0 16px;
  width: 100%;
`
