import { css } from 'styled-components'

export const MiscelaneousSpecialTitleBoldCSS = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-decoration: none;
  text-transform: none;
`
