import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { colours } from '@configs/colours'
import { useField } from 'formik'

import styled from 'styled-components'

type Props = {
  $accent?: string
  disabled?: boolean
  label?: React.ReactNode
  name: string
}

export const Checkbox: React.FCS<Props> = ({
  $accent = colours.brand.blue,
  className,
  disabled,
  name,
  label,
  ...props
}) => {
  const [field, meta] = useField(name)
  const hasError = !!meta.error

  return (
    <Root className={className}>
      <Label $error={hasError}>
        <Action>
          <Input
            $accent={$accent}
            $error={hasError}
            disabled={disabled}
            type="checkbox"
            {...props}
            {...field}
            checked={field.value}
          />
          {label}
        </Action>
      </Label>
      <Error>{hasError && meta.error}</Error>
    </Root>
  )
}

const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Error = styled.div`
  color: ${colours.error.red};
`

const Input = styled.input<{ $accent: string; $error: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  appearance: none;

  &[type='checkbox'] {
    border: 1px solid ${({ $error }) => ($error ? colours.error.red : colours.mist[200])};
  }

  &[type='checkbox']:checked {
    background-color: ${({ $accent }) => $accent};
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml,<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.25 1L4.5 11L0.75 7.25" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  &[type='checkbox']:disabled {
    background-color: ${colours.mist[200]};
  }
`

const Root = styled.div`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
  ${BodyRegularCSS};
`

const Label = styled.label<{ disabled?: boolean; $error?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  ${({ $error }) => $error && `color: ${colours.error.red}`};
`
