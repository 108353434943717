import { css } from 'styled-components'

export const ButtonTextLinkCSS = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
`
