import { Field, Form, Formik } from 'formik'

import { generatePath, useNavigate } from 'react-router-dom'

import * as y from 'yup'

import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { claimCodePaths } from '@configs/urls'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { HashType } from '@typeDeclarations/hashType'

const Page: React.FC = () => {
  const navigate = useNavigate()
  const { hashFlows } = useHashFlowContext()
  const { mainKey } = useMainKeyContext()
  const { entries } = Object
  const claimCodeType = entries(hashFlows).filter(([, flow]) => flow?.type === HashType.ClaimCode)
  const claimCodeHashes = claimCodeType.map((el) => el[0])
  const numberOfClaimCodes = claimCodeType.length

  const onSubmit = ({ manualClaimCode }: { manualClaimCode: string | undefined }) => {
    const cataloguePath = generatePath(claimCodePaths.welcome, { claimCode: manualClaimCode })
    navigate(cataloguePath)
  }

  return (
    <div>
      <Fonts.BodyRegular>Select or enter claim code</Fonts.BodyRegular>
      <Formik
        initialValues={{ manualClaimCode: mainKey ?? '' }}
        onSubmit={onSubmit}
        validationSchema={y.object({
          manualClaimCode: y.string().required('This field cannot be empty'),
        })}
      >
        <WithTranslateFormErrors>
          <Form>
            <TextInput description="Claim code" name="manualClaimCode" placeholder="Your code" />
            <PrimaryButton type="submit">Go to catalogue</PrimaryButton>
          </Form>
        </WithTranslateFormErrors>
      </Formik>
      <br />
      {numberOfClaimCodes > 0 && (
        <>
          <Fonts.BodyRegular>Or you can select from remembered ones:</Fonts.BodyRegular>
          <Formik
            initialValues={{ selectedCode: claimCodeHashes[0] }}
            onSubmit={({ selectedCode }) => {
              const cataloguePath = generatePath(claimCodePaths.welcome, {
                claimCode: selectedCode,
              })
              navigate(cataloguePath)
            }}
          >
            <WithTranslateFormErrors>
              <Form>
                <Field as="select" multiple={false} name="selectedCode">
                  {claimCodeHashes.map((hash) => (
                    <option key={hash} value={hash}>
                      {hash}
                    </option>
                  ))}
                </Field>
                <PrimaryButton type="submit">Go to catalogue</PrimaryButton>
              </Form>
            </WithTranslateFormErrors>
          </Formik>
        </>
      )}
    </div>
  )
}

export default Page
