import { TFunction } from 'i18next'
import * as y from 'yup'

import { CartItem, ChoiceCardItem } from '@context/cart'

export const getValidationSchema = (t: TFunction) =>
  y.object({
    cards: y.array().of(
      y.object({
        amount: y.number().min(1),
        id: y.number(),
        quantity: y.string(),
      }),
    ),
    choiceCardInput: y.string(),
    choiceCardsToUse: y.array(),
    email: y
      .string()
      .email()
      .test((s) => s?.includes('.') && !s.endsWith('.'))
      .required(),
    paymentMethod: y
      .string()
      .when(['cards', 'choiceCardsToUse'], ([cards, choiceCardsToUse], schema) => {
        const c = (cards as CartItem[]).reduce((a, c) => a + c.quantity * c.amount, 0)
        const v = (choiceCardsToUse as ChoiceCardItem[]).reduce((a, c) => a + c.debit_value, 0)

        return c - v > 0 ? schema.required(t('forms.validation.paymentMethodRequired')) : schema
      }),
  })
