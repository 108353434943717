import { styled } from 'styled-components'

import { DivProps } from '@typeDeclarations/elements/div'

import { FeeInfoStrip } from '../FeeInfoStrip/FeeInfoStrip'

type GiftCardProps = {
  imageUrl?: string
  title?: string
  description?: string
  currency?: string
  isFeeDeducted?: boolean
  isFeePaid?: boolean
  feeFlat?: number | null
  feePercentage?: number | null
}

const MAX_DESCRIPTION_LENGTH = 150

export const GiftCardVertical: React.FC<GiftCardProps & DivProps> = ({
  currency,
  description,
  imageUrl,
  isFeeDeducted,
  isFeePaid,
  title,
  feeFlat,
  feePercentage,
  ...props
}) => (
  <Root {...props}>
    <Content>
      {imageUrl && (
        <CardImageWrapper>
          <CardImage alt={title + ' card image'} src={imageUrl} />
          <StyledInfoStrip
            currency={currency}
            feeFlat={feeFlat}
            feePercentage={feePercentage}
            isFeeDeducted={isFeeDeducted}
            isFeePaid={isFeePaid}
          />
        </CardImageWrapper>
      )}
    </Content>
    <Content>
      {title && <CardTitle>{title}</CardTitle>}
      {description && (
        <CardDescription>
          {description.length > MAX_DESCRIPTION_LENGTH
            ? description.substring(0, MAX_DESCRIPTION_LENGTH) + '...'
            : description}
        </CardDescription>
      )}
    </Content>
  </Root>
)

const CardImageWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`

const Root = styled.section`
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  padding: 8px;
  width: 100%;
`

const Content = styled.div`
  flex-basis: 50%;
`

const CardImage = styled.img`
  border-radius: 12px;
  max-width: 100%;
  width: 100%;
  aspect-ratio: 1.6;
`

const CardTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
`

const CardDescription = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
  word-break: break-all;
  /* */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 3lh;
`

const StyledInfoStrip = styled(FeeInfoStrip)`
  position: absolute;
  left: 16px;
  bottom: 16px;
`
