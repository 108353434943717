import { lazy } from 'react'

export const Arch = lazy(() => import(`@components/atoms/icons/Arch`))
export const ArrowLeft = lazy(() => import(`@components/atoms/icons/ArrowLeft.tsx`))
export const ArrowRight = lazy(() => import(`@components/atoms/icons/ArrowRight.tsx`))
export const Bin = lazy(() => import(`@components/atoms/icons/Bin`))
export const Cart = lazy(() => import(`@components/atoms/icons/Cart`))
export const Checkmark = lazy(() => import(`@components/atoms/icons/Checkmark`))
export const Chevron = lazy(() => import(`@components/atoms/icons/Chevron`))
export const CircledPlus = lazy(() => import(`@components/atoms/icons/CircledPlus`))
export const CloseOutline = lazy(() => import(`@components/atoms/icons/CloseOutline`))
export const Cross = lazy(() => import(`@components/atoms/icons/Cross`))
export const EmptyCart = lazy(() => import(`@components/atoms/icons/EmptyCart`))
export const Envelope = lazy(() => import(`@components/atoms/icons/Envelope`))
export const ExclamationMark = lazy(() => import(`@components/atoms/icons/ExclamationMark`))
export const ExternalResource = lazy(() => import(`@components/atoms/icons/ExternalResource`))
export const EyeClosed = lazy(() => import(`@components/atoms/icons/EyeClosed`))
export const EyeOpen = lazy(() => import(`@components/atoms/icons/EyeOpen`))
export const HeadphonesFace = lazy(() => import(`@components/atoms/icons/HeadphonesFace`))
export const Info = lazy(() => import(`@components/atoms/icons/Info`))
export const Minus = lazy(() => import(`@components/atoms/icons/Minus`))
export const Pen = lazy(() => import(`@components/atoms/icons/Pen`))
export const Plus = lazy(() => import(`@components/atoms/icons/Plus`))
export const Shop = lazy(() => import(`@components/atoms/icons/Shop`))
export const Tada = lazy(() => import(`@components/atoms/icons/Tada`))
export const Warning = lazy(() => import(`@components/atoms/icons/Warning`))
export const Megaphone = lazy(() => import(`@components/atoms/icons/Megaphone`))
export const BigSmile = lazy(() => import(`@components/atoms/icons/BigSmile`))
export const Bulb = lazy(() => import(`@components/atoms/icons/Bulb`))
export const CircledInfo = lazy(() => import(`@components/atoms/icons/CircledInfo`))
export const FilledCheckmark = lazy(() => import(`@components/atoms/icons/FilledCheckmark`))
export const Logout = lazy(() => import(`@components/atoms/icons/Logout`))
export const CardStack = lazy(() => import(`@components/atoms/icons/CardStack`))
export const Contract = lazy(() => import(`@components/atoms/icons/Contract`))
export const Receipt = lazy(() => import(`@components/atoms/icons/Receipt`))
export const Gift = lazy(() => import(`@components/atoms/icons/Gift`))
export const EnvelopeOpen = lazy(() => import(`@components/atoms/icons/EnvelopeOpen`))
export const CreditCard = lazy(() => import(`@components/atoms/icons/CreditCard`))
export const PaperPlane = lazy(() => import(`@components/atoms/icons/PaperPlane`))
export const OpenCircle = lazy(() => import(`@components/atoms/icons/OpenCircle`))
export const Wallet = lazy(() => import(`@components/atoms/icons/Wallet`))
export const Copy = lazy(() => import(`@components/atoms/icons/Copy`))
export const Share = lazy(() => import(`@components/atoms/icons/Share`))
export const Link = lazy(() => import(`@components/atoms/icons/Link`))
export const People = lazy(() => import(`@components/atoms/icons/People`))
export const Letter = lazy(() => import(`@components/atoms/icons/Letter`))
export const Whatsapp = lazy(() => import(`@components/atoms/icons/Whatsapp`))
export const Twitter = lazy(() => import(`@components/atoms/icons/Twitter`))
