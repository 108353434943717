export const concatenate = (args: unknown[], options: { separator?: string } = {}) => {
  const { separator } = options
  const concatenator = (a: string, c: unknown, idx: number) => {
    return typeof c === 'string' || typeof c === 'number'
      ? a + (typeof separator === 'string' && idx !== 0 ? separator : '') + c
      : a
  }

  return args.filter((c) => typeof c === 'string' || typeof c === 'number').reduce(concatenator, '')
}
