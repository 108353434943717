import { useFormikContext } from 'formik'
import { PropsWithChildren } from 'react'

import { useTranslateFormErrors } from '@hooks/useTranslateFormErrors'

export const WithTranslateFormErrors: React.FC<PropsWithChildren> = ({ children }) => {
  const context = useFormikContext()
  useTranslateFormErrors(context)

  return <>{children}</>
}
