import { lazy } from 'react'

import { Vectors } from '@typeDeclarations/components/atoms/vectors'

export const VectorsDict = {
  [Vectors.Shop]: lazy(() => import(`@components/atoms/vectors/Shop.tsx`)),
  [Vectors.Furniture]: lazy(() => import(`@components/atoms/vectors/Furniture.tsx`)),
  [Vectors.ShoppingBags]: lazy(() => import(`@components/atoms/vectors/ShoppingBags.tsx`)),
  [Vectors.GameConsole]: lazy(() => import(`@components/atoms/vectors/GameConsole.tsx`)),
  [Vectors.Gift]: lazy(() => import(`@components/atoms/vectors/Gift.tsx`)),
  [Vectors.Monitor]: lazy(() => import(`@components/atoms/vectors/Monitor.tsx`)),
  [Vectors.Hourglass]: lazy(() => import(`@components/atoms/vectors/Hourglass.tsx`)),
  [Vectors.SmileHappy]: lazy(() => import(`@components/atoms/vectors/SmileHappy.tsx`)),
  [Vectors.SmileIndifferent]: lazy(() => import(`@components/atoms/vectors/SmileIndifferent.tsx`)),
  [Vectors.SmileSad]: lazy(() => import(`@components/atoms/vectors/SmileSad.tsx`)),
  [Vectors.SmileSmile]: lazy(() => import(`@components/atoms/vectors/SmileSmile.tsx`)),
  [Vectors.SmileUnhappy]: lazy(() => import(`@components/atoms/vectors/SmileUnhappy.tsx`)),
  [Vectors.Celebration]: lazy(() => import(`@components/atoms/vectors/Celebration.tsx`)),
}
