const prefix = '[Currency formatter]'

export const getLocalisedPrice = (language: string, price?: number, currency?: string): string => {
  if (typeof price !== 'number' || !currency) return '---'

  try {
    const formatter = new Intl.NumberFormat(language, { style: 'currency', currency })

    return formatter.format(price)
  } catch (e) {
    console.error(`${prefix} Error parsing localised currency: `, e)
    console.warn(
      `${prefix} Cannot parse price with provided data. Price ${price}, currency: ${currency}`,
    )
    return '---'
  }
}

export const localisedPriceFactory = (language: string, currency?: string) => (price?: number) =>
  getLocalisedPrice(language, price, currency)
