import { FormikErrors } from 'formik'
import { PropsWithChildren } from 'react'

import styled from 'styled-components'

import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { LimitsViolations } from '@utils/evaluateLimits'
import { mediaQueries } from '@utils/mediaQueries'

import { CardsIssuingTakesLongerBanner } from './CardsIssuingTakesLongerBanner'
import { GenericErrorBanner } from './GenericErrorBanner'
import { NoPaymentMethodBanner } from './NoPaymentMethodBanner'
import { ViolationBanner } from './ViolationBanner'

type Props = {
  currency: undefined | string
  dismissViolationError: undefined | (() => void)
  error: string | boolean | undefined
  errors: FormikErrors<CheckoutFormikValues>
  orderError: undefined | boolean
  paymentMethodBannerClosed: undefined | boolean
  setError: ((arg: boolean) => void) | undefined
  setOrderError: undefined | ((arg: boolean) => void)
  setPaymentMethodBannerClosed: undefined | ((arg: boolean) => void)
  takesLongerWarning: boolean | undefined
  touched: undefined | boolean
  violationError: undefined | LimitsViolations
}

export const TabletBannersGroup: React.FC<PropsWithChildren<Props>> = ({
  currency,
  dismissViolationError,
  error,
  errors,
  orderError,
  paymentMethodBannerClosed,
  setError,
  setOrderError,
  setPaymentMethodBannerClosed,
  takesLongerWarning,
  touched,
  violationError,
}) => (
  <Root>
    {violationError && (
      <TabletBannerWrapper>
        <ViolationBanner
          currency={currency}
          dismissViolationError={dismissViolationError}
          violationError={violationError}
        />
      </TabletBannerWrapper>
    )}
    {(orderError || error) && (
      <TabletBannerWrapper>
        <GenericErrorBanner error={error} setError={setError} setOrderError={setOrderError} />
      </TabletBannerWrapper>
    )}
    {touched && errors.paymentMethod && !paymentMethodBannerClosed && (
      <TabletBannerWrapper>
        <NoPaymentMethodBanner
          setError={setError}
          setPaymentMethodBannerClosed={setPaymentMethodBannerClosed}
        />
      </TabletBannerWrapper>
    )}
    {takesLongerWarning && (
      <TabletBannerWrapper>
        <CardsIssuingTakesLongerBanner />
      </TabletBannerWrapper>
    )}
  </Root>
)

const TabletBannerWrapper = styled.div`
  margin-bottom: 24px;
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

const Root = styled.div``
