import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { ToPay } from '@components/molecules/priceSummary/ToPay'
import { colours } from '@configs/colours'

import { useCheckoutContext } from '@hooks/useCheckout'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  currency?: string
  actionDisabled?: boolean
  actionHandler: () => void
  text?: string
  type?: 'submit' | 'button'
  loading?: boolean
  iconRight?: Icons
}

export const BottomActionsSection: React.FC<PropsWithChildren<Props>> = ({
  currency,
  actionDisabled,
  actionHandler,
  children,
  loading,
  text,
  type,
  iconRight,
}) => {
  const { amountFromChoiceCards, paymentProcessingFee, priceFromCards } = useCheckoutContext()

  return (
    <Root>
      <VisibleToMobileL>
        <ToPay
          amountFromChoiceCards={amountFromChoiceCards}
          currency={currency}
          paymentProcessingFee={paymentProcessingFee}
          priceFromCards={priceFromCards}
        />
      </VisibleToMobileL>
      {children}
      {loading ? (
        <LoadingButton />
      ) : (
        <PrimaryButton
          disabled={actionDisabled}
          iconRight={iconRight}
          onClick={actionHandler}
          type={type ?? 'button'}
        >
          {text}
        </PrimaryButton>
      )}
    </Root>
  )
}

const VisibleToMobileL = styled.div`
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
`
const Root = styled.div`
  padding: 16px;
  position: sticky;
  bottom: 0;
  min-height: 120px;
  box-sizing: border-box;
  box-shadow: 0px -24px 24px 0px rgba(255, 255, 255, 1);

  ${mediaQueries.to.breakpoint.mobileL} {
    background: ${colours.prisma.white};
  }

  ${mediaQueries.from.breakpoint.tablet} {
    display: none;
  }
`
