import { signOut } from 'aws-amplify/auth'
import { useFeatureFlag } from 'configcat-react'
import { Fragment, Suspense, lazy, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { envs } from '@configs/envs'
import { datadogRum } from '@datadog/browser-rum'
import { useUserContext } from '@hooks/useUserContext'

const ConfirmSignUp = lazy(() =>
  import('@components/organisms/modals/signUp/ConfirmSignUp').then(({ ConfirmSignUp }) => ({
    default: ConfirmSignUp,
  })),
)
const SignUp = lazy(() =>
  import('@components/organisms/modals/signUp/SignUp').then(({ SignUp }) => ({ default: SignUp })),
)
const SignIn = lazy(() =>
  import('@components/organisms/modals/signIn/SignIn').then(({ SignIn }) => ({ default: SignIn })),
)
const ForgotPasswordCode = lazy(() =>
  import('@components/organisms/modals/ForgotPassword/ForgotPasswordCode').then(
    ({ ForgotPasswordCode }) => ({ default: ForgotPasswordCode }),
  ),
)
const ForgotPassword = lazy(() =>
  import('@components/organisms/modals/ForgotPassword/ForgotPassword').then(
    ({ ForgotPassword }) => ({
      default: ForgotPassword,
    }),
  ),
)

export const DevActions: React.FC = () => {
  const { value: accountCreationEnabled, loading: accountCreationEnabledLoading } = useFeatureFlag(
    'accountcreation',
    false,
  )

  const { isLoggedIn, setIsLoggedIn, setEmail } = useUserContext()
  const { t } = useTranslation()
  const { email } = useUserContext()

  const [devButtonsOpen, setDevButtonsOpen] = useState(false)

  const [signUpOpen, setSignUpOpen] = useState(false)
  const [confirmSignUpOpen, setConfirmSignUpOpen] = useState(false)
  const [signInOpen, setSignInOpen] = useState(false)
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false)
  const [forgotPasswordCodeOpen, setForgotPasswordCodeOpen] = useState(false)

  useEffect(() => {
    if (!accountCreationEnabledLoading)
      datadogRum.addFeatureFlagEvaluation('accountCreationEnabled', accountCreationEnabled)
  }, [accountCreationEnabled, accountCreationEnabledLoading])

  return (
    <Fragment>
      {devButtonsOpen && (
        <Fragment>
          <EmailWrapper>{email}</EmailWrapper>
          <button
            disabled={!accountCreationEnabled || isLoggedIn}
            onClick={() => {
              setSignUpOpen(true)
            }}
          >
            sign up
          </button>
          <button
            disabled={isLoggedIn}
            onClick={() => {
              setConfirmSignUpOpen(true)
            }}
          >
            confirm sign up
          </button>
          <button
            disabled={isLoggedIn}
            onClick={() => {
              setSignInOpen(true)
            }}
          >
            log in
          </button>
          <button
            disabled={!isLoggedIn}
            onClick={() => {
              signOut()
                .then(() => {
                  setIsLoggedIn(false)
                  setEmail('')
                })
                .catch((e: unknown) => {
                  console.log('Error signing out', e)
                })
            }}
          >
            log out
          </button>
          <button
            disabled={isLoggedIn}
            onClick={() => {
              setForgotPasswordOpen(true)
            }}
          >
            forgot password
          </button>
          <button
            disabled={isLoggedIn}
            onClick={() => {
              setForgotPasswordCodeOpen(true)
            }}
          >
            forgot password code
          </button>
        </Fragment>
      )}
      <Actions>
        {envs.VITE_DOTENV === 'test' && (
          <DevButtons
            onClick={() => {
              setDevButtonsOpen((prev) => !prev)
            }}
          >
            {t('development')}
          </DevButtons>
        )}
      </Actions>
      {signUpOpen && (
        <Suspense>
          <SignUp
            onClose={() => {
              setSignUpOpen(false)
            }}
            open={signUpOpen}
          />
        </Suspense>
      )}
      {confirmSignUpOpen && (
        <Suspense>
          <ConfirmSignUp
            onClose={() => {
              setConfirmSignUpOpen(false)
            }}
            open={confirmSignUpOpen}
          />
        </Suspense>
      )}
      {signInOpen && (
        <Suspense>
          <SignIn
            onClose={() => {
              setSignInOpen(false)
            }}
            open={signInOpen}
          />
        </Suspense>
      )}
      {forgotPasswordOpen && (
        <Suspense>
          <ForgotPassword
            onClose={() => {
              setForgotPasswordOpen(false)
            }}
            open={forgotPasswordOpen}
          />
        </Suspense>
      )}
      {forgotPasswordCodeOpen && (
        <Suspense>
          <ForgotPasswordCode
            onClose={() => {
              setForgotPasswordCodeOpen(false)
            }}
            open={forgotPasswordCodeOpen}
          />
        </Suspense>
      )}
    </Fragment>
  )
}

const DevButtons = styled(Fonts.ButtonCTALabelLarge)`
  padding: 0 24px;
  cursor: pointer;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmailWrapper = styled.div`
  word-break: break-all;
`
