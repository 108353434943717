import React from 'react'
import { Navigate, generatePath, useParams } from 'react-router-dom'

import { CatalogueOrganism } from '@components/organisms/Catalogue/Catalogue'
import { claimCodePaths } from '@configs/urls'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useProductsContext } from '@hooks/useProducts'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'

const CataloguePage: React.FC = () => {
  const { claimCode = '' } = useParams()
  const { mainKey } = useMainKeyContext()
  const { products, productsLoading, productsErrorCode } = useProductsContext()

  if (
    productsErrorCode === HttpCodes.get(HttpCodeNames.Forbidden) ||
    productsErrorCode === HttpCodes.get(HttpCodeNames.Unauthroized) ||
    mainKey !== claimCode
  ) {
    return <Navigate to={generatePath(claimCodePaths.root, { claimCode })} />
  }

  return (
    <CatalogueOrganism
      products={products}
      productsErrorCode={productsErrorCode}
      productsLoading={productsLoading}
    />
  )
}

export default CataloguePage
