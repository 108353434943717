import i18n from '@i18n'

export const authMessages = {
  'Password attempts exceeded': i18n.t('auth.Password attempts exceeded'),
  'Incorrect username or password.': i18n.t('auth.Incorrect username or password.'),
  'Username/client id combination not found.': i18n.t(
    'auth.Username/client id combination not found.',
  ),
  'Invalid verification code provided, please try again.': i18n.t(
    'auth.Invalid verification code provided, please try again.',
  ),
  'Attempt limit exceeded, please try after some time.': i18n.t(
    'auth.Attempt limit exceeded, please try after some time.',
  ),
  'An account with the given email already exists.': i18n.t(
    'auth.An account with the given email already exists.',
  ),
  'password is required to signIn': i18n.t('auth.password is required to signIn'),
  'username is required to signIn': i18n.t('auth.username is required to signIn'),
  'username is required to signUp': i18n.t('auth.username is required to signUp'),
  'password is required to signUp': i18n.t('auth.password is required to signUp'),
  'Invalid email address format.': i18n.t('auth.Invalid email address format.'),
  'Cannot reset password for the user as there is no registered/verified email or phone_number':
    i18n.t(
      'auth.Cannot reset password for the user as there is no registered/verified email or phone_number',
    ),
}
