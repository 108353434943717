import { PropsWithChildren, Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

type Props = {
  onClose?: () => void
}

export const BasicErrorComponent: React.FC<PropsWithChildren<Props>> = ({
  children,
  onClose,
  ...props
}) => (
  <Root $clickable={!!onClose} onClick={() => onClose?.()} {...props}>
    {children}
    {!!onClose && (
      <Suspense>
        <IconComponents.cross height="12" stroke={colours.error.red} width="12" />
      </Suspense>
    )}
  </Root>
)

const Root = styled(Fonts.BodyRegular)<{ $clickable?: boolean }>`
  align-items: center;
  color: ${colours.error.red};
  cursor: ${(p) => (p.$clickable ? 'pointer' : 'unset')};
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 16px;
  width: fit-content;
`
