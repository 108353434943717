import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'

type Props = { count: number; sum: number; currency?: string }

export const ChoiceCardsSummary: React.FCS<Props> = ({ className, count, sum, currency }) => {
  const { i18n, t } = useTranslation()

  if (!currency) return null

  const price = getLocalisedPrice(i18n.language, sum, currency)

  return (
    <Root className={className}>
      <Amount>
        {t('amount')} <Count>({count})</Count>
      </Amount>
      <Price>{price}</Price>
    </Root>
  )
}

const Root = styled.div`
  background-color: ${colours.blues[10]};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  text-transform: uppercase;
`

const Count = styled.span`
  color: ${colours.brand.blue};
`

const Amount = styled(Fonts.TagText)`
  text-transform: uppercase;
`

const Price = styled(Fonts.ButtonTextLink)`
  color: ${colours.brand.blue};
`
