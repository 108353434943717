import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonTextLinkCSS } from '@components/atoms/typography/css'
import { colours } from '@configs/colours'

export const ReferLink = styled(NavLink)`
  ${ButtonTextLinkCSS};
  color: ${colours.brand.blue};
  display: block;
  margin: 8px;
  text-align: center;
  text-decoration: underline;
  width: 100%;
`
