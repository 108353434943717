import React, { PropsWithChildren, createContext, useEffect, useReducer } from 'react'

import { Flows } from '@services/api.types'
import { FeePaymentOptions } from '@typeDeclarations/feePaymentOption'
import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { HashType } from '@typeDeclarations/hashType'

import { getLocalStorageKey } from '@utils/getLocalStorageKey'

type HashData = {
  claimCodeDelayMinutes?: number | null
  feePaymentOption?: null | FeePaymentOptions
  flow: Flows
  hash: string
  maxPurchaseAmount?: number | null
  maxPurchaseQuantity?: number | null
  prefillEmail?: string
  type: HashType
  skip_claim_step?: boolean | null
}

type HashFlowState = Record<string, HashData>
type ReceiveFlowContext = {
  hashFlows: Partial<Record<string, HashData>>
  dispatchHashFlows: (value: Actions) => void
  setEmailDelayInfo: (shareKey: string, delayMinutes?: number | null) => void
}

type CreateHashFlowPayload = HashData

type UpdateHashFlowPayload = Partial<Omit<CreateHashFlowPayload, 'hash'>> &
  Pick<CreateHashFlowPayload, 'hash'>

type ClearHashFlowPayload = { hash: string }

type Actions =
  | { type: HashFlowActions.SetHashFlow; payload: CreateHashFlowPayload }
  | { type: HashFlowActions.ClearHashFlow; payload: ClearHashFlowPayload }
  | { type: HashFlowActions.UpdateHashFlow; payload: UpdateHashFlowPayload }

const hashFlowReducer = (state: HashFlowState, action: Actions) => {
  switch (action.type) {
    case HashFlowActions.SetHashFlow:
      return {
        ...state,
        [action.payload.hash]: { ...action.payload },
      }
    case HashFlowActions.ClearHashFlow:
      return { ...state }
    case HashFlowActions.UpdateHashFlow:
      return {
        ...state,
        [action.payload.hash]: { ...state[action.payload.hash], ...action.payload },
      }
    default:
      return { ...state }
  }
}

export const HashFlowContext = createContext<ReceiveFlowContext>({
  hashFlows: {},
  dispatchHashFlows: () => ({}),
  setEmailDelayInfo: () => {},
})

type Props = {
  init?: HashFlowState
}

export const ReceiveFlowContextProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  init,
}) => {
  const initialState = init ?? {}

  const initializer = (initialValue = initialState) => {
    const localStorageKey = localStorage.getItem(getLocalStorageKey('hashes'))
    return localStorageKey === null ? initialValue : (JSON.parse(localStorageKey) as HashFlowState)
  }

  const [hashFlows, dispatchHashFlows] = useReducer(hashFlowReducer, initialState, initializer)

  const setEmailDelayInfo = (shareKey: string, delayMinutes?: number | null) => {
    if (typeof delayMinutes === 'number' && delayMinutes > 0)
      dispatchHashFlows({
        type: HashFlowActions.UpdateHashFlow,
        payload: { hash: shareKey, claimCodeDelayMinutes: delayMinutes },
      })
  }

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('hashes'), JSON.stringify(hashFlows))
  }, [hashFlows])

  return (
    <HashFlowContext.Provider value={{ hashFlows, dispatchHashFlows, setEmailDelayInfo }}>
      {children}
    </HashFlowContext.Provider>
  )
}
