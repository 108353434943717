import { css } from 'styled-components'

export const ButtonCTALabelLargeCSS = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: none;
`
