import { PropsWithChildren, Suspense } from 'react'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { colours } from '@configs/colours'
import { Icons } from '@typeDeclarations/components/atoms/icons'

import { DivProps } from '@typeDeclarations/elements/div'

type ComponentProps = {
  bgColor?: string
  icon: Icons
  isHighlighted?: boolean
  isDisabled?: boolean
  mainColor?: string
  mainDisabledColor?: string
  bgDisabledColor?: string
}

export const HighlightableIcon: React.FCS<PropsWithChildren<DivProps & ComponentProps>> = ({
  icon,
  isHighlighted,
  isDisabled,
  mainColor = colours.prisma.black,
  bgColor = colours.prisma.white,
  mainDisabledColor = colours.mist[400],
  bgDisabledColor = colours.mist[900],
  ...props
}) => {
  const ChosenIcon = IconComponents[icon]

  const pathOutline = isDisabled
    ? isHighlighted
      ? mainDisabledColor
      : mainDisabledColor
    : isHighlighted
    ? bgColor
    : mainColor

  const background = isDisabled
    ? isHighlighted
      ? bgDisabledColor
      : bgColor
    : isHighlighted
    ? mainColor
    : bgColor

  return (
    <Root $bgColor={background} {...props}>
      <Suspense>
        <ChosenIcon fill={pathOutline} stroke={pathOutline} />
      </Suspense>
    </Root>
  )
}

const Root = styled.div<{ $bgColor?: string }>`
  align-items: center;
  background-color: ${(p) => (p.$bgColor ? p.$bgColor : colours.prisma.white)};
  border-radius: 16px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 48px;
`
