import fetchWrapper from 'fetch-retry'

import { apiBaseURL } from '@configs/urls'

const fetchWithRetry = fetchWrapper(window.fetch)

export const fetchApi = async (path: string, options?: RequestInit): Promise<Response> => {
  const config = {
    method: 'GET',
    ...options,
    headers: { ...options?.headers },
  }

  return fetchWithRetry(`${apiBaseURL}${path}`, config)
}
