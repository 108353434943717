import JsBarcode from 'jsbarcode'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useFeatureFlag } from 'configcat-react'

import { AboutFooter } from '@components/atoms/About/About'
import { Chip } from '@components/atoms/Chip/Chip'
import { WidthBoundary, WidthBoundaryCSS } from '@components/atoms/Content/WidthBoundary'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import {
  ButtonTextLinkCSS,
  TitleHeaderH1LCSS,
  TitleHeaderH1SCSS,
} from '@components/atoms/typography/css'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { ButtonCTALabelSmallCSS } from '@components/atoms/typography/css/ButtonCTALabelSmallCSS'
import { IntroSubtextSmallCSS } from '@components/atoms/typography/css/IntroSubtextSmallCSS'
import { Fonts } from '@components/atoms/typography/Fonts'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { CardStack } from '@components/molecules/CardStack/CardStack'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours } from '@configs/colours'
import { api } from '@services/api'
import { ActivateInfo } from '@services/api.types'
import { rotate } from '@styles/animations/rotate'
import { spin } from '@styles/animations/spin'
import { afterDelay } from '@utils/afterDelay'
import { delay } from '@utils/delay'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'
import { formattedDate } from '@utils/formatter'

const COOLDOWN_TIME = 4000

const Page: React.FC = () => {
  const { shareKey } = useParams()
  const { i18n, t } = useTranslation()
  const { value: issuingCardDelayedInfo } = useFeatureFlag('issuingCardDelayedInfo', false)

  const [isError, setIsError] = useState(false)
  const [processing, setProcessing] = useState(true)
  const [card, setCard] = useState<ActivateInfo>()
  const [redirecting, setRedirecting] = useState(false)
  const [copied, setCopied] = useState(false)

  const copyHandler = async (code?: string | null) => {
    if (code) {
      void navigator.clipboard.writeText(code)
      setCopied(true)
      await afterDelay(() => setCopied(false), COOLDOWN_TIME / 2)
    }
  }

  const submitHandler = useCallback(async (cardNumber?: string, count?: number): Promise<void> => {
    if (typeof count !== 'number') count = 0
    if (!cardNumber) throw new Error('Card number not provided')

    try {
      setIsError(false)
      setProcessing(true)
      if (count === 0) await delay(COOLDOWN_TIME / 4)
      count++

      const { data, status } = await api.activate(cardNumber)

      if (data.redeem_url) {
        setRedirecting(true)
        await delay(COOLDOWN_TIME)
        window.location.href = data.redeem_url
      } else {
        setCard(data)
      }

      if (status === 202) {
        await new Promise((resolve) => setTimeout(() => resolve(null), COOLDOWN_TIME))
        return submitHandler(cardNumber)
      }
    } catch {
      setIsError(true)
    } finally {
      setProcessing(false)
    }
  }, [])

  useEffect(() => {
    void submitHandler(shareKey)
  }, [shareKey, submitHandler])

  useEffect(() => {
    if (processing) return
    const cardNumber = card?.card_number

    const barcodeLargeElement = document.getElementById('barcode-large')
    const barcodeSmallElement = document.getElementById('barcode-small')

    if (barcodeLargeElement && typeof cardNumber === 'string')
      JsBarcode('#barcode-large', cardNumber, {
        format: 'code128',
        displayValue: false,
        background: colours.mist[100],
        width: 2.8,
      })

    if (barcodeSmallElement && typeof cardNumber === 'string')
      JsBarcode('#barcode-small', cardNumber, {
        format: 'code128',
        displayValue: false,
        width: 1.6,
      })
  }, [card?.card_number, processing])

  return (
    <Root>
      <Spacer />
      {processing && (
        <FullHeight>
          <TitleH1L>{t('sitTight')}</TitleH1L>
          <Circle>
            <Suspense>
              <Rotating>
                <OpenCircle />
              </Rotating>
            </Suspense>
          </Circle>
          {!redirecting && (
            <WidthBoundary>
              <TitleH1S>{t('yourCardIsBeingIssued')}</TitleH1S>
            </WidthBoundary>
          )}
          {redirecting && (
            <WidthBoundary>
              <TitleH1S>{t('redirecting')}</TitleH1S>
              <Subtitle>{t('redirectingDescription')}</Subtitle>
            </WidthBoundary>
          )}
        </FullHeight>
      )}
      {!processing && isError && (
        <FullHeight>
          <TitleH1L>{t('whoops')}</TitleH1L>
          <Circle>
            <Suspense>
              <VectorsDict.monitor fill={colours.brand.blue} height="120" width="120" />
              <LeftEye>
                <IconComponents.plus
                  fill={colours.brand.green}
                  height="48"
                  stroke={colours.brand.green}
                  width="48"
                />
              </LeftEye>
              <RightEye>
                <IconComponents.plus
                  fill={colours.brand.green}
                  height="48"
                  stroke={colours.brand.green}
                  width="48"
                />
              </RightEye>
            </Suspense>
          </Circle>
          <ErrorDescriptionWrapper>
            <TitleH1S>{t('somethingWentWrong')}</TitleH1S>
            <Subtitle>{t('difficultiesActivatingCard')}</Subtitle>
          </ErrorDescriptionWrapper>
          <div>
            <PrimaryButton onClick={() => submitHandler(shareKey)}>
              <RetryTextWrapper>{t('retryIssuigCard')}</RetryTextWrapper>
            </PrimaryButton>
          </div>
          <BlueLink onClick={() => window.Trengo?.Api?.Widget?.open?.('chat')}>
            {t('needHelp')}
          </BlueLink>
        </FullHeight>
      )}
      {!processing && card && !isError && (
        <FullHeight>
          <ToDesktop>
            <TitleH1S>{t('yourCard')}</TitleH1S>
          </ToDesktop>
          <TwoSides>
            <InfoFrame>
              <FromDesktop>
                <ActivatedTitleH1S>{t('yourCard')}</ActivatedTitleH1S>
              </FromDesktop>
              {card.product?.description_short && (
                <div>
                  <Title>{t('descriptionShort')}</Title>
                  <Regular>
                    <Markdown className="__markdown">{card.product.description_short}</Markdown>
                  </Regular>
                </div>
              )}
              {card.product?.description_long && (
                <div>
                  <Title>{t('descriptionLong')}</Title>
                  <Regular>
                    <Markdown className="__markdown">{card.product.description_long}</Markdown>
                  </Regular>
                </div>
              )}
              {card.product?.spending_text && (
                <div>
                  <Title>{t('spending')}</Title>
                  <Regular>
                    <Markdown className="__markdown">{card.product.spending_text}</Markdown>
                  </Regular>
                </div>
              )}
              {card.product?.expiration_text && (
                <div>
                  <Title>{t('expiration')}</Title>
                  <Regular>
                    <Markdown className="__markdown">{card.product.expiration_text}</Markdown>
                  </Regular>
                </div>
              )}
              {card.expiry_date && !card.product_expiry && (
                <div>
                  <Title>{t('validity')}</Title>
                  <Regular>{formattedDate(card.expiry_date ?? null)}</Regular>
                </div>
              )}
              {card.product_expiry && (
                <div>
                  <Title>{t('validity')}</Title>
                  <Regular>
                    {/* only for runa cards */}
                    {t('productExpiry', {
                      productExpiry: formattedDate(card.product_expiry ?? null),
                      linkExpiry: formattedDate(card.expiry_date ?? null),
                    })}
                  </Regular>
                </div>
              )}
              {card.product?.conditions_text && (
                <div>
                  <Title>{t('conditions')}</Title>
                  <Regular>
                    <Markdown className="__markdown">{card.product.conditions_text}</Markdown>
                  </Regular>
                </div>
              )}
            </InfoFrame>
            <CardFrame>
              <CardStackWrapper>
                <CardStack
                  currency={card.product?.currency}
                  debitValue={card.balance ? `${card.balance}` : undefined}
                  slotImage={card.product?.primary_image}
                />
              </CardStackWrapper>
              <MobileCardImageWrapper>
                <Img src={card.product?.primary_image ?? ''} />
                <StyledChip>
                  {getLocalisedPrice(i18n.language, Number(card.balance), card.product?.currency)}
                </StyledChip>
              </MobileCardImageWrapper>
              <GrayBackground>
                {issuingCardDelayedInfo && <Title>{t('issuingCardDelayedInfo')}</Title>}
                {processing && (
                  <Suspense>
                    <AnimationRoot>
                      <Animation>
                        <IconComponents.openCircle />
                      </Animation>
                    </AnimationRoot>
                  </Suspense>
                )}
                {card.is_expired && card.expiry_date && card.product_expiry && (
                  <Expired>
                    {t('yourCardExpiredOn')}
                    {formattedDate(card.expiry_date)}
                  </Expired>
                )}
                {card.card_number && <BarcodeLarge id="barcode-large" />}
                {card.card_number && <BarcodeSmall id="barcode-small" />}
                {card.card_number && (
                  <div>
                    <Title>{t('cardNumber')}</Title>
                    <CardNumberWrapper>
                      <Codes>{card.card_number}</Codes>
                      <CopyButton onClick={() => copyHandler(card.card_number)}>
                        <Suspense>
                          {copied ? (
                            <CheckMarkIcon
                              fill={colours.prisma.white}
                              stroke={colours.prisma.white}
                            />
                          ) : (
                            <CopyIcon fill={colours.prisma.white} stroke={colours.prisma.white} />
                          )}
                        </Suspense>
                        <CopyText>{t('copy')}</CopyText>
                      </CopyButton>
                    </CardNumberWrapper>
                  </div>
                )}
                {card.security_code && (
                  <div>
                    <Title>{t('securityCode')}</Title>
                    <Codes>{card.security_code}</Codes>
                  </div>
                )}
              </GrayBackground>
              {card.redeem_url && (
                <div>
                  <Title>{t('redeemAt')}</Title>
                  <Regular>{card.redeem_url}</Regular>
                </div>
              )}
            </CardFrame>
          </TwoSides>
        </FullHeight>
      )}
      <AboutFooter $noBackground />
    </Root>
  )
}

export default Page

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f4f6f7 50%, #edf0f1 100%);
`

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  flex-grow: 10;
  justify-content: flex-start;
  align-items: center;
`

const GrayBackground = styled.div`
  background-color: ${colours.mist[100]};
  width: 100%;
  border-radius: 16px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${mediaQueries.to.breakpoint.mobileL} {
    background-color: white;
    padding: 0;
  }
`

const Codes = styled(Fonts.ButtonCTALabelLarge)`
  background-color: white;
  color: ${colours.mist[900]};
  padding: 4px 12px 4px 12px;
  gap: 8px;
  border-radius: 8px;
  width: fit-content;
  letter-spacing: 0.3em;
  white-space: break-spaces;
  word-break: break-all;
  ${mediaQueries.to.breakpoint.mobileL} {
    color: ${colours.mist[900]};
    background-color: ${colours.mist[200]};
  }
`

const MobileCardImageWrapper = styled.div`
  position: relative;
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
`
const Expired = styled(Fonts.BodyLarge)`
  font-size: 24px;
`
const CardFrame = styled.div`
  width: min(560px, 100vw);
  min-width: min(560px, 100vw);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 28px 24px 24px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 24px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  justify-content: flex-start;
  ${mediaQueries.to.breakpoint.desktop} {
    order: -1;
    width: 100%;
    min-width: 100%;
  }
  ${mediaQueries.to.breakpoint.tablet} {
    border-radius: 0;
  }
`

const InfoFrame = styled.div`
  ${mediaQueries.to.breakpoint.tablet} {
    ${WidthBoundaryCSS};
  }
`

const Title = styled(Fonts.BodyLarge)`
  margin-bottom: 4px;
`

const Regular = styled(Fonts.BodyRegular)`
  color: ${colours.mist[900]};
  margin-bottom: 24px;
`

const Img = styled.img`
  width: 100%;
  border-radius: 16px;
  align-self: center;
`

const BarcodeLarge = styled.svg`
  align-self: center;
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`

const BarcodeSmall = styled.svg`
  align-self: center;
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
`

const Circle = styled.div`
  width: 200px;
  min-width: 200px;
  height: 200px;
  min-height: 200px;
  background: white;
  border-radius: 100%;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Rotating = styled.div`
  animation: ${spin} 2s linear infinite;
  transform-origin: center;
`

const OpenCircle = styled.div`
  background-color: rgba(0, 0, 0, 0);
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 6px solid ${colours.brand.green};
  border-right: 6px solid rgba(0, 0, 0, 0);
  transform: rotate(-45deg);
`

const TitleH1L = styled(Fonts.TitleHeaderH1L)`
  ${TitleHeaderH1LCSS};
  text-align: center;
`

const TitleH1S = styled(Fonts.TitleHeaderH1S)`
  ${TitleHeaderH1SCSS};
  text-align: center;
`

const ActivatedTitleH1S = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 24px;
`

const TwoSides = styled.div`
  display: flex;
  gap: 64px;
  max-width: 1200px;
  ${mediaQueries.to.breakpoint.desktop} {
    flex-direction: column;
  }
  ${mediaQueries.from.breakpoint.desktop} {
    ${WidthBoundaryCSS};
  }
`

const CardStackWrapper = styled.div`
  background-color: ${colours.brand.dark};
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`
const RightEye = styled.div`
  position: absolute;
  top: 40px;
  right: 100px;
  animation: 4s linear ${rotate} infinite;
`

const LeftEye = styled.div`
  position: absolute;
  top: 40px;
  left: 100px;
  animation: 4s linear ${rotate} infinite;
`

const Subtitle = styled.div`
  text-align: center;
  ${mediaQueries.to.breakpoint.tablet} {
    ${BodyLargeCSS}
  }
  ${mediaQueries.from.breakpoint.tablet} {
    ${IntroSubtextSmallCSS}
  }
`

const RetryTextWrapper = styled.div`
  padding: 0 88px;
  ${mediaQueries.to.breakpoint.mobileL} {
    padding: 0;
  }
`

const ErrorDescriptionWrapper = styled.div`
  ${WidthBoundaryCSS};
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
`

const BlueLink = styled(Fonts.BodyLarge)`
  ${ButtonTextLinkCSS};
  color: ${colours.blues[100]};
  text-decoration: underline;
  cursor: pointer;
`

const ToDesktop = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

const FromDesktop = styled.div`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const Spacer = styled.div`
  width: 100%;
  min-height: 0;
  ${mediaQueries.from.breakpoint.desktop} {
    min-height: 48px;
  }
`

const StyledChip = styled(Chip)`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0 12px;
  border-radius: 16px;
`

const CopyButton = styled.div`
  background-color: ${colours.brand.dark};
  color: ${colours.prisma.white};
  border-radius: 12px;
  padding: 8px 24px;
  ${ButtonCTALabelSmallCSS}
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
`

const CopyIcon = styled(IconComponents.copy)``
const CheckMarkIcon = styled(IconComponents.checkmark)``

const CardNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`

const CopyText = styled.div``

const Animation = styled.div`
  animation: ${spin} 2s linear infinite;
  width: 24px;
  height: 24px;
  transform-origin: center;
`

const AnimationRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
