import { CSSProperties, Suspense } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { colours } from '@configs/colours'

import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useUserContext } from '@hooks/useUserContext'
import { Flows } from '@services/api.types'
import { mediaQueries } from '@utils/mediaQueries'

export const DeliverySection: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  const { t } = useTranslation()
  const { isLoggedIn, email } = useUserContext()
  const { hashFlows } = useHashFlowContext()
  const { mainKey } = useMainKeyContext()

  const shouldShowEmailInput = () => {
    if (!mainKey) return true
    switch (hashFlows[mainKey]?.flow) {
      case Flows.ShopGuest:
        return true
      case Flows.ShopAccountMandatory:
        return false
      case Flows.ShopAccountOptional:
        return !isLoggedIn
      default:
        return true
    }
  }

  return shouldShowEmailInput() ? (
    <DeliveryWrapper style={style}>
      <DeliveryTitle>
        <SubHeader>{t('delivery')}</SubHeader>
      </DeliveryTitle>
      <DeliverySubtitle>
        <Suspense>
          <IconComponents.gift />
        </Suspense>
        <Suspense>
          <IconComponents.envelopeOpen />
        </Suspense>
        {t('whereToSendCards')}
      </DeliverySubtitle>
      <BodyRegular700>{t('giftCardsProcessingTime')}</BodyRegular700>

      <StyledTextInput
        description={t('emailAddress')}
        disabled={!!email && isLoggedIn}
        name="email"
      />
    </DeliveryWrapper>
  ) : null
}

const DeliveryTitle = styled.div`
  margin-bottom: 16px;
`

const DeliveryWrapper = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    width: 66%;
  }
`

const BodyRegular700 = styled(Fonts.BodyRegular)`
  color: ${colours.mist[700]};
`

const DeliverySubtitle = styled(Fonts.ButtonCTALabelLarge)`
  margin-bottom: 8px;
  color: ${colours.brand.dark};
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledTextInput = styled(TextInput)`
  margin: 24px 0;
`

const SubHeader = styled(Fonts.TitleHeaderH2S)`
  display: flex;
  align-items: center;
  gap: 8px;
`
