import { AuthError, signUp } from 'aws-amplify/auth'
import { Form, Formik, FormikConfig } from 'formik'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as y from 'yup'

import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { GeneralError } from '@components/molecules/forms/GeneralError/GeneralError'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { PasswordStrength } from '@components/molecules/PasswordStrength/PasswordStrength'
import { SERVICE_NAME } from '@configs/constants'
import { authMessages } from '@constants/authMessages'
import { datadogMessages } from '@constants/datadog'
import { shopResponseErrors } from '@constants/shopResponseErrors'
import { datadogLogs } from '@datadog/browser-logs'
import { HttpError } from '@errors/httpError'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'
import {
  atLeastNCharacters,
  atLeastOneNumber,
  atLeastOneSpecialCharacter,
  atLeastOneUpperCase,
} from '@utils/regexes'

const initialValues = { email: '', givenName: '', password: '' }

const getValidationSchema = (t: TFunction) =>
  y.object({
    password: y
      .string()
      .min(10, t('forms.validation.atLeastCharacters', { count: 10 }))
      .matches(atLeastOneNumber, t('forms.validation.atLeastNumbers', { count: 1 }))
      .matches(atLeastOneUpperCase, t('forms.validation.atLeastUpperCase', { count: 1 }))
      .matches(atLeastOneSpecialCharacter, t('forms.validation.atLeastOneSpecialCharacter'))
      .required(t('forms.validation.passwordRequired')),
    email: y.string().email().required(t('forms.validation.emailRequired')),
    givenName: y.string().min(3).required(t('forms.validation.nameRequired')),
  })

export type SubmitAction = (
  values: typeof initialValues & { userSub?: string },
) => void | Promise<void>

type Props = {
  submitAction?: SubmitAction
  initialEmail?: string | null
  children?: () => React.ReactNode
  footer?: () => React.ReactNode
}

export const SignUpForm: React.FC<Props> = ({ footer, initialEmail, submitAction, children }) => {
  const { t } = useTranslation()
  const [generalError, setGeneralError] = useState(false)
  const [knownError, setKnownError] = useState<string>()
  const [unexpectedError, setUnexpectedError] = useState<string>()
  const closeGeneralError = () => setGeneralError(false)
  const validationSchema = getValidationSchema(t)

  const mainSubmit: FormikConfig<typeof initialValues>['onSubmit'] = async (
    values,
    { setSubmitting },
  ) => {
    setGeneralError(false)
    const emailAsUsername = values.email.trim().toLowerCase()
    const password = values.password

    try {
      const { userId } = await signUp({
        username: emailAsUsername,
        password,
        options: {
          userAttributes: { email: emailAsUsername, given_name: values.givenName },
          clientMetadata: { service: SERVICE_NAME },
        },
      })

      if (!userId) {
        datadogLogs.logger.warn(datadogMessages.userIdNotReturned, { env: import.meta.env.PROD })
      }

      await submitAction?.({ ...values, userSub: userId })
    } catch (error) {
      console.error('Error signing up:', error)
      setGeneralError(true)

      if (error instanceof AuthError && error.message in authMessages) {
        setKnownError(error.message)
        setUnexpectedError(undefined)
        return
      }

      if (error instanceof AuthError && error.message in authMessages) {
        setKnownError('auth.' + error.message)
        setUnexpectedError(undefined)
        return
      }

      if (error instanceof HttpError && error.json !== null && typeof error.json === 'object') {
        const nonFieldError =
          'non_field_errors' in error.json && Array.isArray(error.json.non_field_errors)
            ? (error.json.non_field_errors[0] as string)
            : undefined
        if (nonFieldError && nonFieldError in shopResponseErrors) {
          setKnownError('backend.' + nonFieldError)
          setUnexpectedError(undefined)
          return
        }
      }

      if (error instanceof Error && error.message) {
        setUnexpectedError(error.message)
        setKnownError(undefined)
        return
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Root>
      {children?.()}
      <Formik
        initialValues={{ ...initialValues, email: initialEmail ?? '' }}
        onSubmit={mainSubmit}
        validateOnBlur
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }) => (
          <WithTranslateFormErrors>
            <Form>
              <StyledTextInput
                autoComplete="given-name"
                description={t('forms.fields.givenName')}
                name="givenName"
                type="text"
              />
              <StyledTextInput
                autoComplete="email"
                description={t('forms.fields.email')}
                name="email"
                type="text"
              />
              <StyledTextInput
                autoComplete="password"
                canPreviewPassword
                data-testid="passwordinput"
                description={t('forms.fields.password')}
                name="password"
                role="passwordinput"
                type="password"
              >
                <StyledPasswordStrength
                  conditions={[
                    [atLeastOneNumber, t('forms.validation.atLeastNumbers', { count: 1 })],
                    [atLeastOneUpperCase, t('forms.validation.atLeastUpperCase', { count: 1 })],
                    [
                      atLeastOneSpecialCharacter,
                      t('forms.validation.atLeastOneSpecialCharacter', { count: 1 }),
                    ],
                    [
                      atLeastNCharacters(10),
                      t('forms.validation.atLeastNCharacters', { count: 10 }),
                    ],
                  ]}
                  string={values.password}
                  tip="all"
                />
              </StyledTextInput>
              <GeneralError $visible={generalError} onClick={closeGeneralError}>
                {knownError ? t(knownError) : null}
                {unexpectedError ? unexpectedError : null}
              </GeneralError>
              <StyledPrimaryButton
                disabled={isSubmitting}
                iconRight={Icons.ArrowRight}
                type="submit"
              >
                {t('forms.actions.signUp')}
              </StyledPrimaryButton>
            </Form>
          </WithTranslateFormErrors>
        )}
      </Formik>
      {footer?.()}
    </Root>
  )
}

const StyledTextInput = styled(TextInput)`
  margin-bottom: 24px;
  position: relative;
  &:last-of-type {
    margin-bottom: 64px;
  }
  & input:not(:focus) ~ aside {
    display: none;
  }
`

const StyledPasswordStrength = styled(PasswordStrength)`
  position: absolute;
  z-index: 3;
  width: 100%;

  #tip-left,
  #tip-top {
    display: none;
  }

  ${mediaQueries.from.breakpoint.desktop} {
    left: 0;
    top: 0;
    transform: translate(-100%, calc(-50% + 24px));
    #tip-bottom {
      display: none;
    }
  }

  ${mediaQueries.to.breakpoint.desktop} {
    bottom: 52px;
    #tip-right {
      display: none;
    }
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 32px;
`

const Root = styled.div``
