import { Form, Formik } from 'formik'
import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { createGlobalStyle, keyframes } from 'styled-components'

import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { LazyGiftAnimation } from '@components/organisms/Animation/LazyAnimation'
import ConfettiAnimation from '@components/organisms/ConfettiAnimation/ConfettiAnimation'
import { colours } from '@configs/colours'
import { shareKeyPaths } from '@configs/urls'
import { datadogMessages } from '@constants/datadog'
import { zIndexes } from '@constants/zIndexes'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useData } from '@hooks/useData'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useSettingsContext } from '@hooks/useSettings'
import { api } from '@services/api'
import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { HashType } from '@typeDeclarations/hashType'

import ConfettiDefaultImage from '/images/confetti-card.jpg'

const Confetti: React.FC = () => {
  const isTest = import.meta.env.MODE === 'test'
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { shareKey = '' } = useParams()
  const { dispatchHashFlows, hashFlows } = useHashFlowContext()
  const { data, loading, error } = useData(
    useCallback(() => api.receiveInfo(shareKey, i18n.language), [shareKey, i18n.language]),
    true,
  )
  const { imageLogo } = useSettingsContext()
  const [confettiTrigger, setConfettiTrigger] = useState(false)
  const personalMessageRef = useRef<HTMLDivElement | null>(null)

  const messageHeight = personalMessageRef.current?.getBoundingClientRect().height

  if (error) throw new Error()

  const videoUrl = data?.video_url
  const photoUrl = data?.photo_url

  const preload = new Image()
  preload.src = photoUrl ?? ConfettiDefaultImage

  useEffect(() => {
    if (!data?.flow) return

    dispatchHashFlows({
      type: HashFlowActions.SetHashFlow,
      payload: {
        hash: shareKey,
        flow: data.flow,
        type: HashType.ShareKey,
        prefillEmail: data.prefill_email,
        skip_claim_step: data.skip_claim_step,
      },
    })

    datadogRum.setGlobalContextProperty('shareKey', {
      shareKey,
      flow: data.flow,
    })
  }, [data?.flow, shareKey, dispatchHashFlows, data?.prefill_email, data?.skip_claim_step])

  useEffect(() => {
    if (!photoUrl) return

    const preloaded = new Image()
    preloaded.src = photoUrl
  }, [photoUrl])

  const onSubmit = () => {
    datadogLogs.logger.info(datadogMessages.flowEntered + hashFlows[shareKey]?.flow, {
      flow: hashFlows[shareKey]?.flow,
    })

    if (data?.skip_claim_step) {
      navigate(generatePath(shareKeyPaths.claimSkip, { shareKey }))
    } else {
      navigate(generatePath(shareKeyPaths.claim, { shareKey }))
    }
  }

  return (
    <Root $height={messageHeight}>
      <TrengoWidgetStyle />
      <AnimationPositioner>
        <AnimationWrapper $show={isTest || confettiTrigger}>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            <WithTranslateFormErrors>
              <StyledForm>
                <PersonalMessage
                  $barColor={data?.shop_personalization?.color_background}
                  ref={personalMessageRef}
                >
                  <MediaPart>
                    {!photoUrl && !videoUrl && <img src={ConfettiDefaultImage} />}
                    {photoUrl && <ContainedImage alt="Yesty" src={photoUrl} />}
                    {videoUrl && (
                      <VideoWrapper>
                        <ContainedVideo controls={true} poster={data.thumbnail_url}>
                          <source src={videoUrl} />
                        </ContainedVideo>
                      </VideoWrapper>
                    )}
                  </MediaPart>
                  <MessagePart>
                    <LogoImg src={imageLogo ?? undefined} />
                    <Message>{data?.message}</Message>
                    <PrimaryButton disabled={!data?.flow || loading} type="submit">
                      {t('forms.actions.open')}
                    </PrimaryButton>
                  </MessagePart>
                </PersonalMessage>
              </StyledForm>
            </WithTranslateFormErrors>
          </Formik>
        </AnimationWrapper>
      </AnimationPositioner>
      <Arrangement>
        {!isTest && !confettiTrigger && (
          <AnimationPosition>
            <AnimationSizer>
              <Suspense>
                <LazyGiftAnimation
                  isCardLoading={loading}
                  onComplete={() => setConfettiTrigger(true)}
                />
              </Suspense>
            </AnimationSizer>
          </AnimationPosition>
        )}
      </Arrangement>
      <ConfettiAnimation trigger={confettiTrigger} />
    </Root>
  )
}

export default Confetti

const slideFromTop = keyframes`
  0% {
    transform: translate(0, calc(-72px - 8px));
  };
  80% {
    transform: translate(0,calc(-72px - 8px));
  }
  100% {
    transform: translate(0, calc(100% + 32px));
  }
`

const TrengoWidgetStyle = createGlobalStyle`
  div#trengo-web-widget {
    display: none;
  }
`

const Root = styled.article<{ $height?: number }>`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f4f6f7 50%, #edf0f1 100%);
  height: ${(p) => (p.$height ? p.$height + 64 + 'px' : 'unset')};
  overflow-x: hidden;
`

const Arrangement = styled.div`
  display: grid;
  align-items: start;
  justify-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin-top: 32px;
`

const AnimationPositioner = styled.div`
  height: 0;
  z-index: ${zIndexes.baseOnTop + 2};
  position: relative;
`

const AnimationWrapper = styled.div<{ $show?: boolean }>`
  animation: ${slideFromTop} 8s ease-in-out forwards;
  position: absolute;
  bottom: 0;
  width: 100%;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PersonalMessage = styled.div<{ $barColor?: string | null }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 50%;
  bottom: 0px;
  width: min(520px, 96%);
  z-index: ${zIndexes.baseOnTop + 1};
  margin-bottom: 32px;
`

const MediaPart = styled.div`
  border-radius: 24px;
  background-color: ${colours.prisma.white};
  border-radius: 24px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 600px;
`

const MessagePart = styled.div`
  background-color: ${colours.prisma.white};
  border-radius: 24px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`

const LogoImg = styled.img`
  height: 24px;
`

const ContainedImage = styled.img`
  width: 100%;
  border-radius: 8px;
`

const VideoWrapper = styled.div`
  height: 400px;
  width: 100%;
`

const ContainedVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Message = styled(Fonts.IntroSubtextSmall)`
  padding: 0 24px;
  text-align: center;
  overflow-wrap: break-word;
  max-width: 100%;
`

const AnimationPosition = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AnimationSizer = styled.div`
  width: min(400px, 100vw);
`
