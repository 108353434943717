import styled from 'styled-components'

import { mediaQueries } from '@utils/mediaQueries'

export const CheckoutGridArrangement = styled.div`
  display: grid;
  ${mediaQueries.within.breakpoint.mobileL} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-template-areas: 'simpleReceipt' 'delivery' 'extendedReceipt';
    column-gap: 40px;
  }

  ${mediaQueries.within.breakpoint.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);
    column-gap: 40px;
    grid-template-areas: 'simpleReceipt simpleReceipt' 'delivery extendedReceipt';
  }

  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: 1fr 400px;
    grid-template-rows: repeat(2, min-content);
    column-gap: 40px;
    grid-template-areas: 'simpleReceipt extendedReceipt' 'delivery extendedReceipt';
  }
`
