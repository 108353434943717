import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { SecondaryButton } from '@components/molecules/forms/buttons/SecondaryButton'
import { mediaQueries } from '@utils/mediaQueries'

type Props = { shareLink: string; onClose?: () => void }

export const ShareModalContent: React.FC<Props> = ({ onClose, shareLink }) => {
  const { t } = useTranslation()

  return (
    <Root>
      <Title>{t('share')}</Title>
      <ShareIcons>
        <Mail href={`mailto:?body=${shareLink}`}>
          <Suspense>
            <IconComponents.letter stroke={colours.prisma.white} />
          </Suspense>
        </Mail>
        <Whatsapp href={`https://wa.me/?text=${shareLink}`}>
          <Suspense>
            <IconComponents.whatsapp stroke={colours.prisma.white} />
          </Suspense>
        </Whatsapp>
        <Twitter href={`https://twitter.com/messages/compose?&text=${shareLink}`}>
          <Suspense>
            <IconComponents.twitter />
          </Suspense>
        </Twitter>
        <Facebook>
          <Suspense>
            <img src="/images/Facebook_Logo_Primary.png" />
          </Suspense>
        </Facebook>
      </ShareIcons>
      <CopyArea>
        <LinkToCopy onClick={() => navigator.clipboard.writeText(shareLink)}>
          {shareLink}
        </LinkToCopy>
        <StyledPrimaryButton iconRight={Icons.Link}>{t('copy')}</StyledPrimaryButton>
      </CopyArea>
      <SecondaryButton onClick={() => onClose?.()}>{t('cancel')}</SecondaryButton>
    </Root>
  )
}

const StyledPrimaryButton = styled(PrimaryButton)`
  width: fit-content;
  min-height: 56px;
`

const LinkToCopy = styled.div`
  ${BodyRegularCSS};
  flex-grow: 2;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  word-break: break-all;
`

const CopyArea = styled.div`
  align-items: center;
  background-color: ${colours.mist[200]};
  border-radius: 8px;
  display: flex;
  gap: 16px;
  min-height: 56px;
  margin-bottom: 24px;
  padding: 8px 8px 8px 16px;
  ${mediaQueries.to.breakpoint.tablet} {
    flex-direction: column;
  }
`

const Root = styled.div`
  padding: 32px 24px 24px 24px;
  ${mediaQueries.from.breakpoint.tablet} {
    width: 600px;
  }
`

const Title = styled(Fonts.TitleHeaderH2)`
  margin-bottom: 16px;
`

const ShareIcons = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
  justify-content: center;
`

const Mail = styled.a`
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Whatsapp = styled.a`
  --whatsapp-color: #25d366;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--whatsapp-color);
`

const Twitter = styled.a`
  --twitter-color: ${colours.prisma.black};
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--twitter-color);
`

const Facebook = styled.div`
  --facebook-color: rgb(44, 99, 251);
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 100%;
  background-color: ${colours.brand.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--facebook-color);
`
