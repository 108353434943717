import { signOut } from 'aws-amplify/auth'
import { Fragment, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, generatePath } from 'react-router-dom'
import styled from 'styled-components'

import { Badge } from '@components/atoms/Badge/Badge'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { HighlightableIcon } from '@components/atoms/SelectedIcon/SelectedIcon'
import { Fonts } from '@components/atoms/typography/Fonts'
import { DevActions } from '@components/organisms/dev/DevActions'
import { LanguageSwitcher } from '@components/organisms/LanguageSwitcher/LanguageSwitcher'
import { colours } from '@configs/colours'
import { cartSteps, claimCodePaths } from '@configs/urls'
import { supportLinks } from '@constants/supportLinks'
import { useCartContext } from '@hooks/useCart'
import { useMainKeyContext } from '@hooks/useMainKey'

import { useUserContext } from '@hooks/useUserContext'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  hideActions?: boolean
  Logo?: React.ReactNode
  supportLinksSet?: Record<string, string>
}

export const Nav: React.FC<Props> = ({ hideActions, Logo, supportLinksSet = supportLinks }) => {
  const { aggregatedCart } = useCartContext()
  const { mainKey = '' } = useMainKeyContext()
  const { i18n, t } = useTranslation()
  const { isLoggedIn, setIsLoggedIn, setEmail } = useUserContext()

  const handleLogout = () => {
    signOut()
      .then(() => {
        setIsLoggedIn(false)
        setEmail('')
      })
      .catch((e: unknown) => {
        console.log('Error signing out', e)
      })
  }

  const cartCount = mainKey
    ? aggregatedCart.carts[mainKey]?.cards.reduce((acc, { quantity }) => acc + +quantity, 0)
    : 0

  return (
    <TopMenu>
      <LogoWrapper>
        {Logo && (
          <Link to={mainKey ? generatePath(claimCodePaths.catalogue, { claimCode: mainKey }) : '#'}>
            {Logo}
          </Link>
        )}
      </LogoWrapper>
      <Actions>
        <DesktopOnly>
          <DevActions />
          {!hideActions && (
            <Fragment>
              {mainKey && (
                <StyledNavLink
                  to={
                    mainKey ? generatePath(claimCodePaths.catalogue, { claimCode: mainKey }) : '#'
                  }
                >
                  <Fonts.ButtonCTALabelLarge>{t('shop')}</Fonts.ButtonCTALabelLarge>
                </StyledNavLink>
              )}
              <a
                href={
                  supportLinksSet[i18n.language as keyof typeof supportLinksSet] ||
                  supportLinksSet.en
                }
                rel="noreferrer"
                target="_blank"
              >
                <StyledExternalLink>
                  {t('support')}
                  <Suspense>
                    <IconComponents.externalResource
                      fill={colours.mist[300]}
                      stroke={colours.mist[300]}
                    />
                  </Suspense>
                </StyledExternalLink>
              </a>
              {mainKey && (
                <NavLink to={generatePath(cartSteps.main, { claimCode: mainKey })}>
                  {({ isActive }) => (
                    <Badge count={cartCount}>
                      <StyledHighlightableIcon icon={Icons.Cart} isHighlighted={isActive}>
                        <Suspense>
                          <IconComponents.cart
                            fill={mainKey ? colours.prisma.black : colours.mist[600]}
                          />
                        </Suspense>
                      </StyledHighlightableIcon>
                    </Badge>
                  )}
                </NavLink>
              )}
            </Fragment>
          )}
        </DesktopOnly>
        <HiddenOnSmallDesktop>
          {isLoggedIn && (
            <CleanButton onClick={handleLogout}>
              <Suspense>
                <IconComponents.logout />
              </Suspense>
            </CleanButton>
          )}
          <LanguageSwitcher />
        </HiddenOnSmallDesktop>
      </Actions>
      <ShownOnSmallDesktop>
        {isLoggedIn && (
          <CleanButton onClick={handleLogout}>
            <Suspense>
              <IconComponents.logout />
            </Suspense>
          </CleanButton>
        )}
        <LanguageSwitcher />
      </ShownOnSmallDesktop>
    </TopMenu>
  )
}

const CleanButton = styled.button`
  all: unset;
  cursor: pointer;
`

const HiddenOnSmallDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${mediaQueries.within.breakpoint.desktop} {
    display: none;
  }
`

const ShownOnSmallDesktop = styled.div`
  display: none;
  ${mediaQueries.within.breakpoint.desktop} {
    display: flex;
    gap: 16px;
    align-items: center;
  }
`

const LogoWrapper = styled.div`
  width: 200px;
`

const StyledNavLink = styled(NavLink)`
  padding: 8px 24px;
`

const StyledExternalLink = styled(Fonts.ButtonCTALabelLarge)`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 24px;
`

const DesktopOnly = styled.div`
  align-items: center;
  display: flex;
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
  ${mediaQueries.from.breakpoint.desktopL} {
    margin-right: 176px;
  }
`

const Actions = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledHighlightableIcon = styled(HighlightableIcon)`
  margin: 0px 12px;
`

const TopMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 100%;
`
