import styled from 'styled-components'
import { useFormikContext } from 'formik'

import { Radio } from '@components/molecules/forms/inputs/Radio'
import { colours } from '@configs/colours'
import { Icons } from '@typeDeclarations/components/atoms/icons'

type Props = {
  choiceDisabled?: boolean
  creditCardDisabled?: boolean
  idealDisabled?: boolean
  onValueChange?: (newValue: unknown) => void
}

export const PaymentMethods: React.FCS<Props> = ({
  choiceDisabled,
  creditCardDisabled,
  idealDisabled,
  className,
  onValueChange,
  ...props
}) => {
  const { values } = useFormikContext<{ paymentMethod: 'ideal' | 'creditcard' | undefined }>()
  const accent = colours.brand.blue
  const selectedBackgroundColor = colours.blues[10]

  return (
    <Root className={className} {...props}>
      <Bar
        $accent={accent}
        $disabled={idealDisabled || choiceDisabled}
        $selected={values.paymentMethod === 'ideal'}
        $selectedBackgroundColor={selectedBackgroundColor}
      >
        <Radio
          accent={accent}
          disabled={idealDisabled || choiceDisabled}
          name="paymentMethod"
          onValueChange={(newValue) => onValueChange?.(newValue)}
          text="Ideal"
          value="ideal"
        />
      </Bar>
      <Bar
        $accent={accent}
        $disabled={creditCardDisabled || choiceDisabled}
        $selected={values.paymentMethod === 'creditcard'}
        $selectedBackgroundColor={selectedBackgroundColor}
      >
        <Radio
          accent={accent}
          disabled={creditCardDisabled || choiceDisabled}
          icon={Icons.CreditCard}
          name="paymentMethod"
          onValueChange={(newValue) => onValueChange?.(newValue)}
          text="Credit card"
          value="creditcard"
        />
      </Bar>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Bar = styled.div<{
  $selected?: boolean
  $accent?: string
  $selectedBackgroundColor?: string
  $disabled?: boolean
}>`
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  height: 64px;
  border-radius: 8px;
  padding: 0 24px;
  border: solid 2px;
  border-color: ${({ $selected, $accent, $disabled }) => {
    if ($disabled && $selected) return colours.mist[400]
    return $selected ? $accent : 'transparent'
  }};
  background-color: ${({ $selected, $selectedBackgroundColor, $disabled }) => {
    if ($disabled && $selected) return colours.mist[100]
    return $selected ? $selectedBackgroundColor : colours.prisma.white
  }};
`
